import React from 'react'
import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
// import DataTable from './Datatable_Users'
import UserTable from './UserTable';

const Index = () => {
  let navigate = useNavigate()
  const role = JSON.parse(localStorage.getItem("roledata"))

  const addUsers = () => {
    navigate('/addusername'); 
  }
  return (
    <>
    <Box className='companies'>
      <MiniDrawer menuOrder={22} />
      <Box component="section" className="contentWraper">
        <Box className='headingBox'>
          <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Users</Typography>
          {role.UserRoles == null ? <Button variant="contained" className='addbtn' onClick={() => addUsers()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add UserName</Button>
            : role.UserRoles.users.Addusers && <Button variant="contained" className='addbtn' onClick={() => addUsers()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add UserName</Button>
          }                </Box>
        <Box className='whiteContainer'>
          <UserTable />
        </Box>
      </Box>
    </Box>
    </>
  )
}

export default Index