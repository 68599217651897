
import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../../../components/drawer'
import axios from 'axios'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Checkbox, ListItemText, FormControlLabel, OutlinedInput, Input, RadioGroup, Radio } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import ClearIcon from '@mui/icons-material/Clear';
import 'swiper/css';
import 'swiper/css/pagination';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import { usePlacesWidget } from "react-google-autocomplete";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import dayjs from 'dayjs';
import EmailEditor from "react-email-editor";
import AutoComplete_Edit from './Autocomplete_edit';
import Sidebar from './../../Sidebar'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#f3714d',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#f3714d',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#f3714d',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <ListAlt />,
        2: <CompareIcon />,
        3: <Dvr />,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const steps = ['Basic Information', 'Course Information', 'Course Description'];

export default function Service_Steppyform() {
    const params = useParams()
    const navigate = useNavigate()
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    let location = useLocation()
    const [activeStep, setActiveStep] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar()
    const [course, setCourse] = React.useState({ coursename: location.state.data.course_name, description: location.state.data.shortDescription, fees: location.state.data.fees, owner: location.state.data.owner, specialization: location.state.data.specialization, address: location.state.data.address })
    const [truebutton, setTruebutton] = React.useState(false)
    const [error, setError] = React.useState({ coursename: "", fees: "", start_date: "", end_date: "", video: "", owner: "", specialization: "", address: "", location: "" })
    const [categoryId, setCategoryId] = React.useState(location.state.data.Category_id)
    const [subcategoryId, setSubCategoryId] = React.useState(location.state.data.Subcategory_id ? location.state.data.Subcategory_id : "")
    const [catError, setCatError] = React.useState('')
    const [subcatError, setSubCatError] = React.useState('')
    const [imageError, setImageError] = React.useState('')
    const [state1, setState1] = React.useState(location.state.data.additionalimages)
    const [state2, setState2] = React.useState(location.state.data.additionalimages)
    const [existingimages, setExistingimages] = React.useState(location.state.data.additionalimages)
    const [statesingle, setStatesingle] = React.useState([...[], location.state.data.image])
    const [statesingle2, setStatesingle2] = React.useState([...[], location.state.data.image])
    const [richeditor, setRichtor] = React.useState(location.state.data.description)
    const [description, setDescription] = React.useState(location.state.data.shortDescription)
    const [imageAddError, setImageAddError] = React.useState('')
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const dataofeditor = `${richeditor}`
    const [convertedContent, setConvertedContent] = React.useState(null);
    const [selectedFromDateTime, setSelectedFromDateTime] = useState(dayjs(location.state.data.start_date));
    const [selectedToDateTime, setSelectedToDateTime] = useState(dayjs(location.state.data.end_date));
    const [selectedVideo, setSelectedVideo] = useState([...[], location.state.data.Video]);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState([...[], location.state.data.Video]);
    const [cityName, setCityName] = useState(location.state.data.formatted_address)
    const [lat, setLat] = useState('')
    const [lng, setLng] = useState('')
    const [status, setStatus] = React.useState(location.state.data.status)
    const [subcat, setSubcat] = React.useState([])
    const [category, setCategory] = React.useState([])
    const emailEditorRef = useRef({ editor: {} });
    const [descJson, setDescJson] = React.useState(location.state.data.DescriptionJSON)
    const [isPublic, setPublic] = React.useState(location.state.data.public)
    const [isPrimary, setPrimary] = React.useState(location.state.data.primary)
    const [selectGallert, setSelectGallery] = useState(location.state.data.gallery)
    const [teacher, setTeacher] = React.useState([])
    const [community, setCommunity] = React.useState([])
    const [selectOwner, setSelectOwner] = useState(location.state.data.courseby)

    const auth = JSON.parse(localStorage.getItem('userData'))

    const [loc_coordinates, setLoc_coordinates] = useState({ type: "Point", coordinates: ['', ''] })

    const { ref, autocompleteRef } = usePlacesWidget({
        // need to change this API key 
        apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
        onPlaceSelected: (place) => {
            console.log(place, 'placeplace');
            setCityName(place.formatted_address)
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
            setLoc_coordinates(prevState => ({
                ...prevState,
                coordinates: [place.geometry.location.lat(), place.geometry.location.lng()]
            }));
            setError(prevError => ({ ...prevError, location: '' }))
        },
        options: {
            types: "(regions)" | 'establishment'
        },
    });
    const unlayerSampleJSON = {
        "body": descJson && JSON.parse(descJson),
    }
    const emailJSON = unlayerSampleJSON;

    const onLoad = () => {
        emailEditorRef.current.editor.loadDesign(emailJSON);
    };
    const handleEditor = (e) => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer?.exportHtml((data) => {
            const { design, html } = data;
            const sanitizedHtml = DOMPurify.sanitize(html);
            setDescJson(JSON.stringify(design.body))
            enqueueSnackbar('Saved changes', { variant: 'info' });
        })
    }

    React.useEffect(() => {
        if (dataofeditor) {
            try {
                const contentState = stateFromHTML(dataofeditor);
                setEditorState(EditorState.createWithContent(contentState));
            } catch (error) {
                console.error("Error during HTML to ContentState conversion:", error);
            }
        }
    }, [dataofeditor]);

    React.useEffect(() => {
        convertContentToHTML();
    }, [editorState]);

    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
    }

    const convertContentToHTML = () => {
        const currentContent = editorState.getCurrentContent();
        const currentContentAsHTML = stateToHTML(currentContent);
        setConvertedContent(currentContentAsHTML)
    }
    const handleRemoveRestart = () => {
        setSubCategoryId('')
    }
    const handleTogglePublic = () => {
        setPublic(!isPublic)
    }
    const handleTogglePrimary = () => {
        setPrimary(!isPrimary)
    }
    const handleEditGallery = (e) => {
        setSelectGallery(selectGallert === 'Image' ? 'Video' : 'Image')
    }

    const handleEditOwner = (e) => {
        setSelectOwner(e.target.value);
    }


    React.useEffect(() => {
        getTeacher()
    }, [])

    const getTeacher = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getTeachers`, { headers: headerlocal });
            setTeacher(response.data.teachers);
            setTeacherDataUpdated(response.data.teachers)
            console.log(response, "teachers");
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [teacherUpdated, setTeacherUpdated] = React.useState([])
    const [teacherDataUpdated, setTeacherDataUpdated] = React.useState([])

    const [selectedTeacher, setSelectedTeacher] = React.useState(() => {
        const arrVal = []
        const teacherArr = location.state.data.teacher
        console.log(location.state.data.teacher, "location.state.data.teachers");
        if (teacherArr.length > 0) {
            teacherArr.map((item) => {
                arrVal.push(item.username)
            })
        }
        return arrVal
    });

    React.useEffect(() => {
        getTeacherSelected();
    }, [selectedTeacher, teacherDataUpdated])

    function getTeacherSelected() {
        var array = []
        const selectedTeachers = teacherDataUpdated.filter(content => selectedTeacher.includes(content.username));
        selectedTeachers.map((item, i) => {
            var val = { username: item.username, user_id: item._id }
            Object.assign(item, val)
            array.push(val)
        })
        setTeacherUpdated(array)
    }

    const handlechangeTeacherSelected = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setSelectedTeacher(
            typeof value === 'string' ? value.split(',') : value,
        );
        getTeacherSelected()
    }

    React.useEffect(() => {
        getCommunity()
    }, [])

    const getCommunity = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `community/getCommunity`, { headers: headerlocal });
            setCommunity(response.data.community);
            setCommunityDataUpdated(response.data.community)
            console.log(response, "community");
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [communityUpdated, setCommunityUpdated] = React.useState([])
    const [communityDataUpdated, setCommunityDataUpdated] = React.useState([])

    const [selectedCommunity, setSelectedCommunity] = React.useState(() => {
        const arrVal = []
        const communityArr = location.state.data.community
        console.log(location.state.data.community, "location.state.data.community");
        if (communityArr.length > 0) {
            communityArr.map((item) => {
                arrVal.push(item.coummunity_name)
            })
        }
        return arrVal
    });

    React.useEffect(() => {
        getCommunitySelected();
    }, [selectedCommunity, communityDataUpdated])

    function getCommunitySelected() {
        var array = []
        const selectedCommunities = communityDataUpdated.filter(content => selectedCommunity.includes(content.coummunity_name));
        selectedCommunities.map((item, i) => {
            var val = { coummunity_name: item.coummunity_name, coummunity_id: item._id }
            Object.assign(item, val)
            array.push(val)
        })
        setCommunityUpdated(array)
    }



    const handlechangeCommunitySelected = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setSelectedCommunity(
            typeof value === 'string' ? value.split(',') : value,
        );
        getCommunitySelected()
    }

    const handleUpdateService = async () => {
        var multipleImage = state2.filter(x => x != null);
        var valid = validate()
        // var valid11 = validatenew()
        if (valid == true) {
            setTruebutton(true)
            const unlayer = emailEditorRef.current?.editor;
            unlayer?.exportHtml((data) => {
                const { design, html } = data;
                const sanitizedHtml = DOMPurify.sanitize(html);
                const formData = new FormData();
                formData.append("createdby[]", params && params.id)
                formData.append("Category_id", categoryId)
                formData.append("status", status)
                formData.append("Subcategory_id", subcategoryId)
                formData.append("course_name", course.coursename)
                formData.append("description", sanitizedHtml)
                formData.append("start_date", selectedFromDateTime)
                formData.append("end_date", selectedToDateTime)
                formData.append("fees", course.fees)
                formData.append("owner", course.owner)
                formData.append("specialization", course.specialization)
                formData.append("address", course.address)
                formData.append("location_coord", cityName)
                formData.append("latitude", lat)
                formData.append("longitude", lng)
                formData.append("formatted_address", cityName)
                formData.append("shortDescription", description)
                formData.append("public", isPublic)
                formData.append("primary", isPrimary)
                formData.append("DescriptionJSON", design && JSON.stringify(design.body))
                formData.append("gallery", selectGallert === 'Image' ? 'Image' : 'Video')
                formData.append('community', JSON.stringify(communityUpdated.length > 0 ? communityUpdated : []))
                formData.append('teacher', JSON.stringify(teacherUpdated.length > 0 ? teacher : []))
                { statesingle2 ? statesingle2.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') }
                {
                    selectGallert === 'Image' ? <>
                        {multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '')}
                        {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", '') }) : formData.append("Video", '')}
                        {existingimages ? existingimages.forEach((item) => { formData.append("existingimages", item) }) : formData.append("existingimages", '')}
                    </>
                        :
                        <>
                            {multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", '') }) : formData.append("additionalimages", '')}
                            {selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '')}
                            {existingimages ? existingimages.forEach((item) => { formData.append("existingimages", '') }) : formData.append("existingimages", '')}
                        </>
                }
                formData.append("courseby", selectOwner === 'Teacher' ? 'Teacher' : 'Community')

                console.log([...formData], 'formdata in edit');
                console.log("Video data:", formData.get('Video'));
                axios.put(process.env.REACT_APP_BASE_URL + `course/updateCourse/${location.state.data._id}`, formData, { headers: headerformdata })
                    .then((res) => {
                        setTruebutton(false)
                        navigate(`/teachers/details/course/${params.id}`);
                        setCourse({ coursename: "", description: "", fees: "", specialization: "", address: "" })
                        setCategoryId('');
                        setSubCategoryId('');
                        setState1([])
                        setState2([])
                        setSelectedVideo([]);
                        setVideoPreviewUrl([]);
                        enqueueSnackbar("Course updated successfully", { variant: 'success' })
                        console.log(res, "edit course");
                    }).catch((err) => {
                        setTruebutton(false)
                        enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
                        console.log(err.response, "error");
                    })
            })
        }
    }


    const validate = () => {
        if (!course.coursename && statesingle2?.length == 0 && !course.fees) {
            setError({ coursename: "This field is required.", fees: "This field is required" })
            setImageError("This field is required.")
            return false
        }
        if (!course.coursename) {
            setError({ coursename: "This field is required." })
            return false
        }
        if (!categoryId) {
            setCatError("This field is required.")
        }
        if (!course.fees) {
            setError({ fees: "This field is required." })
            return false
        }
        // if (!course.owner) {
        //     setError({ owner: "This field is required." })
        //     return false
        // }
        // if (!course.specialization) {
        //     setError({ specialization: "This field is required." })
        //     return false
        // }
        // if (!course.address) {
        //     setError({ address: "This field is required." })
        //     return false
        // }
        // if (!cityName) {
        //     setError({ location: "This field is required." })
        //     return false
        // }
        if (selectedFromDateTime && selectedToDateTime && selectedFromDateTime.isSame(selectedToDateTime)) {
			setError(prevError => ({
			  ...prevError,
			  end_date: "End date cannot be the same as start date."
			}));
			return false; // Exit the function early if validation fails
		  } else if (selectedToDateTime && selectedToDateTime.isBefore(selectedFromDateTime)) {
			setError(prevError => ({
			  ...prevError,
			  end_date: "End date must be later than the start date."
			}));
			return false; // Exit the function early if validation fails
		  }
        if (statesingle2?.length == 0) {
            setImageError("This field is required.")
            return false
        }
        return true
    }



    const validatenew = () => {
        // if (state2?.length == 0 && selectedVideo?.length == 0) {
        //     setError({ video: "This field is required." })
        //     setImageAddError("This field is required.")
        //     return false
        // }
        // if (state2?.length == 0) {
        //     setImageAddError("This field is required.")
        //     return false
        // }
        // if (selectedVideo?.length == 0) {
        //     setError({ video: "This field is required." })
        //     return false
        // }
        return true
    }

    let name, value;
    const handlechange = (e) => {
        name = e.target.name;
        value = e.target.value;
        setCourse({ ...course, [name]: value })
    }


    const handleStatus = (e) => {
        setStatus(e.target.value)
        {
            status == 'online' &&
                setCityName('');
            setLat(0.0);
            setLng(0.0);
            setCityName('')
        }
    }

    React.useEffect(() => {
        getCategory()
    }, [])

    React.useEffect(() => {
        getSubCategory()
    }, [categoryId])

    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal }).then((res) => {
            setCategory(res.data.category)
        }).catch((error) => {
            console.log(error.response, "error");
        })
    }

    const getSubCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`, { headers: headerlocal })
            .then((res) => {
                setSubcat(res.data.subcategory)
            }).catch((error) => {
                console.log(error.response, "error");
            })
    }

    const multipleImgChangeMultiple = (e, index) => {
        setImageAddError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        var data = state1.filter(x => x != null)
        var data1 = state2.filter(x => x != null)
        setState1(data)
        setState2(data1)
        setState1(prevValue => prevValue.concat(fileArray))
        setState2(prevValue => prevValue.concat(filepath))
    }

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    const handleNext = () => {
        var valid = validate()
        if (valid === true) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const CategoryData = (e) => {
        setSubCategoryId('')
        setCategoryId(e.target.value);
        setCatError('');
        setError({ coursename: '' });
        setCatError('');
        setSubCatError('')
    }
    const handleFromDateTimeChange = (newValue) => {
        setSelectedFromDateTime(newValue);
        setError(prevError => ({ ...prevError, start_date: '' }))
    };
    const handleToDateTimeChange = (newValue) => {
        setSelectedToDateTime(newValue);
        setError(prevError => ({ ...prevError, end_date: '' }))
    };
    const clearVideo = (e, index) => {
        setSelectedVideo([]);
        setVideoPreviewUrl([]);
    };

    const SingleImage = (e, index) => {
        setImageError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        var data = statesingle.filter(x => x != null)
        var data1 = statesingle2.filter(x => x != null)
        setStatesingle(data)
        setStatesingle2(data1)
        setStatesingle(prevValue => prevValue.concat(fileArray))
        setStatesingle2(prevValue => prevValue.concat(filepath))
    }
    const handleVideoChange = (e, index) => {
        setError(prevError => ({ ...prevError, video: '' }))
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setVideoPreviewUrl(fileArray)
        setSelectedVideo(filepath)
    }

    return (
        // <Box className='withSubmenu'>
        <Box className={auth.role_id == 1 ? 'withSubmenu' : ''}>
			{auth.role_id == 1 ? <MiniDrawer menuOrder={2} submenuOrder={1} />
				: <MiniDrawer menuOrder={4} />}
			{auth.role_id == 1 ? <Sidebar subtype={3} /> : ''}
            <Box component="section" className="contentWraper">
                <Box className='whiteContainer p-20'>
                    <Typography component='h6' className='formTitle mb-20'>Edit Course</Typography>

                    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {activeStep === 0 &&
                        <Box className='steppy_content'>
                            <Box className="maxw_700">
                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Course Name <Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            size="small"
                                            name='coursename'
                                            placeholder='Name'
                                            className='textfield'
                                            style={{ width: '100%' }}
                                            value={course.coursename}
                                            onChange={(e) => {
                                                handlechange(e); setError(prevError => ({ ...prevError, coursename: '' }))
                                            }}
                                        />
                                        {error.coursename && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.coursename}</p>}
                                    </FormControl>
                                </Box>

                                <Box className='mb-20'>
                                    <FormControlLabel className="checkbox_list"
                                        control={
                                            <Checkbox
                                                checked={isPublic}
                                                onChange={handleTogglePublic}
                                                name='checkbox-value'
                                                color='primary'
                                            />
                                        }
                                        label={<Typography component='div' className='label mb-0'>Do you want to make this event as Public? </Typography>}
                                    />
                                    <FormControlLabel className="checkbox_list"
                                        control={
                                            <Checkbox
                                                checked={isPrimary}
                                                onChange={handleTogglePrimary}
                                                name='checkbox-value'
                                                color='primary'
                                            />
                                        }
                                        label={<Typography component='div' className='label mb-0'>Do you want to make this event as Primary? </Typography>}
                                    />
                                </Box>

                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Select Mode<Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl fullWidth >
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={status ? status : "sel"}
                                            placeholder="Select status of course"
                                            className="select"
                                            onChange={handleStatus}
                                        >
                                            <MenuItem disabled value={'sel'}>Select status of course</MenuItem>
                                            <MenuItem value={"online"}>Online</MenuItem>
                                            <MenuItem value={"offline"}>Offline</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Select Category <Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl fullWidth >
                                        <Select
                                            className="select"
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={categoryId ? categoryId : 'sel'}
                                            onChange={(e) => { CategoryData(e); }}>
                                            <MenuItem disabled value={'sel'}>Select category</MenuItem>
                                            {category && category?.length > 0 ? category.map((data) => (
                                                <MenuItem value={data._id}>{data.category_title}</MenuItem>
                                            )) :
                                                <MenuItem disabled>{"No Record's found"}</MenuItem>}
                                        </Select>
                                        {catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>}
                                    </FormControl>
                                </Box>
                                <Box className="mb-20">

                                    <Typography component='div' className='label' style={{ marginRight: '10px' }}>Select Sub Category</Typography>
                                    <Box className="mb-10" style={{ display: 'flex', alignItems: 'center' }}>
                                        <FormControl fullWidth style={{ flex: 1 }}>
                                            <Select
                                                className="select"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                disabled={categoryId !== '' ? false : true}
                                                value={subcategoryId ? subcategoryId : 'sel18'}
                                                onChange={(e) => { setSubCategoryId(e.target.value); setSubCatError('') }}>
                                                <MenuItem disabled value={'sel18'}>Select sub category</MenuItem>
                                                {subcat && subcat?.length > 0 ? (
                                                    subcat.map((data5) => (
                                                        <MenuItem value={data5._id}>{data5.subcategory_title}</MenuItem>
                                                    ))) : (<MenuItem disabled>{"No Record's found"}</MenuItem>)}
                                            </Select>
                                            {subcatError && (<p style={{ color: 'red' }} className="fz-13 m-0">{subcatError}</p>)}
                                        </FormControl>
                                        {subcategoryId !== '' && (
                                            <RestartAltIcon style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleRemoveRestart()} />
                                        )}
                                    </Box>
                                </Box>



                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Start Date <Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth className="datepicker">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                                <DateTimePicker
                                                    size="small"
                                                    onChange={handleFromDateTimeChange}
                                                    format="DD-MM-YYYY hh:mm A"
                                                    ampm={true}
                                                    value={selectedFromDateTime && selectedFromDateTime}
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                        seconds: renderTimeViewClock,
                                                    }}
                                                    className="textfield"

                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        {error.start_date && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.start_date}</p>}
                                    </FormControl>
                                </Box>
                                <Box className="mb-20">
                                    <Typography component='div' className='label'>End Date<Typography component='span' className='star'></Typography></Typography>
                                    <FormControl variant="standard" fullWidth className="datepicker">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                                <DateTimePicker
                                                    size="small"
                                                    onChange={handleToDateTimeChange}
                                                    format="DD-MM-YYYY hh:mm A"
                                                    ampm={true}
                                                    value={selectedToDateTime && selectedToDateTime}
                                                    viewRenderers={{
                                                        hours: renderTimeViewClock,
                                                        minutes: renderTimeViewClock,
                                                        seconds: renderTimeViewClock,
                                                    }}
                                                    className="textfield"

                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                        {error.end_date && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.end_date}</p>}
                                    </FormControl>
                                </Box>

                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Fees <Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            placeholder='Fees'
                                            size="small"
                                            name='fees'
                                            className='textfield'
                                            style={{ width: '100%' }}
                                            value={course.fees}
                                            onChange={(e) => {
                                                handlechange(e); setError(prevError => ({ ...prevError, fees: '' }))
                                            }}
                                        />
                                        {error.fees && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.fees}</p>}
                                    </FormControl>
                                </Box>
                                {/* <Box className=" mb-20">
                                    <Typography component='div' className='label'>Teacher/Community</Typography>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-customized-radios"
                                        name="payment_type"
                                        onChange={handleEditOwner}
                                        value={selectOwner}
                                    >
                                        <FormControlLabel
                                            value="Teacher"
                                            className='radio-btns'
                                            control={<BpRadio />}
                                            label={<Typography className='fz-14'>Teacher</Typography>}
                                        />
                                        <FormControlLabel
                                            value="Community"
                                            className='radio-btns'
                                            control={<BpRadio />}
                                            label={<Typography className='fz-14'>Community</Typography>}
                                        />
                                    </RadioGroup>
                                </Box> */}
                                {/* {selectOwner === 'Teacher' ? <> */}
                                    {/* <Box className="mb-20">
                                        <Typography component='div' className='label'>Select Teacher <Typography component='span'></Typography></Typography>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                className='select'
                                                value={selectedTeacher}
                                                onChange={(e) => handlechangeTeacherSelected(e,)}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    const selectedTeacherss = teacherDataUpdated.filter(teacher => selected.includes(teacher.username));
                                                    if (selectedTeacherss.length === 0) {
                                                        return "Any teacher";
                                                    } else if (selectedTeacherss.length === 1) {
                                                        return selectedTeacherss[0].username;
                                                    } else {
                                                        return `${selectedTeacherss.length} teacher selected`;
                                                    }
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {teacher && teacher.length > 0 ? teacher.map((name) => (
                                                    <MenuItem key={name._id} value={name.username} >
                                                        <Checkbox checked={selectedTeacher.indexOf(name.username) > -1} />
                                                        <ListItemText primary={name.username} />
                                                    </MenuItem>
                                                )) : <MenuItem>No teacher found</MenuItem>}
                                            </Select> */}
                                            {/* <p className='error-field'>{error.userselect}</p> */}
                                        {/* </FormControl> */}
                                    {/* </Box> */}
                                {/* </> : <> */}
                                    {/* <Box className="mb-20">
                                        <Typography component='div' className='label'>Select Community <Typography component='span'></Typography></Typography>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                className='select'
                                                value={selectedCommunity}
                                                onChange={(e) => handlechangeCommunitySelected(e,)}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    const selectedCommunitiesss = communityDataUpdated.filter(community => selected.includes(community.coummunity_name));
                                                    if (selectedCommunitiesss.length === 0) {
                                                        return "Any community";
                                                    } else if (selectedCommunitiesss.length === 1) {
                                                        return selectedCommunitiesss[0].coummunity_name;
                                                    } else {
                                                        return `${selectedCommunitiesss.length} community selected`;
                                                    }
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {community && community.length > 0 ? community.map((name) => (
                                                    <MenuItem key={name._id} value={name.coummunity_name} >
                                                        <Checkbox checked={selectedCommunity.indexOf(name.coummunity_name) > -1} />
                                                        <ListItemText primary={name.coummunity_name} />
                                                    </MenuItem>
                                                )) : <MenuItem>No community found</MenuItem>}
                                            </Select>
                                            {/* <p className='error-field'>{error.userselect}</p> */}
                                        {/* </FormControl> */}
                                    {/* </Box> */}
                                {/* </>}  */}

                                {status === 'offline' &&
                                    <Box className="mb-20">
                                        <Typography component='div' className='label'>Location</Typography>
                                        <AutoComplete_Edit cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} />
                                    </Box>}
                                <Box className="mb-20">
                                <Typography component='div' className='label'>Cover Image <Typography component='span' className='star'>*</Typography></Typography>
                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                        {statesingle.length < 1 &&
                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                            </Button>
                                        }
                                    </Box>
                                </Box>
                                {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
                                {statesingle.length > 0 &&
                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                        {statesingle.length > 0 ? statesingle.map((x, i) => (
                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                <Box className='uploaded_img' >
                                                    <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                    <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                </Box>
                                            </Grid>
                                        )) : ""}

                                    </Grid>
                                }

                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Short Description</Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <textarea
                                            placeholder='Short Description'
                                            className='textarea'
                                            defaultValue={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            rows={4}
                                            cols={40} />
                                    </FormControl>
                                </Box>

                                <Box className='heading-btns mt-40' style={{ textAlign: 'end' }}>
                                    <Button onClick={handleNext} variant="contained" className='theme-btn  '>Next</Button>
                                </Box>

                            </Box>
                        </Box >
                    }
                    {
                        activeStep === 1 &&


                        <Box className="steppy_content mb-10">
                            <Grid container columnSpacing={2} rowSpacing={2} className=" mb-10">

                                <Grid item xs={12} sm={4} md={4} lg={4}>
                                    <Typography component='div' className='label'>Images/Video </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} lg={8}>
                                    <Box className=" ">
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-customized-radios"
                                            name="payment_type"
                                            onChange={handleEditGallery}
                                            value={selectGallert}
                                        >
                                            <FormControlLabel value="Image" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Additional images</Typography>} />
                                            <FormControlLabel value="Video" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Video</Typography>} />
                                        </RadioGroup>
                                    </Box>
                                </Grid>
                                {selectGallert === 'Image' ? <>
                                    <Grid item xs={12} sm={12} md={12}  >
                                        <Box className=" ">
                                            <Typography component='div' className='label'>Additional Images</Typography>
                                            {state1.length < 1 &&
                                                <Button className="multiple_upload_btn" variant="contained" component="label">
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                    <input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChangeMultiple} hidden multiple type="file" />
                                                </Button>
                                            }
                                        </Box>
                                    </Grid>
                                    {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0 ml-20" >{imageAddError}</p>}
                                    <Grid item xs={12} sm={12} md={12}  >
                                        {state1.length > 0 &&
                                            <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container">
                                                {state1.length > 0 ? state1.map((x, i) => (
                                                    <Grid item xs={6} sm={4} md={3} lg={3} >
                                                        <Box className='uploaded_img' >
                                                            <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
                                                            <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                        </Box>
                                                    </Grid>
                                                )) : ""}
                                                <Grid item xs={6} sm={4} md={3} lg={3} >
                                                    <label htmlFor="contained-button-file1" className="w-full">
                                                        <Box className='multiple_upload_btn' >
                                                            <Input
                                                                inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
                                                                onChange={multipleImgChangeMultiple}
                                                                style={{ display: "none", }}
                                                                id="contained-button-file1"
                                                                type="file"
                                                            />
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
                                                        </Box>
                                                    </label>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                                    </Grid>
                                </> : <>
                                    <Grid item xs={12} sm={12} md={12}>
                                    <Typography component='div' className='label'>Video</Typography>
                                        <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                            {videoPreviewUrl && videoPreviewUrl?.length === 0 &&
                                                <Button className="multiple_upload_btn" variant="contained" component="label">
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                    </Box>
                                                    <input id="video-upload" accept="video/*" onChange={handleVideoChange} hidden type="file" />
                                                </Button>
                                            }
                                            {videoPreviewUrl && videoPreviewUrl?.length > 0 &&
                                                <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                    {videoPreviewUrl.length > 0 ? videoPreviewUrl.map((x, i) => (
                                                        <Grid item xs={6} sm={4} md={4} lg={4} >
                                                            <Box  >
                                                                <Button className='close_icon' onClick={(e) => clearVideo(e, i)}><Close /></Button>
                                                                <video className='uploaded_vdo' controls>
                                                                    <source src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} height={155} width={195} />
                                                                </video>
                                                            </Box>
                                                        </Grid>
                                                    )) : ""}
                                                </Grid>
                                            }
                                        </Box>
                                        {error.video && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.video}</p>}
                                    </Grid>


                                </>}

                                {/* <Grid item xs={12} sm={12} md={6} lg={6} className='mb-10'>
                                    <Typography component='div' className='label'>Description</Typography>
                                    <div className="editorclass">
                                        <Editor
                                            editorState={editorState}
                                            onEditorStateChange={handleEditorChange}
                                            toolbarClassName="toolbar"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorcontent"

                                            toolbar={{
                                                options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image'],
                                                inline: {
                                                    options: ['bold', 'italic', 'underline'],
                                                },
                                                blockType: {
                                                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                },
                                                list: {
                                                    options: ['unordered', 'ordered'],
                                                },
                                                textAlign: {
                                                    options: ['left', 'center', 'right', 'justify'],
                                                },
                                                link: {
                                                    options: ['link'],
                                                },
                                                emoji: {
                                                    options: [
                                                        '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
                                                        '😍', '😋', '😎', '😴', '🤔', '😳', '🤗', '🤐', '😷', '🤒'
                                                    ],
                                                },
                                                image: {
                                                    uploadEnabled: true,
                                                    previewImage: true,
                                                    inputAccept: 'image/*',
                                                    alt: { present: false, mandatory: false },
                                                },
                                            }}
                                        />
                                    </div>
                                </Grid>
                                */}
                                {
                                    activeStep === 1 &&
                                    <Grid item xs={12} sm={12} md={12}>

                                        <Box className='heading-btns mt-40' style={{ textAlign: 'end' }}>
                                            <Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>

                                            <Button onClick={handleNext} variant="contained" className='theme-btn  '>Next</Button>
                                        </Box>
                                    </Grid>
                                }


                            </Grid>
                        </Box>
                    }
                    {activeStep === 2 &&
                        <Box className="steppy_content mb-10">
                            <Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
                                <EmailEditor ref={emailEditorRef}
                                    onLoad={onLoad}
                                    projectId={144031}
                                    tools={{
                                        "custom#product_tool": {
                                            // data: { products },
                                            // properties: {
                                            // 	productLibrary: {
                                            // 		editor: {
                                            // 			data: {
                                            // 				products,
                                            // 			},
                                            // 		},
                                            // 	},
                                            // },
                                        },
                                    }}
                                    options={{
                                        designTags: {
                                            business_name: "My business",
                                            current_user_name: "Ron",
                                        },
                                        customCSS: [
                                            "https://examples.unlayer.com/examples/product-library-tool/productTool.css",
                                        ],
                                        customJS: [
                                            window.location.protocol + "//" +
                                            window.location.host + "/custom.js",
                                        ],
                                    }}
                                />
                            </Grid>
                            <Box className='heading-btns mt-20' style={{ textalign: 'end' }}>
                                <Button onClick={handleEditor} variant="contained" className='theme-btn  mr-10'>SaveDesign</Button>
                            </Box>
                        </Box>
                    }

                    <>
                        {activeStep === 2 && truebutton === false ?
                            <>
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
                                    <Button onClick={handleUpdateService} variant="contained" className='theme-btn'>Update</Button>
                                </Box>
                            </>
                            :
                            activeStep === 2 && truebutton === true &&
                            <>
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button>
                                    <Button variant="contained" className='theme-btn  mr-10'>Please wait...</Button>
                                </Box>

                            </>
                        }
                    </>
                </Box>
            </Box>
        </Box>
    )
}