import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../components/drawer';
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { SketchPicker } from 'react-color';
import { Box, Button, FormControl, Grid, TextField, Typography, FormControlLabel, RadioGroup, Radio, Checkbox, ListItemText, MenuItem, Select, OutlinedInput } from '@mui/material'
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#f3714d',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#f3714d',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <ListAlt />,
        2: <CompareIcon />,
        3: <Dvr />,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};



export default function Service_Steppyform() {
    const navigate = useNavigate()
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    let location = useLocation()
    const [activeStep, setActiveStep] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar()
    const [management, setManagement] = React.useState({ title: location.state.data.title, name: location.state.data.shop_now, link: location.state.data.link })
    const [truebutton, setTruebutton] = React.useState(false)
    const [error, setError] = React.useState({ title: "", subtitle: "", link: "" })
    const [imageError, setImageError] = React.useState('')
    const [statesingle, setStatesingle] = React.useState([...[], location.state.data.image])
    const [statesingle2, setStatesingle2] = React.useState([...[], location.state.data.image])
    const [description, setDescription] = React.useState(location.state.data.sub_title)
    const [isToggled, setToggled] = React.useState(location.state.data.mega)
    const [isPrimary, setPrimary] = React.useState(location.state.data.primary)
    const [isPublic, setPublic] = React.useState(location.state.data.public)
    const [pageDataUpdated, setPageDataUpdated] = React.useState([])
    const [pageUpdated, setpageUpdated] = React.useState([])
    const [page, setPage] = React.useState([])
    const [event, setEvent] = React.useState([])
    const [community, setCommunity] = React.useState([])
    const [selectOwner, setSelectOwner] = useState(location.state.data.adsby)

    let name, value;
    const handlechange = (e) => {
        name = e.target.name;
        value = e.target.value;
        if (name === 'name' && value.length > 10) {
            return;
        }
        setManagement({ ...management, [name]: value })

    }

    const handleEditOwner = (e) => {
        setSelectOwner(e.target.value);
    }

    const handleToggle = () => {
        setToggled(!isToggled)
    }
    const handleTogglePrimary = () => {
        setPrimary(!isPrimary)
    }

    const handleTogglePublic = () => {
        setPublic(!isPublic)
    }
    const handleDescriptionChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 50) {
            setDescription(inputValue);
        }
    };

    const [color, setColor] = React.useState(location.state.data.addColor);
    const handleChangeComplete = (color) => {
        setColor(color.hex);
    };


    React.useEffect(() => {
        getEvent()
    }, [])

    const getEvent = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Event/getEventNames`, { headers: headerlocal });
            setEvent(response.data.Events);
            setEventDataUpdated(response.data.Events)
            console.log(response, "Events");
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [eventUpdated, setEventUpdated] = React.useState([])
    const [eventDataUpdated, setEventDataUpdated] = React.useState([])

    const [selecttedEvent, setSelectedEvent] = React.useState(() => {
        const arrVal = []
        const eventArr = location.state.data.events
        console.log(location.state.data.events, "location.state.data.events");
        if (eventArr.length > 0) {
            eventArr.map((item) => {
                arrVal.push(item.event_name)
            })
        }
        return arrVal
    });

    React.useEffect(() => {
        getEventSelected();
    }, [selecttedEvent, eventDataUpdated])

    function getEventSelected() {
        var array = []
        const selectedEvents = eventDataUpdated.filter(content => selecttedEvent.includes(content.username));
        selectedEvents.map((item, i) => {
            var val = { event_name: item.event_name }
            Object.assign(item, val)
            array.push(val)
        })
        setEventUpdated(array)
    }

    const handlechangeEventSelected = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setSelectedEvent(
            typeof value === 'string' ? value.split(',') : value,
        );
        getEventSelected()
    }

    React.useEffect(() => {
        getCommunity()
    }, [])

    const getCommunity = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Community/getCommunityNames`, { headers: headerlocal });
            setCommunity(response.data.communities);
            setCommunityDataUpdated(response.data.communities)
            console.log(response, "community");
        } catch (error) {
            console.log(error.response, "error");
        }
    }

    const [communityUpdated, setCommunityUpdated] = React.useState([])
    const [communityDataUpdated, setCommunityDataUpdated] = React.useState([])

    const [selectedCommunity, setSelectedCommunity] = React.useState(() => {
        const arrVal = []
        const communityArr = location.state.data.community
        console.log(location.state.data.community, "location.state.data.community");
        if (communityArr.length > 0) {
            communityArr.map((item) => {
                arrVal.push(item.coummunity_name, item._id)
            })
        }
        return arrVal
    });

    React.useEffect(() => {
        getCommunitySelected();
    }, [selectedCommunity, communityDataUpdated])

    function getCommunitySelected() {
        var array = []
        const selectedCommunities = communityDataUpdated.filter(content => selectedCommunity.includes(content.coummunity_name));
        selectedCommunities.map((item, i) => {
            var val = { coummunity_name: item.coummunity_name, _id: item._id }
            Object.assign(item, val)
            array.push(val)
        })
        setCommunityUpdated(array)
        console.log(selectedCommunities,"selectedCommunities");
        console.log(array,"array");
        console.log(communityUpdated,"communityUpdated");

    }



    const handlechangeCommunitySelected = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
            target: { value },
        } = event;
        setSelectedCommunity(
            typeof value === 'string' ? value.split(',') : value,
        );
        getCommunitySelected()
    }
    console.log(value);


    const SingleImage = (e, index) => {
        setImageError('');
        const fileObj = e.target.files;

        const fileArray = [];
        const filepath = [];

        for (let i = 0; i < fileObj.length; i++) {
            let file = fileObj[i];
            if (file.size > 1000000) {
                setImageError("Image should be less than 1 MB");
                continue;
            }

            fileArray.push(URL.createObjectURL(file));
            filepath.push(file);
        }


        const data = statesingle.filter(x => x != null);
        const data1 = statesingle2.filter(x => x != null);

        setStatesingle(prevValue => [...prevValue, ...fileArray]);
        setStatesingle2(prevValue => [...prevValue, ...filepath]);
    };

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    const validate = () => {
        if (!management.title && !management.subtitle && statesingle?.length == 0 && !management.link && !management.name) {
            setError({ title: "This field is required.", link: "This field is required.", name: "This field is required." })
            setImageError("This field is required.")
            return false
        }
        if (!management.title) {
            setError({ title: "This field is required." })
            return false
        }
        if (!management.name) {
            setError({ name: "This field is required." })
            return false
        }
        // if (!description) {
        //     setError({ description: "This field is required." })
        //     return false
        // }
        if (statesingle?.length == 0) {
            setImageError("This field is required.")
            return false
        }
        if (!management.link) {
            setError({ link: "This field is required." })
            return false
        }
        return true
    }

    const handleSubmit = async () => {
        var valid = validate()
        if (valid == true) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append("title", management.title)
            formData.append("sub_title", description)
            formData.append("link", management.link)
            formData.append("shop_now", management.name)
            formData.append("mega", isToggled)
            formData.append("primary", isPrimary)
            formData.append("public", isPublic)
            formData.append("addColor", color)
            formData.append('page_permissions', JSON.stringify(page.length > 0 ? page : []))
            // formData.append('events', JSON.stringify(eventUpdated.length > 0 ? eventUpdated : []))
            formData.append('community', JSON.stringify(communityUpdated.length > 0 ? communityUpdated : []))
            // formData.append("adsby", selectOwner === 'Event' ? 'Event' : 'Community')
            { statesingle2 ? statesingle2.forEach((item) => { formData.append("image", item) }) : formData.append("image", '') }
            console.log(...formData, "editttt");
            axios.put(process.env.REACT_APP_BASE_URL + `AddManagement/UpdateManagement/${location.state.data._id}`, formData, { headers: headerformdata })
                .then((res) => {
                    console.log(res, "adsedit");
                    setTruebutton(false)
                    navigate('/offers');
                    setManagement({ title: "", description: "", link: "", name: "" });
                    enqueueSnackbar("Ad updated successfully", { variant: 'success' })

                }).catch((err) => {
                    setTruebutton(false)
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
                    console.log(err.response, "error");
                })
        }
    }


    React.useEffect(() => {
        getPage()
    }, [])

    const getPage = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `Advertisement/getPages`, { headers: headerlocal });
            setPage(response.data.getadvertisement);
            setPageDataUpdated(response.data.getadvertisement)
        } catch (error) {
            console.log(error.response, "error");
        }
    }
    console.log(location.state.data.page_permissions, 'page_permissionslocation');
    const [selectedPage, setSelectedPage] = React.useState(() => {
        const arrVal = []
        const pageArr = location.state.data.page_permissions ? location.state.data.page_permissions : []
        const permission_true = pageArr.filter(pageItem => pageItem.isAddEnabled === true);
        if (permission_true.length > 0) {
            permission_true.map((item) => {
                arrVal.push(item.pageName)
            })
        }
        return arrVal
    });

    React.useEffect(() => {
        getPageSelected();
    }, [selectedPage, pageDataUpdated])

    function getPageSelected() {
        var array = []
        const selectedUser = pageDataUpdated.filter(content => selectedPage.includes(content.pageName));
        selectedUser.map((item, i) => {
            var val = { username: item.username, user_id: item._id, email: item.email, image: item.image ? item.image : '', specialization: item.specialization ? item.specialization : '' }
            Object.assign(item, val)
            array.push(val)
        })
        setpageUpdated(array)
    }
    const [selectedPages, setSelectedPages] = useState([]);

    const handlechangeUserSelected = (event, item) => {
        const {
            target: { value },
        } = event;
        setSelectedPage(
            typeof value === 'string' ? value.split(',') : value,
        );
        getPageSelected()

        const { value: selectedPageNames } = event.target;
        const selectedPages = page.filter((page) => selectedPageNames.includes(page.pageName));
        const updatedPages = page.map((item) => {
            if (selectedPages.some((selectedPage) => selectedPage._id === item._id)) {
                return { ...item, isAddEnabled: true };
            } else {
                return { ...item, isAddEnabled: false };
            }
        });
        setPage(updatedPages);
        setSelectedPages(selectedPageNames);
    }

    console.log(location.state.data.community,"location.state.data.community"); // Check initial selected communities
    console.log(community,"community"); // Check communities fetched and set
    console.log(selectedCommunity,"selectedCommunity"); // Check selected communities after user interaction
    console.log(communityUpdated,"communityUpdated"); // Check updated communities after selection
    


    return (
        <Box className=''>
            <MiniDrawer menuOrder={16} />
            <Box component="section" className="contentWraper">
                <Grid container>
                    <Grid item xs={12} sm={12} md={8} lg={6}  >
                        <Box className='whiteContainer p-20'>
                            <Typography component='h6' className='formTitle mb-20'>Update </Typography>
                            <Box className="">
                                <Box className="mb-10">
                                    <Typography component='div' className='label'>Title<Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="title"
                                            name="title"
                                            placeholder="Title"
                                            size="small"
                                            className='textfield'
                                            style={{ width: '100%' }}
                                            value={management.title}
                                            onChange={(e) => {
                                                handlechange(e); setError(prevError => ({ ...prevError, title: '' }))
                                            }}

                                        />
                                        {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                    </FormControl>
                                </Box>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Box className="mb-10">
                                        <Typography component='div' className='label'>Short Description<Typography component='span' ></Typography></Typography>
                                        <FormControl variant="standard" fullWidth>
                                            <textarea
                                                placeholder='Short Description'
                                                className='textarea'
                                                value={description}
                                                onInput={(e) => {
                                                    handleDescriptionChange(e);
                                                    setError(prevError => ({ ...prevError, description: '' }));
                                                }}
                                                rows={4}
                                                cols={40} />
                                            {/* {error.description && <p style={{ color: 'red' }} className="fz-13 m-0">{error.description}</p>} */}
                                        </FormControl>
                                    </Box>
                                </Grid>


                                <Box className="mb-10">
                                    <Typography component='div' className='label'>Select Pages <Typography component='span' ></Typography></Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            className='select w100'
                                            value={selectedPage}
                                            onChange={(e) => handlechangeUserSelected(e,)}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                const selectedUser = pageDataUpdated.filter(user => selected.includes(user.pageName));

                                                if (selectedUser.length === 0) {
                                                    return "Any Page";
                                                } else if (selectedUser.length === 1) {
                                                    return selectedUser[0].pageName;
                                                } else {
                                                    return `${selectedUser.length} Page slected`;
                                                }
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {page && page.length > 0 ? page.map((name) => (
                                                <MenuItem key={name._id} value={name.pageName} >
                                                    <Checkbox checked={selectedPage.indexOf(name.pageName) > -1} />
                                                    <ListItemText primary={name.pageName} />
                                                </MenuItem>
                                            )) : <MenuItem>No page found</MenuItem>}
                                        </Select>
                                        {/* <p className='error-field'>{error.userselect}</p> */}
                                    </FormControl>
                                </Box>

                                {/* <Box className=" mb-20">
                                    <Typography component='div' className='label'>Do you want to assign this ads to any event/community</Typography>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-customized-radios"
                                        name="payment_type"
                                        onChange={handleEditOwner}
                                        value={selectOwner}
                                    >
                                        <FormControlLabel
                                            value="Event"
                                            className='radio-btns'
                                            control={<Radio />}
                                            label={<Typography className='fz-14'>Event</Typography>}
                                        />
                                        <FormControlLabel
                                            value="Community"
                                            className='radio-btns'
                                            control={<Radio />}
                                            label={<Typography className='fz-14'>Community</Typography>}
                                        />
                                    </RadioGroup>
                                </Box>  */}
                                {/* {selectOwner === 'Event' ? <> */}
                                {/* <Box className="mb-20">
                                        <Typography component='div' className='label'>Select Event <Typography component='span'></Typography></Typography>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-multiple-checkbox-label"
                                                id="demo-multiple-checkbox"
                                                multiple
                                                className='select'
                                                value={selecttedEvent}
                                                onChange={(e) => handlechangeEventSelected(e,)}
                                                input={<OutlinedInput />}
                                                renderValue={(selected) => {
                                                    const selectedEventss = eventDataUpdated.filter(event => selected.includes(event.event_name));
                                                    if (selectedEventss.length === 0) {
                                                        return "Any event";
                                                    } else if (selectedEventss.length === 1) {
                                                        return selectedEventss[0].event_name;
                                                    } else {
                                                        return `${selectedEventss.length} event selected`;
                                                    }
                                                }}
                                                MenuProps={MenuProps}
                                            >
                                                {event && event.length > 0 ? event.map((name) => (
                                                    <MenuItem key={name._id} value={name.event_name} >
                                                        <Checkbox checked={selecttedEvent.indexOf(name.event_name) > -1} />
                                                        <ListItemText primary={name.event_name} />
                                                    </MenuItem>
                                                )) : <MenuItem>No event found</MenuItem>}
                                            </Select>
                                            {/* <p className='error-field'>{error.userselect}</p> */}
                                {/* </FormControl> */}
                                {/* </Box>  */}
                                {/* </> : <> */}
                                <Box className="mb-20">
                                    <Typography component='div' className='label'>Do you want to assign this ads to any community<Typography component='span'></Typography></Typography>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            className='select'
                                            value={selectedCommunity}
                                            onChange={(e) => handlechangeCommunitySelected(e,)}
                                            input={<OutlinedInput />}
                                            renderValue={(selected) => {
                                                const selectedCommunitiesss = communityDataUpdated.filter(community => selected.includes(community.coummunity_name));
                                                if (selectedCommunitiesss.length === 0) {
                                                    return "Any community";
                                                } else if (selectedCommunitiesss.length === 1) {
                                                    return selectedCommunitiesss[0].coummunity_name;
                                                } else {
                                                    return `${selectedCommunitiesss.length} community selected`;
                                                }
                                            }}
                                            MenuProps={MenuProps}
                                        >
                                            {community && community.length > 0 ? community.map((name) => (
                                                <MenuItem key={name._id} value={name.coummunity_name} >
                                                    <Checkbox checked={selectedCommunity.indexOf(name.coummunity_name) > -1} />
                                                    <ListItemText primary={name.coummunity_name} />
                                                </MenuItem>
                                            )) : <MenuItem>No community found</MenuItem>}
                                        </Select>
                                        {/* <p className='error-field'>{error.userselect}</p> */}
                                    </FormControl>
                                </Box>
                                {/* </>}   */}

                                <Box className="mb-10">
                                    <Typography component='div' className='label'>Button Name<Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="name"
                                            name="name"
                                            placeholder="Name"
                                            size="small"
                                            className='textfield'
                                            style={{ width: '100%' }}
                                            value={management.name}
                                            onChange={(e) => {
                                                handlechange(e);
                                                setError(prevError => ({ ...prevError, name: '' }));
                                            }}
                                        />
                                        {error.name && <p style={{ color: 'red' }} className="fz-13 m-0">{error.name}</p>}
                                    </FormControl>
                                </Box>
                                <Box className='mb-20'>
                                    <FormControlLabel className="checkbox_list"
                                        control={
                                            <Checkbox
                                                checked={isToggled}
                                                onChange={handleToggle}
                                                name='checkbox-value'
                                                color='primary'
                                            />
                                        }
                                        label={<Typography component='div' className='label mb-0'>Do you want to add this as Mega Ad? </Typography>}
                                    />
                                    <FormControlLabel className="checkbox_list"
                                        control={
                                            <Checkbox
                                                checked={isPublic}
                                                onChange={handleTogglePublic}
                                                name='checkbox-value'
                                                color='primary'
                                            />
                                        }
                                        label={<Typography component='div' className='label mb-0'>Do you want to make this as Public? </Typography>}
                                    />
                                    <FormControlLabel className="checkbox_list"
                                        control={
                                            <Checkbox
                                                checked={isPrimary}
                                                onChange={handleTogglePrimary}
                                                name='checkbox-value'
                                                color='primary'
                                            />
                                        }
                                        label={<Typography component='div' className='label mb-0'>Do you want to make this as Primary? </Typography>}
                                    />
                                </Box>

                                <Grid item xs={12} sm={12} md={12}  >
                                    <Typography component='div' className='label'>Primary Image <Typography component='span' className='star'> * </Typography></Typography>
                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                        {statesingle.length < 1 &&
                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                            </Button>
                                        }
                                    </Box>
                                </Grid>
                                {imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
                                <Grid item xs={12} sm={12} md={12}  >
                                    {statesingle.length > 0 &&
                                        <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                            {statesingle.length > 0 ? statesingle.map((x, i) => (
                                                <Grid item xs={6} sm={4} md={4} lg={4} >
                                                    <Box className='uploaded_img' >
                                                        <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                        <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                    </Box>
                                                </Grid>
                                            )) : ""}

                                        </Grid>
                                    }
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
                                </Grid>
                                <Box className="mb-10">
                                    <Typography component='div' className='label'>WebsiteLink <Typography component='span' className='star'>*</Typography></Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <TextField
                                            id="filled-size-small"
                                            variant="outlined"
                                            placeholder='Link'
                                            size="small"
                                            name='link'
                                            className='textfield'
                                            style={{ width: '100%' }}
                                            value={management.link}
                                            onChange={(e) => {
                                                handlechange(e); setError(prevError => ({ ...prevError, link: '' }))
                                            }}
                                        />
                                        {error.link && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.link}</p>}
                                    </FormControl>
                                </Box>

                                <Box className="mb-10">
                                    <Typography component='div' className='label'>Colour picker</Typography>
                                    <FormControl variant="standard" fullWidth>
                                        <div>
                                            <SketchPicker
                                                color={color}
                                                onChangeComplete={handleChangeComplete}
                                            />
                                            <p>Selected Color: {color}</p>
                                            <div
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    backgroundColor: color,
                                                }}
                                            ></div>
                                        </div>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                <Button onClick={handleSubmit} variant="contained" className='theme-btn'>Submit</Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}