import React, { useState } from 'react'
import { Box, Button, Grid, IconButton, Stack, TextField, Typography, styled, Divider } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import Avatar from '../../../../../assets/images/Avatar.png';
import axios from 'axios';
import { Pagination,Autoplay } from 'swiper/modules';
import moment from 'moment';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import playBtn from '../../../../../assets/images/play.png'
import CloseIcon from '@mui/icons-material/Close';
import videoPoster from '../../../../../assets/images/TeacherBanner.png'
import yoga2 from '../../../../../assets/images/Jobsbanner.png'
// import Header from '../../../Home/Header';
// import Menu from '../../../Menu';
// import Footer from '../../../Home/Footer';
import Spacing from '../../../../../components/Spacing';
import Comment_section from './Comment_section';
import { MdDelete } from "react-icons/md";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import AudioPlayer from 'react-h5-audio-player';
import pdf from '../../../../../assets/images/pdf.png'

export default function Fans_page() {
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const location = useLocation()
    const params = useParams()
    const [open, setOpen] = React.useState(false);
    const [postid,setPostId] = React.useState('')
    const [isActive, setIsActive] = React.useState(false);
    const [isActive2, setIsActive2] = React.useState(false);
    const [isActive3, setIsActive3] = React.useState(false);
    const [timeline, setTimeline] = React.useState([])
    const [newdata, setNewdata] = React.useState([])
    const [fansPage, setFansPage] = React.useState([])
    const [fans_id, setFans_id] = React.useState([])
    const currentURL = window.location.href;
    const hasMultipleImages = currentURL.includes("multiple-images");
    const [videourl,setVideoUrl] = React.useState('')
    const [openvideo,setOpenVideo] = React.useState(false)



    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));


//
    React.useEffect(() => {
        getFans()
    }, [])

    const getFans = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `FanPost/GetPostbyfanpageId/${params.id}`, { headers: headerlocal });
            setNewdata(response.data.data);
        } catch (error) {
            console.log(error, "error in service get");
        }
    }
    


    React.useEffect(() => {
        getFansPage();
    }, [fans_id]);

    const getFansPage = () => {
        if (fans_id.length > 0) {
            axios.get(process.env.REACT_APP_BASE_URL + `Fanpagecomment/getCommentsByFanPageByIds/${fans_id}`)
                .then((res) => {
                    setFansPage(res.data.data);
                })
                .catch((error) => {
                    console.log(error, "error in Timeline");
                });
        }
    }

    const ConfirmDelete = (id) => {
        setPostId(id)
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleDeletePost = (id) => {
        axios.put(process.env.REACT_APP_BASE_URL + `FanPost/DeletePost/${id}`)
        .then((res)=>{
            getFans()
            setOpen(false)
            enqueueSnackbar('Post deleted successfully',{variant:'success'})
           
        })
        .catch((error)=>{
            console.log(error,"error in DeletePost");
        })
    }

//To Open & Close Video Modal:
    const handleOpenModal = (url) => {
        setVideoUrl(url)
        setOpenVideo(true);
    };

    const CloseVideoModal = () => {
        setOpenVideo(false)
        setVideoUrl('')
    }

    
    

    return (
        <Box className='community_details'>
           
            {newdata && newdata.length > 0 ? newdata.map((data) =>
            (
                
                <>
                
                {/* <Box className="container ">
                    <Box className="video_banner">
                    {data.additionalimages.length > 0 ? 
                                                <Swiper
                                                    spaceBetween={30}
                                                    pagination={{ clickable: true }}
                                                    autoplay={{ delay: 5000, disableOnInteraction: false }}
                                                    modules={[Pagination, Autoplay]}
                                                    className="mySwiper p-0"
                                                >
                                                    {data.additionalimages.map((img, imgIndex) => (
                                                        <SwiperSlide key={imgIndex}>
                                                            <img src={process.env.REACT_APP_BASE_URL + img} className='w100' alt={`slide-${imgIndex}`} />
                                                           
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                            : (
                                                <Swiper>
                                                    <SwiperSlide>
                                                        <React.Fragment>
                                                            <video
                                                                loop
                                                                muted
                                                                width='100%'
                                                                poster={process.env.REACT_APP_BASE_URL + data.image}
                                                            ></video>
                                                            {data.Video ? <Button disableRipple onClick={() => handleOpenModal(data.Video[0])}  className='playbtn'>
                                                                <img src={playBtn} alt="Play Button" />
                                                            </Button> : null}
                                                        </React.Fragment>
                                                    </SwiperSlide>
                                                </Swiper>
                                            )}
    
                    </Box>
                </Box> */}
                <Box className="container">
            <Box className="video_banner">
                {data.additionalimages.length > 0 ? (
                    <Swiper
                        spaceBetween={30}
                        pagination={{ clickable: true }}
                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper p-0"
                    >
                        {data.additionalimages.map((img, imgIndex) => (
                            <SwiperSlide key={imgIndex}>
                                <img
                                    src={process.env.REACT_APP_BASE_URL + img}
                                    className='w100'
                                    alt={`slide-${imgIndex}`}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : data.Video.length > 0 ? (
                    // If video is present and no additional images
                    <Swiper>
                        <SwiperSlide>
                            <React.Fragment>
                                <video
                                    loop
                                    muted
                                    width='100%'
                                    poster={process.env.REACT_APP_BASE_URL + data.image}
                                ></video>
                                {data.Video && (
                                    <Button
                                        disableRipple
                                        onClick={() => handleOpenModal(data.Video[0])}
                                        className='playbtn'
                                    >
                                        <img src={playBtn} alt="Play Button" />
                                    </Button>
                                )}
                            </React.Fragment>
                        </SwiperSlide>
                    </Swiper>
                ) : data.audio && data.audio.length > 0 ? (
                    // If audio files are present and no additional images or video
                    <Swiper
                        spaceBetween={30}
                        pagination={{ clickable: true }}
                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                        modules={[Pagination, Autoplay]}
                        className="mySwiper p-0"
                    >
                        {data.audio.map((audio, audioIndex) => (
                            <SwiperSlide key={audioIndex}>
                                    <AudioPlayer
                                        src={audio && audio.slice(0, 4) === "blob" ? audio : process.env.REACT_APP_BASE_URL + `${audio}`}
                                        onPlay={e => console.log("onPlay")}
                                        className='audio_player'
                                    />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : data.files && data.files.length > 0 ? (
                    <Swiper>
                    <SwiperSlide >
                <Grid container columnSpacing={2} rowSpacing={2}>
                {data.files?.map((item, audioIndex) => {
                    
                 let sizeInMB = (item.size / (1024*1024)).toFixed(2);
                    return(
                    
                    <Grid key={audioIndex} item xs={12} md={3}>
                        {/* <Button  className='w100 flex-center custom_card p-20 justify-content-start'> */}
                            <Box className='iconBox mr-10'>
                            <i class="fa-regular fa-file-pdf fz-26 text_white"></i>
                            </Box>
                            <Box className=' text-left '>
                            <img src={pdf} width={100} height={100} className='w100'
                                    alt='PDF'
                                />
                                <Typography component='div' variant='body1' className='text_black fz-14 semibold letter_sp '>{item.name}</Typography>
                                <Typography component='div' variant='body1' className='text_black fz-12 fw-400 letter_sp'>{sizeInMB} MB</Typography>
                            </Box>
                        {/* </Button> */}
                    </Grid>
            )})}
            </Grid>
                            </SwiperSlide>
                        
                    </Swiper>) : (
                        <Box style={{textalign:'center'}}>No media available</Box>
                    )}
            </Box>
        </Box>
                
                
                
                
                <Box className='p-20'>
                        <Box className='d-flex mb-20 flex-wrap'>
                        <img src={data.fan_page_id && data.fan_page_id?.profile ? process.env.REACT_APP_BASE_URL + `${data.fan_page_id?.profile}` : Avatar} width={60} className='mr-10 userImg' alt="User" />
                            <Box>
                                <Typography component='h6' variant='body1' className='fz-14 text_black semibold'>
                                    {data && data.fan_page_id?.name}
                                </Typography>
                                <Typography component='span' variant='body1' className='fz-14 text_grey fw-400'>
                                    {moment(data.createdAt).fromNow()} <i className='fa-solid fa-earth-americas'></i>
                                </Typography>
                            </Box>
                <MdDelete style={{marginLeft:'900px'}} onClick={()=>ConfirmDelete(data._id)}/>

                        </Box>
                        <Typography component='span' variant='body1' className='fz-14 text_black letter_sp fw-400'>
                            {data.short_description}
                        </Typography>
                    </Box>
                    <Divider />
                    {/* <Box>
                        <Button disableRipple className='likebtn btn  letter_sp pt-10 mr-20' onClick={() => like(data._id)}>
                            <i class="fa-solid fa-thumbs-up text_primary fz-18 mr-10"></i>
                            <Typography className='text_black fz-14 semibold'>{data && data.likes?.length}</Typography>
                        </Button>
                        <Box className={isActive3 ? 'comment-collapse' : 'comment-collapse comment-close'} >
                            <Comment_section data={data} getTimeLine={getFansPage}/>
                        </Box>
                        <Button disableRipple className='likebtn btn  letter_sp pt-10 mr-20' onClick={() => comment(data._id)}>
                            <i class="fa-regular fa-message text_primary fz-16 mr-10"></i>
                            <Typography className='text_black fz-14 semibold'>{data.comments.length}</Typography>
                        </Button>

                    </Box> */}
                    <Box>
                        <Box className="p-20">
                            <i class="fa-solid fa-thumbs-up text_primary fz-18 mr-10"></i> 
                            {data.likes ? data.likes.length : '0'}
                                                    
                             <Button disableRipple className='likebtn btn text_black letter_sp mr-20'><i class="fa-regular fa-message text_primary fz-16 mr-10"></i>{data.totalCommentsAndReplies}</Button>
                        </Box>
                        {<Box className={isActive3 ? 'comment-collapse' : 'comment-collapse comment-close'} >
                            <Comment_section data={data} getTimeLine={getFans}/>    
                        </Box>}
                    </Box>
                    </>
            )) : <Grid item xs={12} sm={12} md={12} lg={12}>
                <p className='text-center semibold'>No data found</p></Grid>

            }

            <Dialog open={open} keepMounted
                        onClose={handleCloseModal}
                        aria-describedby="alert-dialog-slide-description"
                        className='custom_modal'>
                        <DialogTitle>Would you like to delete this post?</DialogTitle>
                        <DialogActions>
                            <Button onClick={()=>handleCloseModal()} className='cancel_btn'>No</Button>
                            <Button onClick={()=>handleDeletePost(postid)} className='theme-btn'>Yes</Button>
                        </DialogActions>
            </Dialog>

             {/* To Play Video in Modal View  */}
             <BootstrapDialog
                    onClose={CloseVideoModal}
                    aria-labelledby="customized-dialog-title"
                    open={openvideo}
                    fullWidth
                    maxWidth="xl"
                    className='fullscreen_modal'
                >
                    <IconButton
                        aria-label="close"
                        onClick={CloseVideoModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'white',
                            zIndex: 1
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box>
                        <iframe width="100%" height="600px" src={process.env.REACT_APP_BASE_URL + `${videourl}`} frameborder="0" allowfullscreen allow='autoplay'></iframe>
                    </Box>
                </BootstrapDialog>
        </Box>

    )
}