import React, { useState } from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Tooltip, Grid, InputAdornment, Avatar, Input, TextField, FormControlLabel, RadioGroup, Checkbox, Radio, Typography, IconButton, TextareaAutosize } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined, AddAPhoto } from '@mui/icons-material';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'
import Avatarimage from "../../../assets/images/Avatar.png"
import { locale } from 'moment';
import Switch from '@mui/material/Switch';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import AutoComplete from "./Autocomplete";
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { usePlacesWidget } from "react-google-autocomplete";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

// const IOSSwitch = styled((props) => (
//     <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//     width: 40,
//     height: 20,
//     padding: 0,
//     '& .MuiSwitch-switchBase': {
//         padding: 0,
//         margin: 0,
//         transitionDuration: '300ms',
//         '&.Mui-checked': {
//             transform: 'translateX(20px)',
//             color: '#fff',
//             '& + .MuiSwitch-track': {
//                 backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
//                 opacity: 1,
//                 border: 0,
//             },
//             '&.Mui-disabled + .MuiSwitch-track': {
//                 opacity: 0.5,
//             },
//         },
//         '&.Mui-focusVisible .MuiSwitch-thumb': {
//             color: '#33cf4d',
//             border: '6px solid #fff',
//         },
//         '&.Mui-disabled .MuiSwitch-thumb': {
//             color:
//                 theme.palette.mode === 'light'
//                     ? theme.palette.grey[100]
//                     : theme.palette.grey[600],
//         },
//         '&.Mui-disabled + .MuiSwitch-track': {
//             opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
//         },
//     },
//     '& .MuiSwitch-thumb': {
//         boxSizing: 'border-box',
//         width: 18.5,
//         height: 18.5,
//     },
//     '& .MuiSwitch-track': {
//         borderRadius: 18.5 / 2,
//         backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
//         opacity: 1,
//         transition: theme.transitions.create(['background-color'], {
//             duration: 500,
//         }),
//     },
// }));


BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#f3714d',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});


export default function AddTeacher() {
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }

    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    let location = useLocation()
    var data = location.state ? location.state.result : null
    const [user, setUser] = React.useState({ firstname: '', username: '', email: '', gender: 'Male', phone: '', Imageprew: "", Imageraw: "", specialization: [''], description: "", plan: '', userid: '' });
    const [error, setError] = React.useState({ firstname: "", username: "", email: "", subscription: "" })
    const [isToggled, setToggled] = React.useState(false)
    const [isActive, setActive] = React.useState(false)
    const [plans, setPlans] = React.useState([])
    const [cityName, setCityName] = React.useState('')
    const [selectedPlan, setSelectedPlan] = useState('')
    const [userId, setUserId] = useState('')
    const [isChecked, setChecked] = React.useState(false)
    const [isPrimary, setPrimary] = React.useState(false)
    const [customerid, setCustomerId] = useState('')
    const [lat, setLat] = React.useState('')
    const [lng, setLng] = React.useState('')
    const [truebutton, setTruebutton] = React.useState(false)
    const [plan, setPlan] = useState('')
    const [users, setUsers] = useState('')
    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };
    // let username, uservalue;
    // const handleChange = (e,index) => {
    //     username = e.target.name;
    //     uservalue = e.target.value;
    //     if (username === 'username') {
    //         uservalue = uservalue.trim();
    //     }
    //     setUser({ ...user, [username]: uservalue })
    //     setError({ ...error, [username]: '' })
    //     setError('')
    // };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        let isValid = true;
        let errorMessage = '';

        // Validate Username
        if (name === 'username') {
            const trimmedValue = value.trim(); // Trim leading and trailing spaces
            if (trimmedValue === '' || !/^[a-zA-Z0-9]+$/.test(trimmedValue)) {
                isValid = false;
                errorMessage = "Username should only contain alphanumeric characters (no spaces or special characters)";
            }
        }

        // Validate Name
        if (name === 'firstname') {
            if (!value || /^\d+$/.test(value) || /^[^a-zA-Z0-9]+$/.test(value)) {
                isValid = false;
                errorMessage = "Name should not contain only numbers or special characters";
            }
        }

        // Validate Email
        if (name === 'email') {
            if (!value || /^\d+$/.test(value) || /^[^a-zA-Z0-9]+$/.test(value)) {
                isValid = false;
                errorMessage = "Please enter a valid Email";
            } else {
                const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
                if (!emailPattern.test(value)) {
                    isValid = false;
                    errorMessage = "Please enter a valid Email";
                }
            }
        }

        // Validate Specialization
        if (name === 'specialization') {
            if (!value || /^\d+$/.test(value) || /^[^a-zA-Z0-9]+$/.test(value)) {
                isValid = false;
                errorMessage = "Specialization should not contain only numbers or special characters";
            }
        }

        // Update state based on validation result
        if (name === 'specialization') {
            const updatedSpecializations = [...user.specialization];
            updatedSpecializations[index] = value;
            setUser({ ...user, specialization: updatedSpecializations });
        } else {
            setUser({ ...user, [name]: value });
        }

        // Update error state
        setError({ ...error, [name]: errorMessage });
    };

    const handleAddSpecialization = () => {
        setUser({ ...user, specialization: [...user.specialization, ''] });
    };

    React.useEffect(() => {
		getUser()
	}, [])

    const getUser = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Userrole/getUserroles`, { headers: headerlocal }).then((res) => {
			console.log(res,"users res");
            setUsers(res.data.userroles)
		}).catch((error) => {
			console.log(error.response, "error");
		})
	}

    const handleRemoveSpecialization = (index) => {
        const updatedSpecializations = [...user.specialization];
        updatedSpecializations.splice(index, 1);
        setUser({ ...user, specialization: updatedSpecializations });
    };

    // const handleaddphoto = (e) => {
    //     setUser({ ...user, Imageprew: URL.createObjectURL(e.target.files[0]), Imageraw: e.target.files[0] })
    //     setError('')
    // }

    const handleToggle = () => {
        setToggled(!isToggled)
    }

    const handleToggleActive = () => {
        setActive(!isActive)
    }

    const handleaddphoto = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 1) {
            setError({ image: 'Image should be less than 1 MB' });
            setUser({
                ...user,
                Imageprew: '',
                Imageraw: null,
            });
        } else {
            setUser({
                ...user,
                Imageprew: URL.createObjectURL(file),
                Imageraw: file,
            });
            setError({ image: '' });
        }
    };

    const handleChecked = () => {
        setChecked(!isChecked)
    }
    // console.log(!isChecked, "isCheckedisChecked");

    const handleTogglePrimary = () => {
        setPrimary(!isPrimary)
    }

   

    let urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i;
    const validation = () => {
        let isValid = true;
        const errors = {};

        const trimmedValue = user.username.trim();
        if (!user.username || trimmedValue === '' || !/^[a-zA-Z0-9]+$/.test(trimmedValue)) {
            errors.username = "Username can't be empty or contain any special characters";
            isValid = false;
        }

        if (!user.firstname || /^\d+$/.test(user.firstname) || /^[^a-zA-Z0-9]+$/.test(user.firstname)) {
            errors.firstname = "Name can't be empty or contain only numbers or special characters";
            isValid = false;
        }

        if (!user.email) {
            errors.email = "Email cannot be empty";
            isValid = false;
        } else {
            const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
            if (!emailPattern.test(user.email)) {
                errors.email = "Please enter a valid Email";
                isValid = false;
            }
        }

        setError(errors);
        return isValid;
    };


    const subscriptionDetails = (q, cid, subscribeId) => {
        console.log(subscribeId, "-------");
        let body = {
            planId: selectedPlan.stripePlanId,
            userId: q,
            customerId: cid,
            subscriptionId: subscribeId

        };
        console.log(body, "susctijd");
        axios.post(process.env.REACT_APP_BASE_URL + `subscription/create-session`, body, headerlocal)
            .then((res) => {
                console.log(res.data, "SESSION");
            })
            .catch((err) => {

                console.log(err, "session error");
            });
    };


    // console.log(user.username, "1");
    // console.log(user.firstname, "2");

    const addUserDetails = () => {
        const validator = validation();
        if (validator === true) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append("usertype", 'Member')
            formData.append("image", user.Imageraw ? user.Imageraw : "");
            formData.append("name", user.firstname);
            formData.append("username", user.username);
            formData.append("email", user.email);
            // formData.append("specialization", user.specialization);
            // const transformedSpecializations = user.specialization.flatMap(spec => spec.split(',').map(s => s.trim())).filter(Boolean);
            // transformedSpecializations.forEach((spec, index) => {
            //     formData.append(`specialization[${index}]`, spec);
            // });
            // formData.append("userrole", userId);
            formData.append("address", cityName);
            // formData.append("isBlocked", false);
            formData.append("description", user.description);
            formData.append("contact_number", user.phone);
            formData.append("verified",isToggled);
            formData.append("active",isActive);
            formData.append("gender", user.gender ? user.gender : '');
            formData.append("role_id", 3);
            console.log(...formData, "---------");
            axios.post(process.env.REACT_APP_BASE_URL + `Admin/adduserprivate`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false)
                    console.log(res, "ressss---");
                    setUser({ firstname: '', email: '', SetVerifyTeacher: "", gender: 'Male', phone: '', Imageprew: "", Imageraw: "", specialization: "", description: "" });
                    setSelectedPlan('');
                    navigate(`/users`);
                    enqueueSnackbar("User added successfully", { variant: 'success' });
                })
                .catch((err) => {
                    setTruebutton(false)
                    console.log(err.response, "------");
                    enqueueSnackbar(err.response.data.error ? err.response.data.error : err.response.data ? err.response.data : "Error occurred", { variant: 'error' });
                });
        }
    };


    React.useEffect(() => {
        getPlans()
    }, [])

    const getPlans = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `Subscription/getSubscriptionPlansYogaTeacher`, { headers: headerlocal }).then((res) => {
            console.log(res.data, "getplanssssssssssssssssssss");
            setPlans(res.data.getSubscriptiondata)
        }).catch((error) => {
            console.log(error, "Error ");
        })
    }

    return (
        <Box className='Addcompany'>
            <MiniDrawer menuOrder={18} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add User</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item sm={12} md={12} lg={12} className='mb-20'>
                                        <Box className='personal-info-tab'>
                                            <Typography component='div' className='label' >
                                                <center>Profile Photo<Typography component='span' className='star'></Typography></center>
                                            </Typography>
                                            <Box className='profile-photo'>
                                                <label htmlFor="icon-button-file">
                                                    <Input accept="image/*" id="icon-button-file" className='profUploadbtn' type="file" onChange={handleaddphoto} />
                                                    <IconButton color="#f3714d" aria-label="upload picture" component="span">
                                                        {user.Imageprew ? <Avatar src={user.Imageprew}>
                                                        </Avatar> : <Avatar src={Avatarimage} />
                                                        }
                                                        <AddAPhoto />
                                                    </IconButton>
                                                </label>
                                            </Box>
                                            <Typography className='allow-filetypes'>
                                                Allowed *.jpeg, *.jpg, *.png<br />max size of 1 MB
                                            </Typography>
                                            {/* <span style={{ color: "red", fontSize: 12 }} className='text-center'>{error.image}</span> */}

                                        </Box>

                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={8} lg={12}>
                                        <FormControl fullWidth >
                                            <Typography component='div' className='label'>Select Subscription <Typography component='span' className='star'></Typography></Typography>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedPlan ? selectedPlan : 'sel'}
                                                placeholder={selectedPlan && selectedPlan}
                                                className="select"
                                                onChange={(e) => { setSelectedPlan(e.target.value); setError('') }}
                                            >
                                                <MenuItem disabled value={'sel'}>Select Subscription Plan</MenuItem>
                                                {plans && plans?.length > 0 ? plans.map((data) => (

                                                    <MenuItem value={data}>{data.name}</MenuItem>
                                                )) : <MenuItem disabled>{"No Plan's found"}</MenuItem>}
                                            </Select>
                                        </FormControl>
                                    </Grid> */}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                className='textfield'
                                                placeholder='Name'
                                                value={user.firstname}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.firstname}</span>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Username: <Typography component='span' className='star'>*</Typography>
                                                <Tooltip title="This name will be used to create a unique URL for teacher">
                                                    <IconButton aria-label="information">
                                                        <AiOutlineInfoCircle size={20} />
                                                    </IconButton>
                                                </Tooltip></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='username'
                                                className='textfield'
                                                placeholder='Username'
                                                value={user.username}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        </FormControl>
                                        {error.username && <div className="error-message" style={{ color: "red", fontSize: 12 }}>{error.username}</div>}
                                    </Grid>

                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography component='div' className='label'>Select Role<Typography component='span' className='star'>*</Typography></Typography>
                                        <FormControl fullWidth >
                                            <Select
                                                className="select"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={userId ? userId : 'sel'}
                                                onChange={(e) => { setUserId(e.target.value); }}>
                                                <MenuItem disabled value={'sel'} >Select Role</MenuItem>
                                                {users && users?.length > 0 ? users.map((data) => (
                                                    <MenuItem value={data._id}>{data.UserRole}</MenuItem>)) : <MenuItem disabled>{"No Record's found"}</MenuItem>} 
                                            </Select>
                                            {/* // </Grid>{catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>} */}
                                        {/* </FormControl>
                                    </Grid> */} 

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className='mb-10 flex-center' >
                                            <Typography component='div' variant='body1' className='fz-14 label mb-0 '>User Verified?   </Typography>
                                            <FormControlLabel className='m-0' control={<Switch  checked={isToggled} size='small' onClick={handleToggle} sx={{ m: 1 }} />} />
                                        </Box>
                                    </Grid>

                
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className='mb-10 flex-center' >
                                            <Typography component='div' variant='body1' className='fz-14 label mb-0 '>User Active?   </Typography>
                                            <FormControlLabel className='m-0' control={<Switch  checked={isActive} size='small' onClick={handleToggleActive} sx={{ m: 1 }} />} />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Email: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='email'
                                                className='textfield'
                                                value={user.email}
                                                placeholder='Email'
                                                onChange={(e) => { handleChange(e); setError('') }}

                                            />
                                        </FormControl>
                                        <span style={{ color: "red", fontSize: 12 }}>{error.email}</span>
                                    </Grid>

                                    <Grid item lg={12} className='pt-0'>
                                        <Typography component='div' className='label '>Gender:</Typography>
                                        <Box className=" ">
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-customized-radios"
                                                name="gender"
                                                onChange={handleChange}
                                                value={user.gender}
                                            >
                                                <FormControlLabel value="Male" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Male</Typography>} />
                                                <FormControlLabel value="Female" className='radio-btns' control={<BpRadio />} label={<Typography className='fz-14'>Female</Typography>} />
                                            </RadioGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Phone:</Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='phone'
                                                className='textfield'
                                                value={user.phone}
                                                placeholder='Phone'
                                                onChange={handleChange}
                                                onInput={(e) => onlyNumbers(e)}
                                                inputProps={{ maxLength: 14 }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Address:</Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Address"
                                                className='textfield textarea'
                                                name='address'
                                                value={user.address}
                                                onChange={handleChange}
                                                minRows={2}
                                            />
                                        </FormControl>
                                    </Grid> */}

                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Address</Typography>
                                            <AutoComplete cityName={cityName} setCityName={setCityName} lat={lat} setLat={setLat} lng={lng} setLng={setLng} className="textfield" />
                                            {error.location && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.location}</p>}
                                        </FormControl>
                                    </Grid> */}

                                    {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Description:</Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Details"
                                                className='textfield textarea'
                                                name='description'
                                                value={user.description}
                                                onChange={handleChange}
                                                minRows={3}
                                                style={{ resize: 'none' }} />

                                        </FormControl>
                                    </Grid> */}
                                </Grid>

                                {truebutton === false ?
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10' onClick={() => addUserDetails()}>Save</Button>
                                    </Box>
                                    : truebutton === true &&
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                        <Button variant="contained" className='addbtn mr-10'>Please Wait</Button>
                                    </Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
}