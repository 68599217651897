import * as React from 'react';
import { Typography, TableBody, IconButton, TextField, InputAdornment, Menu, MenuItem, Box, Button, FormControl, FormGroup, Grid } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import axios from 'axios'
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'notistack'
import { Verified } from '@mui/icons-material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { ExpandMore, SearchOutlined, CloseOutlined } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate, useParams } from 'react-router-dom';
import playBtn from '../../../../../assets/images/play.png'
import Switch, { SwitchProps } from '@mui/material/Switch';


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >

                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function Podcast_cardview() {
    const params = useParams()
    const auth = JSON.parse(localStorage.getItem('userData'))
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuopen = Boolean(anchorEl);
    const tokendata = {
        'headers': {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem("token")
        }
    }
    var navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [deletearry, setDeleteArray] = React.useState([])
    const [count, setCount] = React.useState(0)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [podcastDetails, setPodcastDetails] = React.useState([])
    const [searchTerm, setSearchTerm] = React.useState('')
    const [deletemodel, setDeletemodel] = React.useState(false)
    const [deleteid, setDeleteId] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const [alldata, setAlldata] = React.useState('');
    const [openview, setOpenview] = React.useState(false);

    const handleClick = (event, data) => {
        setAlldata(data)
        setAnchorEl(event.currentTarget);
    };

    React.useEffect(() => {
        getPodcast()
    }, [page, rowsPerPage, searchTerm])

    const handleClose_menu = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        console.log('New page:', newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getPodcast = () => {
        axios.get(process.env.REACT_APP_BASE_URL + `podcast/GetPodcast?createdby=${params && params.id}&size=${rowsPerPage}&page=${page}&search=${searchTerm}`, tokendata).then((res) => {
            setPodcastDetails(res.data.podcast)
            setCount(res.data.totalcount || 0);
        }).catch((err) => {
            console.log(err);
        })
    }

    console.log(podcastDetails, "det");
    const handleDeleteData = () => {
        setDeletemodel(true)
    }
    const handleDelete = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `podcast/deletepodcast/${deleteid}`, {}, tokendata,).then((res) => {
            handleClose()
            getPodcast()
            enqueueSnackbar("Podcast deleted successfully", { variant: 'success' })
        }).catch((err) => {
            console.log(err);
        })
    }

    const EditData = (row) => {
        let obj = {
            data: alldata
        }
        // navigate(`/teachers/details/podcastedit/${alldata._id}`, { state: obj })
        navigate(`/teachers/details/podcastedit/${params.id}`, { state: obj })

    }

    const handleOpen = (id) => {
        setDeleteId(id)
        setOpen(true)
    }

    const handleClickOpen = () => {
        setAnchorEl(null);
        setDeleteId(alldata._id)
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('')
        setOpen(false)
    }

    const handleChangeStatus = async (e, item, type) => {
        console.log(type, 'typetypetypetype');

        if (type === 'Public') {
            const body = {
                public: e.target.checked,
            };
            console.log(body, "public")
            try {
                const response = await axios.put(process.env.REACT_APP_BASE_URL + `podcast/updatePodcast/${item._id}`, body, tokendata);
                getPodcast();
            } catch (error) {
                enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                console.error("Error while updating public", error);
            }
        }
        if (type === 'Primary') {
            const body = {
                primary: e.target.checked,
            };
            try {
                const response = await axios.put(process.env.REACT_APP_BASE_URL + `podcast/updatePodcast/${item._id}`, body, tokendata);
                getPodcast();
            } catch (error) {
                enqueueSnackbar(error.response.data && error.response.data.error, { variant: 'error' })
                console.error("Error while updating primary event", error);
            }
        }
    };

    const handleView = (e) => {
        let podcastDetails = {
            podcastData: alldata
        }
        navigate(`/podcast/Podcast-details/${alldata.name}`, { state: podcastDetails });
    }

    const handleRemove = () => {
        setSearchTerm('')
        setPage(0)
    }

    const handleNavigate = (e, data) => {
        let podcastDetails = {
            podcastData: data
        }
        navigate(`/podcast/Podcast-details/${data.name}`, { state: podcastDetails });
    }

    return (

        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', boxShadow: 'none' }} className='Datatable'>
                <Toolbar className='toolbar comptoolbar'>
                    {deletearry && deletearry.length > 0 && <Button className='del-btn' onClick={handleDeleteData}>Delete</Button>}
                    <Box component='div' className='DTsearch mr-10'>
                        <FormControl variant="standard">
                            <Typography component='div' className='label mr-10'>Search :</Typography>
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={searchTerm}
                                className='textfield search'
                                onChange={(e) => { setSearchTerm(e.target.value); setPage(0) }}
                                placeholder='Search Here...'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {searchTerm !== '' && (
                                                <IconButton onClick={handleRemove} size="small">
                                                    <CloseOutlined className='fz-18' />
                                                </IconButton>
                                            )}
                                            <SearchOutlined className='fz-18' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Box>
                </Toolbar>
                <Grid container columnSpacing={4} rowSpacing={4} className='p-20'>
                    {podcastDetails && podcastDetails.length > 0 ? podcastDetails.map((item, i) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} className='d-flex'>
                                <Box className='poditem'>
                                    <Box className='podImg_box'>
                                        <IconButton aria-label="more" id="long-button"
                                            aria-controls={menuopen ? 'long-menu' : undefined}
                                            aria-expanded={menuopen ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={(e) => handleClick(e, item)}
                                            className='vertical_menu'>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={menuopen}
                                            onClose={handleClose_menu}
                                            PaperProps={{
                                                style: {
                                                    width: '15ch',
                                                },
                                            }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                        >
                                            {/* <MenuItem className='menuitems' onClick={(e) => handleView(e, item)} ><VisibilityIcon className='fz-16 mr-5' />View</MenuItem> */}
                                            <MenuItem className='menuitems' onClick={() => EditData()}><EditIcon className='fz-16 mr-5' />Edit</MenuItem>
                                            <MenuItem className='menuitems' onClick={(e) => handleClickOpen(e, item._id)}><DeleteIcon className='fz-14 mr-5' />Delete</MenuItem>
                                        </Menu>
                                        <img src={process.env.REACT_APP_BASE_URL + item.thumbnail} />
                                        <Button className='playbtn' onClick={(e) => handleNavigate(e, item)} ><img src={playBtn} /></Button>
                                    </Box>

                                    <Box className='d-flex flex-wrap flex-between mb-10'>
                                        <Box className='' >
                                            <Typography component='div' variant='body1' className='fz-14 semibold mr-10'>Public:   </Typography>
                                            <FormControlLabel className='m-0' control={<Switch size='small' onChange={(e) => handleChangeStatus(e, item, 'Public')} checked={item.public} />} />
                                        </Box>
                                        <Box className=' ' >
                                            <Typography component='div' variant='body1' className='fz-14 semibold mr-10'>Primary:   </Typography>
                                            <FormControlLabel className='m-0' control={<Switch size='small' onChange={(e) => handleChangeStatus(e, item, 'Primary')} checked={item.primary} />} />
                                        </Box>
                                    </Box>

                                    <Box className='d-flex mb-5'>
                                        <Box>
                                            <Typography component='div' variant='body1' className='text_black fz-14 semibold letter_sp'>{item.name}</Typography>
                                            <Typography component='div' variant='body1' className='text_black fz-14 semibold letter_sp'>
                                                {item.audio_size && `${item.audio_size}mb`}
                                            </Typography>
                                        </Box>
                                        {
                                            item.primary && <Typography component='div' variant='body1' className='fz-14 ml-auto' ><Verified className='fz-18' style={{ color: '#1492fd' }} /></Typography>
                                        }
                                    </Box>
                                    <Typography component='div' variant='body1' className='text_black fz-14 ellipse2 fw-500 letter_sp mb-5'>{item.description}</Typography>
                                </Box>
                            </Grid>
                        );
                    }) : <Grid item xs={12} sm={12} md={12} lg={12}>
                        <p className='text-center semibold'>No data found</p></Grid>}

                </Grid></Paper>
            <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description" className='custom_modal'>
                <DialogTitle>{"Would you like to delete Podcast?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} className='cancel_btn'>No</Button>
                    <Button onClick={handleDelete} className='theme-btn'>Yes</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

