import React, { useState, useEffect } from 'react';
import MiniDrawer from '../../../components/drawer';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate, useLocation } from 'react-router-dom';

export default function EditSub() {
    const headerLocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    };

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.state.data, 'location.state.data.');
    const [category, setCategory] = useState({ categoryname: location.state.data.subcategory_title, description: location.state.data.description });
    const [error, setError] = useState({ categoryname: "" });
    const [recordings, setRecordings] = useState([]);
    const [categoryId, setCategoryId] = useState(location.state.data.category_id._id);
    const [catError, setCatError] = useState('');
    const [truebutton, setTruebutton] = React.useState(false)

    useEffect(() => {
        getCategory();
    }, []);

    const getCategory = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}Category/GetCategory`, { headers: headerLocal });
            setRecordings(res.data.category);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCategory = (e) => {
        const { name, value } = e.target;
        setError({ categoryname: "" });
        setCategory({ ...category, [name]: value });
      
    };

    const validateForm = () => {
        if (!categoryId) {
            setCatError("This field is required.");
            return false
        }
        else if (!category.categoryname) {
            setError({ categoryname: "This field is required." });
            return false
        }
        return true;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setTruebutton(true);
            const body = {
                category_id: categoryId,
                subcategory_title: category.categoryname,
                description: category.description,
                
            };
            console.log(body, 'body');

            try {
                await axios.put(`${process.env.REACT_APP_BASE_URL}SubCategory/EditSubCategory/${location.state.data._id}`, body, { headers: headerLocal });
                setTruebutton(false);
                navigate('/subcategory');
                enqueueSnackbar("Category updated successfully", { variant: 'success' });
                setCategory({ categoryname: "", description: "" });
                setCategoryId('');
            } catch (error) {
                setTruebutton(false);
                enqueueSnackbar("Something went wrong.", { variant: 'error' });
                console.error(error);
            }
        }
    };

    return (
        <Box>
            <MiniDrawer  menuOrder={8} submenuOrder={17} />
            <Box className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className='whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle mb-20'>Edit Sub Category</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <Typography component='div' className='label'>Select Category: <Typography component='span' className='star'>*</Typography></Typography>
                                            <Select
                                                value={categoryId ? categoryId : ''}
                                                onChange={(e) => { setCategoryId(e.target.value); setCatError(''); }}
                                                className="select"

                                            >
                                                <MenuItem value='' disabled>Select category</MenuItem>
                                                {recordings.length > 0 ? recordings.map((data) => (
                                                    <MenuItem key={data._id} value={data._id}>{data.category_title}</MenuItem>
                                                )) :
                                                    <MenuItem disabled>No Record's found</MenuItem>}
                                            </Select>
                                            {catError && <p style={{ color: 'red' }} className="fz-13 m-0">{catError}</p>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <Typography component='div' className='label'>Sub Category Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                variant="outlined"
                                                placeholder='Sub Category Name'
                                                name="categoryname"
                                                value={category.categoryname}
                                                onChange={handleCategory}
                                                error={!!error.categoryname}
                                                helperText={error.categoryname}
                                                className='textfield'
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <Typography component='div' className='label'>Description</Typography>
                                            <textarea
                                                className='textarea'
                                                placeholder='Description'
                                                name="description"
                                                value={category.description}
                                                onChange={(e) => handleCategory(e)}
                                                rows={4}
                                                cols={40}
                                                style={{ resize: 'none' }} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {truebutton === false ?
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button variant="contained" className='addbtn ' onClick={handleSubmit} >Update</Button>
                                </Box>
                                 : truebutton === true &&
                                 <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                     <Button variant="contained" className='addbtn mr-10'>Please Wait...</Button>
                                 </Box>
                             }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
