import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import MiniDrawer from '../../../components/drawer'
import DataTable from './Datatable_Studios'

export default function YogaStudios() {
    let navigate = useNavigate()
    const role = JSON.parse(localStorage.getItem("roledata"))

    const addYogaStudios = () => {
        navigate('/addyogastudios');
    }
    return (
        <Box className='companies'>
            <MiniDrawer menuOrder={2} submenuOrder={9} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading formTitle'>Yoga Studio</Typography>
                    {role.UserRoles == null ? <Button variant="contained" className='addbtn' onClick={() => addYogaStudios()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Yoga Studio</Button>
                        : role.UserRoles.studio.Addstudio && <Button variant="contained" className='addbtn' onClick={() => addYogaStudios()}> <PlaylistAddRounded className='fz-18 mr-5' /> Add Yoga Studio</Button>
                    }</Box>
                <Box className='whiteContainer'>
                    <DataTable />
                </Box>
            </Box>
        </Box>
    )
}