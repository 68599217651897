

import { PlaylistAddRounded } from '@mui/icons-material'
import { Box, Button, Typography, TextField } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePlacesWidget } from "react-google-autocomplete";

export default function AutoComplete_Edit({cityName,setError, setCityName, lat, setLat,lng, setLng, formattedadrress, setFormattedAddress}) {
    const { ref, autocompleteRef } = usePlacesWidget({
        // need to change this API key 
        apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
        onPlaceSelected: (place) => {
            setCityName(place.location_coord)
            setFormattedAddress(place.formatted_address)
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
            setError('')
        },
        options: {
            types: "(regions)" | 'establishment'
        },
    });

    const handleChange = (event) => {
        const value = event.target.value;
        setCityName(value);
        setError("")
        };

    return (
        <TextField
            fullWidth
            label="Address"
            variant="outlined"
            inputRef={ref}
            defaultValue = {formattedadrress}
            onChange={handleChange}
        />
    );
}