import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../../components/drawer';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, FormControlLabel, InputAdornment, TextField, Typography, Card, Input, CardContent, Dialog, Slide,TextareaAutosize } from '@mui/material'
import { Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { styled } from '@mui/material/styles';

const NoUnderlineTextField = styled(TextField)({
    '& .MuiInputBase-root a': {
        textDecoration: 'none',
        color: 'inherit',
    },
});

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 0,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 18.5,
        height: 18.5,
    },
    '& .MuiSwitch-track': {
        borderRadius: 18.5 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#f3714d',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#f3714d',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <ListAlt />,
        2: <CompareIcon />,
        3: <Dvr />,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

export default function AddCourseSlider() {
//useState's:
    const navigate = useNavigate()

    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const auth = JSON.parse(localStorage.getItem('userData'))
    const { enqueueSnackbar } = useSnackbar()
    const [truebutton, setTruebutton] = React.useState(false)
    const [error, setError] = React.useState({titlemsg:'', link: "", video: "", image: "" })
    const [imageError, setImageError] = React.useState('')
    const [imageAddError, setImageAddError] = React.useState('')
    const [state1, setState1] = React.useState([])
    const [state2, setState2] = React.useState([])
    const [statesingle, setStatesingle] = React.useState([])
    const [statesingle2, setStatesingle2] = React.useState([])
    const [richeditor, setRichtor] = React.useState("")
    const [selectedVideo, setSelectedVideo] = useState([]);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState([]);
    const [link, setLink] = useState("")
    const [title,setTitle] = React.useState('')
    const [description,setDescription] = React.useState('')
    const [isToggled, setToggled] = React.useState(true)

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }


//To Upload Single/double Image:
    const SingleImage = (e, index) => {
        setImageError('');
        setError('');

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);

            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);

                    var data = statesingle.filter(x => x != null);
                    var data1 = statesingle2.filter(x => x != null);
                    setStatesingle(data);
                    setStatesingle2(data1);
                    setStatesingle(prevValue => prevValue.concat(fileArray));
                    setStatesingle2(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setLink(value);
        setError(prevError => ({ ...prevError, link: '' })); // Clear error
    };

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    const handleToggle = () => {
        setToggled(!isToggled)
    }



//Validation:
    const validate = () => {
        const urlRegex = /^(ftp|http[s]?):\/\/(?:www\.)?([^\s.]+\.[^\s]{2,}|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(:\d+)?(\/\S*)?$/;
        if(statesingle.length == ''){
            setError({...error,image:'This field is required'});
            return false
        }
        if (!title) {
            setError({ ...error, titlemsg: "This field is required" });
            return false;
        }
        if (!link) {
            setError({ ...error, link: "This field is required" });
            return false;
        }

        if (!urlRegex.test(link)) {
            setError({ ...error, link: "Please enter a valid URL, example:https://www.abc.com/" });
            return false;
        }

        return true;
    };

//Add Slider:
    const handleAdd = () => {
        var valid = validate();
        if (valid == true) {
            setTruebutton(true)
            const formData = new FormData();
            formData.append("createdby", auth && auth._id)
            { statesingle2 ? statesingle2.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') }
            formData.append('lable',title)
            formData.append('short_description',description)
            formData.append("link", link)
            formData.append('public', JSON.stringify(isToggled));
            axios.post(process.env.REACT_APP_BASE_URL + `CourseSldier/AddCourseSlider`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false);
                    navigate('/coursesslider');
                    setLink('');
                    setState1([]);
                    setState2([]);
                    enqueueSnackbar("Course slider setting added successfully", { variant: 'success' });
                }).catch((err) => {
                    setTruebutton(false);
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' });
                    console.log(err, "error");
                });

        }
    };

    return (
        <Box className=''>
            <MiniDrawer menuOrder={4} submenuOrder={12} />
            <Box component="section" className="contentWraper">
                <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={5} className='mb-10'>
                        <Box className='whiteContainer p-20'>
                            <Typography component='h6' className='formTitle'>Add Event Slider</Typography>
                            <Box className="steppy_content mb-10">
                                <Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
                                    <Grid item xs={12} sm={12} md={12}  >
                                        <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                            <Typography component="div" className="label mr-10 mb-0">Image <Typography component='span' className='star'>*</Typography></Typography>
                                            <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                {statesingle.length < 1 &&
                                                    <Button className="upload_btn" variant="contained" component="label">
                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                        <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                                    </Button>
                                                }
                                            </Box>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}  >
                                        {statesingle.length > 0 &&
                                            <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                {statesingle.length > 0 ? statesingle.map((x, i) => (
                                                    <Grid item xs={6} sm={4} md={4} lg={4} >
                                                        <Box className='uploaded_img' >
                                                            <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                            <img src={x} alt='' height={155} width={195} />
                                                        </Box>
                                                    </Grid>
                                                )) : ""}
                                            </Grid>
                                        }
                                    </Grid>
                                    {error.image && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.image}</p>}


                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 2000px * 500px for better quality picture.</Typography>
                                    </Grid>

                                    <Box className="mb-20">
                                        <Typography component='div' className='label'>Title <Typography component='span' className='star'> * </Typography></Typography>
                                        <FormControl variant="standard" fullWidth>
                                            <NoUnderlineTextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                placeholder='Title'
                                                size="small"
                                                name='title'
                                                fullWidth
                                                value={title}
                                                onChange={(e)=>{setTitle(e.target.value);setError({titlemsg:''})}}
                                            />
                                        </FormControl>
                                        {error.titlemsg && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.titlemsg}</p>}
                                    </Box>

                                    <Box className="mb-20">
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Short Description:</Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Short Description"
                                                className='textfield textarea'
                                                name='description'
                                                value={description}
                                                onChange={(e)=>setDescription(e.target.value)}
                                                minRows={3}
                                                style={{ resize: 'none' }} />

                                        </FormControl>
                                    </Box>


                                    <Box className="mb-20">
                                        <Typography component='div' className='label'>Link <Typography component='span' className='star'> * </Typography></Typography>
                                        <FormControl variant="standard" fullWidth>
                                            <NoUnderlineTextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                placeholder='Link'
                                                size="small"
                                                name='link'
                                                fullWidth
                                                value={link}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                        {error.link && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.link}</p>}
                                    </Box>

                                    <Box className="mb-20">
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Short Description:</Typography>
                                            <TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Short Description"
                                                className='textfield textarea'
                                                name='description'
                                                value={description}
                                                onChange={(e)=>setDescription(e.target.value)}
                                                minRows={3}
                                                style={{ resize: 'none' }} />

                                        </FormControl>
                                    </Box>

                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Box className='flex-center'>
                                            <Typography component='div' className='label mr-10 mb-0'>Public</Typography>
                                            <Box className='heading-btns' >
                                                <FormControlLabel
                                                    className='m-0'
                                                    style={{ display: 'flex', alignItems: 'center' }}
                                                    control={<Switch size="small" checked={isToggled} onChange={handleToggle} />}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <>
                                {truebutton === false ?
                                    <>
                                        <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                            <Button onClick={handleAdd} variant="contained" className='theme-btn'>Submit</Button>
                                        </Box>
                                    </>
                                    :
                                    truebutton === true &&
                                    <>
                                        <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>

                                            <Button variant="contained" className='theme-btn  mr-10'>Please wait</Button>
                                        </Box>
                                    </>
                                }
                            </>
                        </Box >
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}