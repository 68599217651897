
import axios from 'axios';
import * as React from 'react';
import { useCommunity } from "../Context/CommunityContext";
import { useSnackbar } from 'notistack'
import { SearchOutlined, Delete as DeleteIcon } from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, IconButton, FormControlLabel, Tooltip, Card, Checkbox, Link, ListItemText, OutlinedInput, Input, Breadcrumbs, TableContainer, Select, TablePagination, MenuItem, Dialog, Typography, Grid, FormControl, TextField, DialogContent, DialogActions, DialogTitle, styled, CardContent, AccordionDetails, AccordionSummary, Accordion, Divider, RadioGroup, Radio, InputAdornment, Tabs, Tab } from '@mui/material';


export default function DataTable({ params }) {
    const { communityAttachments, fetchCommunity } = useCommunity();
    const [previousFiles, setPreviousFiles] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    };

    const supportedFileTypes = ['pdf', 'doc', 'docx', 'txt'];

    const deleteFile = (filepath) => {
        if (!filepath) {
            console.error("Filepath is undefined or empty.");
            return;
        }

        const deleteData = {
            filepath: filepath
        };

        axios.put(
            `${process.env.REACT_APP_BASE_URL}community/deleteattachments/${params.id}`,
            deleteData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('token')
                }
            }
        )
            .then((res) => {
                enqueueSnackbar("File deleted successfully", { variant: 'success' });
                fetchCommunity(params.id); // Refresh community data after deletion
            })
            .catch((err) => {
                enqueueSnackbar(
                    err.response && err.response.data.error ? err.response.data.error : "Failed to delete file.",
                    { variant: 'error' }
                );
                console.error(err);
            });
    };


    React.useEffect(() => {
        setPreviousFiles(communityAttachments);
    }, [communityAttachments]);

    // const handleFileChange = (e) => {
    //     const selectedFiles = Array.from(e.target.files);
    //     const filesWithNames = selectedFiles.map((file) => ({
    //         name: file.name,
    //         size: file.size,
    //         file: file,
    //     }));
    //     setFiles((prevFiles) => [...prevFiles, ...filesWithNames]);
    // };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const filesWithNames = selectedFiles.map((file) => ({
            name: file.name,
            size: file.size,
            file: file,
        }));

        const validFiles = filesWithNames.filter(file => {
            const extension = file.name.split('.').pop().toLowerCase();
            return supportedFileTypes.includes(extension);
        });

        const unsupportedFiles = filesWithNames.filter(file => !validFiles.includes(file));
        if (unsupportedFiles.length > 0) {
            enqueueSnackbar(`Invalid file ${unsupportedFiles.map(file => file.name).join(', ')}`, { variant: 'error' });
        }

        setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    };

    const removeFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    React.useEffect(() => {
        fetchCommunity(params.id);
    }, [params.id]);

    
    const saveFilesToServer = () => {
        // const filesArray = Array.isArray(files) ? files : [files];
        // const formData = new FormData();
        // filesArray.forEach((file, index) => {
        //     formData.append(`files`, file.file);
        //     formData.append(`name_${index}`, file.name);
        //     formData.append(`size_${index}`, (file.size) / 1024);
        // });

        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append(`files`, file.file);
            formData.append(`name_${index}`, file.name);
            formData.append(`size_${index}`, (file.size) / 1024);
        });
        { previousFiles ? previousFiles.forEach((item) => { formData.append("ExistingFiles", JSON.stringify(item)) }) : formData.append("ExistingFiles", '') }

        axios.post(process.env.REACT_APP_BASE_URL + `community/updatecommunityFile/${params.id}`, formData, { headers: headerformdata })
            .then((res) => {
                enqueueSnackbar("Community updated successfully", { variant: 'success' });
                setFiles([]);
                fetchCommunity(params.id);
            })
            .catch((err) => {
                enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' });
                console.log(err, "error");
            });
    };

    return (
        <div className="contentWraper">
            {/* <FileUploadOutlinedIcon className="fz-18 mr-5" />
            <input type="file" multiple onChange={handleFileChange} /> */}
            <Box className="mb-20 whiteContainer p-20">
                <Box>
                    <Typography component='h4' variant='h4' className='Submenu-page-heading mb-20'>Add Files</Typography>
                </Box>
          
                <FormControl variant="standard" fullWidth>
                    <Box className='flex-center flex-between flex-wrap w100'>
                        <Button className="upload_btn" variant="contained" component="label">
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <FileUploadOutlinedIcon className="fz-18 mr-5" />
                                Add Files
                            </Box>
                            <input onChange={handleFileChange} multiple hidden type="file" />
                        </Button>
                    </Box>
                </FormControl>
          
            <ul className='p-0'>
                {[...previousFiles, ...files].map((file, index) => (
                    <li key={index} className='flex-center fz-16 text_black'>
                        {file.fileName ? file.fileName : file.name}
                        {/* <button onClick={() => removeFile(index)}>Remove</button> */}
                        {/* <button onClick={() => deleteFile(file.file)}>Delete</button> */}
                        <DeleteIcon className='ml-10 fz-20 text-grey' onClick={() => deleteFile(file.file)} />
                    </li>
                ))}
            </ul>
            {/* <button onClick={() => saveFilesToServer()}>Submit</button> */}
            <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                <Button variant="contained" className='addbtn mr-10' onClick={() => saveFilesToServer()}>Submit</Button>
            </Box>
            </Box>
        </div>
    );
}

