import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../components/drawer';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, TextField, Typography, FormControlLabel,RadioGroup,Radio, Checkbox, ListItemText, MenuItem, Select, OutlinedInput } from '@mui/material'
import { Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-datepicker/dist/react-datepicker.css";
import { SketchPicker } from 'react-color';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 40,
	height: 20,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 0,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(20px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 18.5,
		height: 18.5,
	},
	'& .MuiSwitch-track': {
		borderRadius: 18.5 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
	display: 'flex',
	height: 22,
	alignItems: 'center',
	...(ownerState.active && {
		color: '#784af4',
	}),
	'& .QontoStepIcon-completedIcon': {
		color: '#784af4',
		zIndex: 1,
		fontSize: 18,
	},
	'& .QontoStepIcon-circle': {
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
}));

function QontoStepIcon(props) {
	const { active, completed, className } = props;
	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<Check className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'#f3714d',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundColor:
				'#f3714d',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundColor:
			'#f3714d',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	}),
	...(ownerState.completed && {
		backgroundColor:
			'#f3714d',
	}),
}));

function ColorlibStepIcon(props) {
	const { active, completed, className } = props;

	const icons = {
		1: <ListAlt />,
		2: <CompareIcon />,
		3: <Dvr />,
	};
	return (
		<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
}

ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};

const steps = ['Basic Information', 'Blog Information'];
export default function AddManagement() {
	const headerlocal = {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token")
	}
	const headerformdata = {
		'Content-Type': 'multipart/form-data',
		Authorization: localStorage.getItem("token")
	}
	const [activeStep, setActiveStep] = React.useState(0);
	const navigate = useNavigate()
	const { enqueueSnackbar } = useSnackbar()
	const [management, setManagement] = React.useState({ title: "", link: "", name: "" })
	const [truebutton, setTruebutton] = React.useState(false)
	const [error, setError] = React.useState({ title: "", link: "", name: "" ,description:""})
	const [data, setData] = React.useState([])
	const [imageError, setImageError] = React.useState('')
	const [state1, setState1] = React.useState([])
	const [state2, setState2] = React.useState([])
	const [statesingle, setStatesingle] = React.useState([])
	const [statesingle2, setStatesingle2] = React.useState([])
	const [description, setDescription] = React.useState('')
	const [isToggled, setToggled] = React.useState(false)
	const [isPrimary, setPrimary] = React.useState(false)
	const [isPublic, setPublic] = React.useState(false)
	const [page, setPage] = React.useState([])
	const [selectedPages, setSelectedPages] = useState([]);
	const [selectOwner, setSelectOwner] = useState('Event')
	const [event, setEvent] = useState([]);
	const [community, setCommunity] = useState([]);



	const handleEditOwner = (e) => {
		setSelectOwner(e.target.value);
		setEventName([]);
		setCommunityName([]);
	}

	let name, value;
	const handlechange = (e) => {
		name = e.target.name;
		value = e.target.value;
		if (name === 'name' && value.length > 10) {
			return;
		}
		setManagement({ ...management, [name]: value })

	}

	React.useEffect(() => {
		getEvent()
	}, [])

	const getEvent = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Event/getEventNames`, { headers: headerlocal });
			setEvent(response.data.Events);
			console.log(response, "events45");
		} catch (error) {
			console.log(error.response, "error");
		}
	}

	const [selecttedEvent, setSelecttedEvent] = React.useState([])
	const [eventName, setEventName] = React.useState([])
	const handlechangeEvent = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setEventName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getEventval()
	}
	React.useEffect(() => {
		getEventval()
	}, [eventName])

	function getEventval() {
		var array = []
		const selecttedEvent = event.filter(content => eventName.includes(content.event_name));
		// console.log(selecttedTag,'selecttedTagselecttedTagselecttedTag');
		selecttedEvent.map((item, i) => {
			var val = {  event_name: item.event_name}
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedEvent(array)
	}


	React.useEffect(() => {
		getCommunity()
	}, [])

	const getCommunity = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Community/getCommunityNames`, { headers: headerlocal });
			setCommunity(response.data.communities);
			console.log(response, "community===");
		} catch (error) {
			console.log(error.response, "error");
		}
	}

	const [selecttedCommunity, setSelecttedCommunity] = React.useState([])
	const [communityName, setCommunityName] = React.useState([])
	const handlechangeCommunity = (event, item) => {
		// setError({ ...error, ["userselect"]: '' })
		const {
			target: { value },
		} = event;
		setCommunityName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getCommunityval()
	}
	React.useEffect(() => {
		getCommunityval()
	}, [communityName])

	function getCommunityval() {
		var array = []
		const selecttedCommunity = community.filter(content => communityName.includes(content.coummunity_name));
		// console.log(selecttedTag,'selecttedTagselecttedTagselecttedTag');
		selecttedCommunity.map((item, i) => {
			var val = {
				coummunity_name
					: item.coummunity_name,
					_id :item._id
			}
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedCommunity(array)
	}
	React.useEffect(() => {
		getPage()
	}, [])

	const getPage = async () => {
		try {
			const response = await axios.get(process.env.REACT_APP_BASE_URL + `Advertisement/getPages`, { headers: headerlocal });
			setPage(response.data.getadvertisement);
		} catch (error) {
			console.log(error.response, "error");
		}
	}

	const [selecttedPage, setSelecttedPage] = React.useState([])

	const [pageName, setPageName] = React.useState([])
	const handlechangePage = (event, item) => {

		const {
			target: { value },
		} = event;
		setPageName(
			typeof value === 'string' ? value.split(',') : value,
		);
		getPageval()
		const { value: selectedPageNames } = event.target;
		const selectedPages = page.filter((page) => selectedPageNames.includes(page.pageName));
		const updatedPages = page.map((item) => {
			if (selectedPages.some((selectedPage) => selectedPage._id === item._id)) {
				return { ...item, isAddEnabled: true };
			} else {
				return { ...item, isAddEnabled: false };
			}
		});
		setPage(updatedPages);
		setSelectedPages(selectedPageNames);
	}

	React.useEffect(() => {
		getPageval()
	}, [pageName])

	function getPageval() {
		var array = []
		const selecttedPage = page.filter(content => pageName.includes(content.pageName));
		selecttedPage.map((item, i) => {
			var val = { pageName: item.pageName, isAddEnabled: true, page_id: item._id }
			Object.assign(item, val)
			array.push(val)
		})
		setSelecttedPage(array)
	}


	const [color, setColor] = useState('#fff');
	const handleChangeComplete = (color) => {
		setColor(color.hex);
	};
	const handleDescriptionChange = (e) => {
		const inputValue = e.target.value;

		
		if (inputValue.length >= 50) {
			setError({description:"Short Description can only be less than 50 characters"})
			return;
		}
		else{
			setDescription(inputValue);			
		}
		setError(prevError => ({ ...prevError, description: '' }));

	};

	const SingleImage = (e, index) => {
		setImageError('');
		const fileObj = e.target.files;
	
		const fileArray = [];
		const filepath = [];
	
		for (let i = 0; i < fileObj.length; i++) {
			let file = fileObj[i];
			if (file.size > 1000000) {
				setImageError("Image should be less than 1 MB");
				continue; 
			}
	
			fileArray.push(URL.createObjectURL(file));
			filepath.push(file);
		}
	
	
		const data = statesingle.filter(x => x != null);
		const data1 = statesingle2.filter(x => x != null);
	
		setStatesingle(prevValue => [...prevValue, ...fileArray]);
		setStatesingle2(prevValue => [...prevValue, ...filepath]);
	};

	const indexDelete1 = (e, index) => {
		var filteredpreview = statesingle.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = statesingle2.filter((val, i) => {
			return i !== index;
		});
		setStatesingle(filteredpreview);
		setStatesingle2(filteredraw);
	}

	const handleToggle = () => {
		setToggled(!isToggled)
	}
	const handleTogglePrimary = () => {
		setPrimary(!isPrimary)
	}
	const handleTogglePublic = () => {
		setPublic(!isPublic)
	}

	const validate = () => {
		if (!management.title && !management.subtitle && statesingle?.length == 0 && !management.link && !management.name ) {
			setError({ title: "This field is required.", link: "This field is required.", name: "This field is required." })
			setImageError("This field is required.")
			return false
		}
		if (!management.title) {
			setError({ title: "This field is required." })
			return false
		}
		if (!management.name) {
			setError({ name: "This field is required." })
			return false
		}
		// if (!description) {
		// 	setError({ description: "This field is required." })
		// 	return false
		// }
		if (statesingle?.length == 0) {
			setImageError("This field is required.")
			return false
		}
		if (!management.link) {
			setError({ link: "This field is required." })
			return false
		}
		return true
	}

	const handleSubmit = () => {
		var valid = validate()
		if (valid == true) {
			setTruebutton(true)
			const formData = new FormData();
			formData.append("title", management.title)
			formData.append("sub_title", description)
			formData.append("link", management.link)
			formData.append("shop_now", management.name)
			formData.append("mega", isToggled)
			formData.append("public", isPublic)
			formData.append("primary", isPrimary)
			formData.append("addColor", color)
			formData.append('page_permissions', JSON.stringify(page.length > 0 ? page : []))
			// formData.append('events', JSON.stringify(selecttedEvent.length > 0 ? selecttedEvent : []))
			formData.append('community', JSON.stringify(selecttedCommunity.length > 0 ? selecttedCommunity : []))
			// formData.append("adsby", selectOwner === 'Event' ? 'Event' : 'Community');
			{ statesingle2 ? statesingle2.forEach((item) => { formData.append("image", item) }) : formData.append("newimage", '') }
            console.log(...formData,"adssssss");
			axios.post(process.env.REACT_APP_BASE_URL + `AddManagement/addManagement`, formData, { headers: headerformdata }).then((res) => {
				setTruebutton(false)
				navigate('/offers');
				setManagement({ title: "", description: "", link: "", name: "" });
				setState1([])
				setState2([])
				enqueueSnackbar("Ad added successfully", { variant: 'success' })
				console.log(res, "management addedd");
			}).catch((err) => {
				setTruebutton(false)
				enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
				console.log(err, "error");
			})
		}
	}

	return (
		<Box className=''>
			<MiniDrawer menuOrder={16} />
			<Box component="section" className="contentWraper">
				<Grid container>
					<Grid item xs={12} sm={12} md={8} lg={6}  >
						<Box className='whiteContainer p-20'>
							<Typography component='h6' className='formTitle mb-20'> New Ads </Typography>
							<Box className="">
								<Box className="mb-10">
									<Typography component='div' className='label'>Title<Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="title"
											name="title"
											placeholder="Title"
											size="small"
											className='textfield'
											style={{ width: '100%' }}
											value={management.title}
											onChange={(e) => {
												handlechange(e); setError(prevError => ({ ...prevError, title: '' }))
											}}

										/>
										{error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
									</FormControl>
								</Box>
								<Grid item xs={12} sm={12} md={12}>
									<Box className="mb-10">
										<Typography component='div' className='label'>Short Description<Typography component='span' ></Typography></Typography>
										<FormControl variant="standard" fullWidth>
											<textarea
												placeholder='Short Description'
												className='textarea'
												value={description}
												rows={4}
												cols={40}
                                                style={{resize:'none'}}
												onInput={(e) => {
													handleDescriptionChange(e);
												}}
												
												 />
											{error.description && <p style={{ color: 'red' }} className="fz-13 m-0">{error.description}</p>}
										</FormControl>
									</Box>
								</Grid>

								<Box className="mb-10">
									<Typography component='div' className='label'>Button Name<Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="name"
											name="name"
											placeholder="Name"
											size="small"
											className='textfield'
											style={{ width: '100%' }}
											value={management.name}
											onChange={(e) => {
												handlechange(e);
												setError(prevError => ({ ...prevError, name: '' }));
											}}
										/>
										{error.name && <p style={{ color: 'red' }} className="fz-13 m-0">{error.name}</p>}
									</FormControl>
								</Box>

								<Box className="mb-10">

									<Typography component='div' className='label'>Select Pages</Typography>
									<FormControl fullWidth>
										<Select
											labelId="demo-multiple-checkbox-label"
											id="demo-multiple-checkbox"
											multiple
											className='select w100'
											value={pageName}
											onChange={(e) => handlechangePage(e,)}
											input={<OutlinedInput />}
											renderValue={(selected) => {
												const selecttedPage = page.filter(content => selected.includes(content.pageName));
												if (selecttedPage.length === 0) {
													return "Any Page";
												} else if (selecttedPage.length === 1) {
													return selecttedPage[0].pageName;
												} else {
													return `${selecttedPage.length} Page selected`;
												}
											}}
											MenuProps={MenuProps}
										>
											{page && page.length > 0 ? page.map((name) => (
												<MenuItem key={name._id} value={name.pageName} >
													<Checkbox checked={pageName.indexOf(name.pageName) > -1} />
													<ListItemText primary={name.pageName} />
												</MenuItem>
											)) : <MenuItem>No user found</MenuItem>}
										</Select>

									</FormControl>
								</Box>




								{/* <Box className="mb-20 "> */}
										{/* <Typography component='div' className='label'>Do you want to assign this ads to any community</Typography> */}
										{/* <RadioGroup
											row
											aria-labelledby="demo-customized-radios"
											name="payment_type"
											onChange={handleEditOwner}
											value={selectOwner}
										>
											<FormControlLabel
												value="Event"
												className='radio-btns'
												control={<Radio />}
												label={<Typography className='fz-14'>Event</Typography>}
											/>
											<FormControlLabel
												value="Community"
												className='radio-btns'
												control={<Radio />}
												label={<Typography className='fz-14'>Community</Typography>}
											/>
										</RadioGroup> */}
									{/* </Box>  */}


									
									
										{/* <Grid item xs={12} sm={6} md={6} lg={6}>
											<label className='form-labels mb-0' for="my-input">Select Event</label>
											<FormControl fullWidth>
												<Select
													labelId="demo-multiple-checkbox-label"
													id="demo-multiple-checkbox"
													multiple
													className='select'
													value={eventName}
													onChange={(e) => handlechangeEvent(e,)}
													input={<OutlinedInput />}
													renderValue={(selected) => {
														const selecttedEvent = event.filter(content => selected.includes(content.event_name
															));
														if (selecttedEvent.length === 0) {
															return "Any Event";
														} else if (selecttedEvent.length === 1) {
															return selecttedEvent[0].event_name
															;
														} else {
															return `${selecttedEvent.length} Event selected`;
														}
													}}
													MenuProps={MenuProps}
												>
													{event && event.length > 0 ? event.map((name) => (
														<MenuItem key={name._id} value={name.event_name
														} >
															<Checkbox checked={eventName.indexOf(name.event_name
) > -1} />
															<ListItemText primary={name.event_name
} />
														</MenuItem>
													)) : <MenuItem>No event found</MenuItem>}
												</Select>
												{/* <p className='error-field'>{error.userselect}</p> */}
											{/* </FormControl> */}
										{/* </Grid>  */} 
								
									
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<label className='form-labels mb-0' for="my-input">Do you want to assign this ads to any community</label>
											<FormControl fullWidth>
												<Select
													labelId="demo-multiple-checkbox-label"
													id="demo-multiple-checkbox"
													multiple
													className='select'
													value={communityName}
													onChange={(e) => handlechangeCommunity(e,)}
													input={<OutlinedInput />}
													renderValue={(selected) => {
														const selecttedCommunity = community.filter(content => selected.includes(content.coummunity_name
														));
														if (selecttedCommunity.length === 0) {
															return "Any Community";
														} else if (selecttedCommunity.length === 1) {
															return selecttedCommunity[0].coummunity_name
																;
														} else {
															return `${selecttedCommunity.length} Community selected`;
														}
													}}
													MenuProps={MenuProps}
												>
													{community && community.length > 0 ? community.map((name) => (
														<MenuItem key={name._id} value={name.coummunity_name
														} >
															<Checkbox checked={communityName.indexOf(name.coummunity_name) > -1} />
															<ListItemText primary={name.coummunity_name
															} />
														</MenuItem>
													)) : <MenuItem>No community found</MenuItem>}
												</Select>
												{/* <p className='error-field'>{error.userselect}</p> */}
											</FormControl>
										</Grid> 
								

								<Box className='mb-20'>
									<FormControlLabel className="checkbox_list"
										control={
											<Checkbox
												checked={isToggled}
												onChange={handleToggle}
												name='checkbox-value'
												color='primary'
											/>
										}
										label={<Typography component='div' className='label mb-0'>Do you want to add this as Mega Ad? </Typography>}
									/>
									<FormControlLabel className="checkbox_list"
										control={
											<Checkbox
												checked={isPublic}
												onChange={handleTogglePublic}
												name='checkbox-value'
												color='primary'
											/>
										}
										label={<Typography component='div' className='label mb-0'>Do you want to make this as Public? </Typography>}
									/>
									<FormControlLabel className="checkbox_list"
										control={
											<Checkbox
												checked={isPrimary}
												onChange={handleTogglePrimary}
												name='checkbox-value'
												color='primary'
											/>
										}
										label={<Typography component='div' className='label mb-0'>Do you want to make this as Primary? </Typography>}
									/>
								</Box>
								<Grid item xs={12} sm={12} md={12}  >
									<Box className="flex-center flex-between">
										<Typography component='div' className='label'>Cover Image <Typography component='span' className='star'> * </Typography></Typography>
										<Box className="mb-10 ">
											{statesingle.length < 1 &&
												<Button className="upload_btn" variant="contained" component="label">
													<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
													<input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
												</Button>
											}
										</Box>
									</Box>
								</Grid>
								{imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}
								<Grid item xs={12} sm={12} md={12}  >
									{statesingle.length > 0 &&
										<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
											{statesingle.length > 0 ? statesingle.map((x, i) => (
												<Grid item xs={6} sm={4} md={4} lg={4} >
													<Box className='uploaded_img' >
														<Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
														<img src={x} alt='' height={155} width={195} />
													</Box>
												</Grid>
											)) : ""}

										</Grid>
									}
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
								</Grid>
								<Box className="mb-10">
									<Typography component='div' className='label'>Website URL <Typography component='span' className='star'>*</Typography></Typography>
									<FormControl variant="standard" fullWidth>
										<TextField
											id="filled-size-small"
											variant="outlined"
											placeholder='Link'
											size="small"
											name='link'
											className='textfield'
											style={{ width: '100%' }}
											value={management.link}
											onChange={(e) => {
												handlechange(e); setError(prevError => ({ ...prevError, link: '' }))
											}}
										/>
										{error.link && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.link}</p>}
									</FormControl>
								</Box>

								<Box className="mb-10">
									<Typography component='div' className='label'>Colour picker</Typography>
									<FormControl variant="standard" fullWidth>
										<div>
											<SketchPicker
												color={color}
												onChangeComplete={handleChangeComplete}
											/>
											<p>Selected Color: {color}</p>
											<div
												style={{
													width: '100px',
													height: '100px',
													backgroundColor: color,
												}}
											></div>
										</div>
									</FormControl>
								</Box>
							</Box>
							<Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
								<Button onClick={handleSubmit} variant="contained" className='theme-btn'>Submit</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	)
}