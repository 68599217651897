import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import firebase from "firebase/compat/app";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CardContent from '@mui/material/CardContent';
import { Avatar, Select, MenuItem, SwipeableDrawer, Card, Menu, Link, Grid, Button } from '@mui/material';
import { ArrowDropDown, BusinessOutlined, HomeRounded, MenuOpen, PersonAddAlt1Rounded, PowerSettingsNew, SettingsRounded, Share, Notifications, SmsFailedRounded, SyncAlt, NotificationsNoneRounded, ArrowDropDownTwoTone, Receipt, VpnKeyRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { header, headerformdata } from "../components/axiousheader"
import LanIcon from '@mui/icons-material/Lan';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CategoryIcon from '@mui/icons-material/Category';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ContactsIcon from '@mui/icons-material/Contacts';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { Delete as DeleteIcon } from '@mui/icons-material'
import { deleteToken } from 'firebase/messaging';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react';
import { PiLinkSimpleBold } from "react-icons/pi";

const drawerWidth = 260;

const Accordion = styled((props) => (

	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowDropDownTwoTone className='text_white' sx={{ fontSize: '0.9rem' }} style={{ fontSize: '22px' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'transparent',
	flexDirection: 'row',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(180deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
	backgroundColor: '#fff',
	color: 'white'
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
	backgroundColor: '#FFF',
	color: 'white'
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `100%`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	backgroundColor: '#fff'
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		boxSizing: 'border-box',
		...(open && {
			...openedMixin(theme),
			'& .MuiDrawer-paper': openedMixin(theme),

		}),
		...(!open && {
			...closedMixin(theme),
			'& .MuiDrawer-paper': closedMixin(theme),
		}),

	}),
);


export default function MiniDrawer({ menuOrder, submenuOrder, open1, setOpen1 }) {

// 	const auth = React.useRef(null);
// const role = React.useRef(null);
// const fcm_token = React.useRef(null);
// const permissions = React.useRef(null);
// const currentPlan = React.useRef(null);

	// let auth,cuurentplan,fcm_token, permissions,role
	
	const auth = JSON.parse(localStorage.getItem("userData"))
	const role = JSON.parse(localStorage.getItem("roledata"))
	
	
	
	const fcm_token = (localStorage.getItem("fcmkey"))
	const permissions = role?.UserRoles ? (typeof role?.UserRoles !== "object" || null ? role?.UserRoles : undefined ):"";

	const cuurentplan = auth?.planId && auth?.planId?.name
	
	console.log(permissions,"+++PERMISSIONS++=");


	// React.useEffect(() => {
	// 	auth.current = JSON.parse(localStorage.getItem("userData"));
	// 	role.current = JSON.parse(localStorage.getItem("roledata"));
	// 	fcm_token.current = localStorage.getItem("fcmkey");
	// 	permissions.current = auth.current?.UserRoles ? role?.current?.UserRoles != {} || null ? role.current?.UserRoles : undefined : "";
	// 	currentPlan.current = auth.current?.planId?.name;
		
	// 	console.log(auth.current, "AUTHHHHHHHHHHHH");
	// 	console.log(currentPlan.current, "CURRENT PLAN");
	// 	}, []);
		
		let istrue;
		let isteacher;
		
		// if (role.current?.UserRoles === null) {
		// istrue = false;
		// } else {
		// istrue = true;
		// console.log("UserRoles is not null");
		// }



	if (role?.UserRoles === null) {
		istrue = false
	} else {
		istrue = true
		console.log("UserRoles is not null");
	}
	

	
	if (!role) {
		isteacher = true
	}
	if(role?.usertype === "User"){
		istrue = true
	} 
	else {
		istrue = false
		console.log("UserRoles is not null");
	}	
	


	const sidebar_Items = [
		(auth && auth.role_id === 1) ? (
			istrue === false ? {
				name: 'Home',
				icon: <i class="fa-solid fa-house-chimney sidebarIcons"></i>,
				menuOrder: 1,
				submenu: [
					{
						name: "Yoga Quotes",
						link: '/yogaquote',
						submenuOrder: 1
					},
					{
						name: "Tag",
						link: '/tag',
						submenuOrder: 2
					},
					{
						name: "Slider settings",
						link: '/hometab',
						submenuOrder: 3
					}
				]
			} : (
				istrue === true && permissions?.settings.getsettings || permissions?.quotes.getquotes || permissions?.tags.gettags ? {
					name: 'Home',
					icon: <i class="fa-solid fa-house-chimney sidebarIcons"></i>,
					menuOrder: 1,
					submenu: [
						permissions?.quotes.getquotes && {
							name: "Yoga Quotes",
							link: '/yogaquote',
							submenuOrder: 1
						},
						permissions?.tags.gettags && {
							name: "Tag",
							link: '/tag',
							submenuOrder: 2
						},
						permissions?.settings.getsettings && {
							name: "Slider settings",
							link: '/hometab',
							submenuOrder: 3
						}
					]
				} : null
			)
		) : null,

		(auth && auth.role_id === 1) ? (
			istrue === false ? {
				name: 'Teachers',
				icon: <i class="fa-solid fa-users-line sidebarIcons"></i>,
				menuOrder: 2,
				submenu: [
					{
						name: "Slider Settings",
						link: '/teachersslider',
						submenuOrder: 4
					},
					{
						name: "All Teachers",
						link: '/teachers',
						submenuOrder: 5
					},
					{
						name: "Legends",
						link: '/yogalegend',
						submenuOrder: 6
					},
					{
						name: "Teachers of Great Merit",
						link: '/teachermerit',
						submenuOrder: 7
					},
					{
						name: "Yoga School",
						link: '/yogaschool',
						submenuOrder: 8
					},
					{
						name: "Yoga Studio",
						link: '/yogastudios',
						submenuOrder: 9
					}
				]
			} : (
				istrue === true && permissions?.Teachers.viewTeacher || permissions?.legends.getlegends || permissions?.merit.getmerit || permissions?.school.getschool || permissions?.studio.getstudio ? {
					name: 'Teachers',
					icon: <i class="fa-solid fa-users-line sidebarIcons"></i>,
					menuOrder: 2,
					submenu: [
						permissions?.Teachers.viewTeacher && {
							name: "All Teachers",
							link: '/teachers',
							submenuOrder: 5
						},
						permissions?.legends.getlegends && {
							name: "Legends",
							link: '/yogalegend',
							submenuOrder: 6
						},
						permissions?.merit.getmerit && {
							name: "Teachers of Great Merit",
							link: '/teachermerit',
							submenuOrder: 7
						},
						permissions?.school.getschool && {
							name: "Yoga School",
							link: '/yogaschool',
							submenuOrder: 8
						},
						permissions?.studio.getstudio && {
							name: "Yoga Studio",
							link: '/yogastudios',
							submenuOrder: 9
						}
					]
				} : null
			)
		) : null,

		auth && auth.role_id === 1 && istrue === false ?

			{
				name: 'Events',
				menuOrder: 3,
				icon: <i class="fa-solid fa-calendar-days sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/eventsslider',
						submenuOrder: 10
					},
					{
						name:'Events',
						link: '/events',
						submenuOrder: 11
					}
				]
					

				
			} 
			: auth && auth.role_id === 1 && istrue === true && permissions?.Event.viewEvent &&
			{
				name: 'Events',
				menuOrder: 3,
				icon: <i class="fa-solid fa-calendar-days sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/eventsslider',
						submenuOrder: 10
					},
					{
						name:'Events',
						link: '/events',
						submenuOrder: 11
					}
				]
			},



		auth && auth.role_id == 2  && istrue == false ?
			{
				name: 'Events',
				menuOrder: 3,
				icon: <i class="fa-solid fa-calendar-days sidebarIcons"></i>,
				link: `/teachers/details/events/${auth._id}`
			} : "",




		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'Courses',
				menuOrder: 4,
				icon: <i class="fa-solid fa-book sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/coursesslider',
						submenuOrder: 12
					},
					{
						name:'Courses',
						link: '/course',
						submenuOrder: 13
					}
				]
			} : auth && auth.role_id == 1 && istrue == true && permissions?.Course.viewCourse &&
			{
				name: 'Courses',
				menuOrder: 4,
				icon: <i class="fa-solid fa-book sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/coursesslider',
						submenuOrder: 12
					},
					{
						name:'Courses',
						link: '/course',
						submenuOrder: 13
					}
				]
			},




		auth && auth.role_id == 2  && istrue == false ?
			{
				name: 'Courses',
				menuOrder: 4,
				icon: <i class="fa-solid fa-book sidebarIcons"></i>,
				link: `/teachers/details/course/${auth._id}`
			} : "",

		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'Blogs',
				menuOrder: 5,
				icon: <i class="fa-solid fa-blog sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/blogsslider',
						submenuOrder: 14
					},
					{
						name:'Blogs',
						link: '/blogs',
						submenuOrder: 15
					}
				]
				
				
			} : auth && auth.role_id == 1 && istrue == true && permissions?.Blogs.viewBlogs &&
			{
				name: 'Blogs',
				menuOrder: 5,
				icon: <i class="fa-solid fa-blog sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/blogsslider',
						submenuOrder: 14
					},
					{
						name:'Blogs',
						link: '/blogs',
						submenuOrder: 15
					}
				]
			},

		auth && auth.role_id == 2  && istrue == false ?
			{
				name: 'Blogs',
				menuOrder: 5,
				icon: <i class="fa-solid fa-blog sidebarIcons"></i>,
				link: `/teachers/details/blog/${auth._id}`
			} : "",

		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'Images',
				menuOrder: 6,
				icon: <i class="fa-regular fa-images sidebarIcons"></i>,
				link: '/image'
			} : auth && auth.role_id == 1 && istrue == true && permissions?.Images.getImages &&
			{
				name: 'Images',
				menuOrder: 6,
				icon: <i class="fa-regular fa-images sidebarIcons"></i>,
				link: '/image'
			},

		auth && auth.role_id == 2  && istrue == false ?
			{
				name: 'Images',
				menuOrder: 6,
				icon: <i class="fa-regular fa-images sidebarIcons"></i>,
				link: `/teachers/details/image/${auth._id}`

			} : "",

		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'Videos',
				menuOrder: 7,
				icon: <i class="fa-solid fa-video sidebarIcons"></i>,
				link: '/video'
			} : auth && auth.role_id == 1 && istrue == true && permissions?.Videos.getVideos &&
			{
				name: 'Videos',
				menuOrder: 7,
				icon: <i class="fa-solid fa-video sidebarIcons"></i>,
				link: '/video'
			},

		auth && auth.role_id == 2  && istrue == false?
			{
				name: 'Videos',
				menuOrder: 7,
				icon: <i class="fa-solid fa-video sidebarIcons"></i>,
				link: `/teachers/details/video/${auth._id}`
			} : "",

		(auth && auth.role_id === 1) ? (
			istrue === true && permissions?.Category.viewCategory || permissions?.SubCategory.viewSubCategory ?  {
				name: 'Categories',
				menuOrder: 8,
				icon: <CategoryIcon className='sidebarIcons' />,
				submenu: [
					permissions?.Category.viewCategory && {
						name: 'All Categories',
						link: '/category',
						submenuOrder: 16
					},
					permissions?.SubCategory?.viewSubCategory && {
						name: 'Sub Categories',
						link: '/subcategory',
						submenuOrder: 17
					}
				]
			} : istrue === false ? {
				name: 'Categories',
				menuOrder: 8,
				icon: <CategoryIcon className='sidebarIcons' />,
				submenu: [
					{
						name: 'All Categories',
						link: '/category',
						submenuOrder: 16
					},
					{
						name: 'Sub Categories',
						link: '/subcategory',
						submenuOrder: 17
					}
				]
			} : ""
		) : "",

	auth && auth.role_id == 1 && istrue == false ?
		{
			name: 'About',
			menuOrder: 9,
			icon: <i class="fa-regular fa-address-card sidebarIcons"></i>,
			link: '/about'
		} : auth && auth.role_id == 1 && istrue == true && permissions?.About.getAbout &&
		{
			name: 'About',
			menuOrder: 9,
			icon: <i class="fa-regular fa-address-card sidebarIcons"></i>,
			link: '/about'
		},


		auth && auth.role_id === 1 && istrue === false ?
			{
				name: 'Subscription Management',
				menuOrder: 10,
				icon: <i class="fa-regular fa-hand-pointer sidebarIcons"></i>,
				link: '/subscription'
			} : auth && auth.role_id === 1 && istrue === true && permissions?.subscription.getsubscription &&
			{
				name: 'Subscription Management',
				menuOrder: 10,
				icon: <i class="fa-regular fa-hand-pointer sidebarIcons"></i>,
				link: '/subscription'
			},


		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'News',
				menuOrder: 11,
				icon: <i class="fa-regular fa-newspaper sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/newsslider',
						submenuOrder: 18
					},
					{
						name:'News',
						link: '/news',
						submenuOrder: 19
					}
				]
			} : auth && auth.role_id == 1 && istrue == true && permissions?.News.viewNews &&
			{
				name: 'News',
				menuOrder: 11,
				icon: <i class="fa-regular fa-newspaper sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/newsslider',
						submenuOrder: 18
					},
					{
						name:'News',
						link: '/news',
						submenuOrder: 19
					}
				]
			},

		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'Careers',
				menuOrder: 12,
				icon: <i class="fa-solid fa-user-tie sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/jobSliders',
						submenuOrder: 20
					},
					{
						name:'Careers',
						link: '/career',
						submenuOrder: 21
					}
				]
				

			} : auth && auth.role_id == 1 && istrue == true && permissions?.Jobs.viewJobs &&
			{
				name: 'Careers',
				menuOrder: 12,
				icon: <i class="fa-solid fa-user-tie sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/jobSliders',
						submenuOrder: 20
					},
					{
						name:'Careers',
						link: '/career',
						submenuOrder: 21
					}
				]
			},

		

		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'Community',
				menuOrder: 13,
				icon: <i class="fa-solid fa-handshake-simple sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/communitysliders',
						submenuOrder: 22
					},
					{
						name:'Community',
						link: '/community',
						submenuOrder: 23
					}
				]
			
			} : auth && auth.role_id == 1 && istrue == true && permissions?.Community.getCommunity &&
			{
				name: 'Community',
				menuOrder: 13,
				icon: <i class="fa-solid fa-handshake-simple sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/communitysliders',
						submenuOrder: 22
					},
					{
						name:'Community',
						link: '/community',
						submenuOrder: 23
					}
				]
			},

		auth && auth.role_id == 2  && istrue == false ?
			{
				name: 'Community',
				menuOrder: 13,
				icon: <i class="fa-solid fa-handshake-simple sidebarIcons"></i>,
				link: `/teachers/details/community/${auth._id}`
			} : "",

		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'Book Reviews',
				menuOrder: 14,
				icon: <i class="fa-solid fa-book-open-reader sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/bookreviewsliders',
						submenuOrder: 24
					},
					{
						name:'Book Reviews',
						link: '/bookreviews',
						submenuOrder: 25
					}
				]
			} : auth && auth.role_id == 1 && istrue == true && permissions?.BookReview.getBookReview &&
			{
				name: 'Book Reviews',
				menuOrder: 14,
				icon: <i class="fa-solid fa-book-open-reader sidebarIcons"></i>,
				submenu:[
					{
						name:'Slider Settings',
						link: '/bookreviewsliders',
						submenuOrder: 24
					},
					{
						name:'Book Reviews',
						link: '/bookreviews',
						submenuOrder: 25
					}
				]
			},


		auth && auth.role_id == 2  && istrue == false?
			{
				name: 'Book Reviews',
				menuOrder: 14,
				icon: <i class="fa-solid fa-book-open-reader sidebarIcons"></i>,
				link: `/teachers/details/bookreview/${auth._id}`
			} : "",

		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'Podcast',
				menuOrder: 15,
				icon: <i class="fa-solid fa-podcast sidebarIcons"></i>,
				link: '/podcast'
			} : auth && auth.role_id == 1 && istrue == true && permissions?.Podcast.getPodcast &&
			{
				name: 'Podcast',
				menuOrder: 15,
				icon: <i class="fa-solid fa-podcast sidebarIcons"></i>,
				link: '/podcast'
			},


		auth && auth.role_id == 2  && istrue == false?
			{
				name: 'Podcast',
				menuOrder: 15,
				icon: <i class="fa-solid fa-podcast sidebarIcons"></i>,
				link: `/teachers/details/podcast/${auth._id}`
			} : "",

		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'Ad management',
				menuOrder: 16,
				icon: <i class="fa-solid fa-person-circle-plus sidebarIcons"></i>,
				link: '/offers'
			} : auth && auth.role_id == 1 && istrue == true && permissions?.AddManagements.getAddManagements &&
			{
				name: 'Ad management',
				menuOrder: 16,
				icon: <i class="fa-solid fa-person-circle-plus sidebarIcons"></i>,
				link: '/offers'
			},


		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'Newsletter Subscription',
				menuOrder: 17,
				icon: <i class="fa-solid fa-envelope-open-text sidebarIcons"></i>,
				link: '/newsletter'
			} : auth && auth.role_id == 1 && istrue == true && permissions?.newsletter.getnewsletter &&
			{
				name: 'Newsletter Subscription',
				menuOrder: 17,
				icon: <i class="fa-solid fa-envelope-open-text sidebarIcons"></i>,
				link: '/newsletter'
			},



		auth && auth.role_id == 2  && isteacher == true ?
			{
				name: 'Fan page',
				menuOrder: 17,
				icon: <i class="fa-solid fa-person-circle-plus sidebarIcons"></i>,
				link: `/teachers/details/fans/${auth._id}`
			} : "",

		auth && auth.role_id === 1 && istrue === false ?
			{
				name: 'Customer Management',
				menuOrder: 18,
				icon: <i class="fa-solid fa-users sidebarIcons"></i>,
				link: `/users`
			} : auth && auth.role_id === 1 && istrue === true && permissions?.users.getusers &&
			{
				name: 'Customer Management',
				menuOrder: 18,
				icon: <i class="fa-solid fa-users sidebarIcons"></i>,
				link: `/users`
			},

		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'Role Management',
				menuOrder: 19,
				icon: <BusinessOutlined className='sidebarIcons' />,
				link: '/rolemanagement'
			} : auth && auth.role_id == 1 && istrue == true && permissions?.users.getusers &&
			{
				name: 'Role Management',
				menuOrder: 19,
				icon: <BusinessOutlined className='sidebarIcons' />,
				link: '/rolemanagement'
			},

		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'Portal Users',
				menuOrder: 20,
				icon: <BusinessOutlined className='sidebarIcons' />,
				link: '/portalusers'
			} : auth && auth.role_id == 1 && istrue == true && permissions?.users.getusers &&
			{
				name: 'Portal Users',
				menuOrder: 20,
				icon: <BusinessOutlined className='sidebarIcons' />,
				link: '/portalusers'
			},

		auth && auth.role_id == 1 && istrue == false ?
			{
				name: 'Social Links',
				menuOrder: 20,
				icon: <PiLinkSimpleBold className='sidebarIcons' />,
				link: '/viewlinks'
			} : auth && auth.role_id == 1 && istrue == true && permissions?.users.getusers &&
			{
				name: 'Social Links',
				menuOrder: 20,
				icon: <PiLinkSimpleBold className='sidebarIcons' />,
				link: '/viewlinks'
			},

		auth && auth.role_id == 2  && isteacher == true ?
			{
				name: 'Fan page slider',
				menuOrder: 21,
				icon: <i class="fa-solid fa-image sidebarIcons"></i>,
				link: `/teachers/details/fansslidersettings/${auth._id}`
			} : "",


			auth && auth.role_id === 1 && istrue === false ?
			{
				name: 'My Users IDs',
				menuOrder: 22,
				icon: <i class="fa-solid fa-users-gear sidebarIcons"></i>,
				link: `/username`
			} : auth && auth.role_id === 1 && istrue === true && permissions?.users.getusers &&
			{
				name: 'My Users IDs',
				menuOrder: 22,
				icon: <i class="fa-solid fa-users-gear sidebarIcons"></i>,
				link: `/username`
			},

	]

	const BootstrapDialog = styled(Dialog)(({ theme }) => ({
		"& .MuiDialogContent-root": {
			padding: theme.spacing(2),
		},
		"& .MuiDialogActions-root": {
			padding: "15px",
		},
	}));

	const [expanded, setExpanded] = React.useState(false);
	const [open, setOpen] = React.useState(true);
	const [notify_id, setNotify_id] = React.useState()
	const [notification, setNotification] = React.useState([])
	const [resdrawer, setresdrawer] = React.useState(true);
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});
	let navigate = useNavigate()

	const handleDrawerToggle = () => {
		setOpen(!open);
		document.querySelector('body').classList.toggle("sidebarclose");
		localStorage.setItem('expanded', false)
		setExpanded(!expanded);
	};

	const PageNavigation = (url) => {
		document.querySelector('body').classList.remove("sidebarclose");
		navigate(url);
	}
	// const [notificationData, setNotificationData] = React.useState("");
	const [notificationLength, setNotificationLength] = React.useState("");

	// ----responsive drawer------//
	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setState({ ...state, [anchor]: open });
	};
	const toggleDrawer_res = (anchor) => (event) => {
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setState({ ...state, [anchor]: !state[anchor] });
	};

	const list = (anchor) => (
		<Box
			sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
			role="presentation"
			// onClick={toggleDrawer(anchor, false)}
			// onKeyDown={toggleDrawer(anchor, false)}
			style={{ paddingTop: 60 }}
		>
			<List>
				{sidebar_Items.map((text, index) => (
					text &&
					<><ListItem key={index} disablePadding sx={{ display: 'block' }} >
						{text.submenu ?
							<ListItemButton
								sx={{
									minHeight: 44,
									justifyContent: open ? 'initial' : 'center',
									fontSize: 13,
								}}
								className='p-0'
								selected={menuOrder === text.menuOrder}
							>
								<Accordion expanded={JSON.parse(expanded) === index} onChange={handleChange(index)} className='accordion'>
									<AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className='sidebar-menuItem'>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: 3,
												justifyContent: 'center',
											}}
											style={{ marginRight: '8px' }}
											onClick={() => PageNavigation(text.link)}
											className='listitemicon'>
											{text.icon}
										</ListItemIcon>
										<ListItemText primary={text.name} className='item-text text_white fz-12' sx={{ opacity: 1 }} />
									</AccordionSummary>
									{(text.submenu !== undefined) ?
										text.submenu?.map((data, key) => {
											return (
												data &&
												<AccordionDetails style={{ padding: 0 }} className='accordion-details'>
													<Typography key={index}  >
														<ListItemButton
															sx={{
																minHeight: 48,
																justifyContent: open1 ? 'initial' : 'center',


															}}
															key={index}
															className='item-btn'
															// onClick={(event) => handleListItemClick(event, key, data.link)}
															selected={selectedIndex === data.submenuOrder}
														>
															<ListItemText primary={data.name} onClick={() => { PageNavigation(data.link) }} className='sidebar_itemText' sx={{ opacity: 1 }} />
														</ListItemButton>
													</Typography>

												</AccordionDetails>
											)
										}) : null}
								</Accordion>

							</ListItemButton>
							:
							<ListItemButton className='' sx={{
								minHeight: 48,
								justifyContent: open1 ? 'initial' : 'center',
								px: 2,
								paddingY: 0,
							}}
								// onClick={(event) => { handleMenuOrder(event, text.menuOrder) }}
								selected={selectedIndex1 == text.menuOrder}>
								<Box className='sidebar-menuItem'>
									<ListItemIcon
										sx={{
											minWidth: 0,
											mr: 3,
											justifyContent: 'center',
										}}
										style={{ marginRight: '8px' }}
										onClick={() => {
											PageNavigation(text.link);
											localStorage.removeItem('panel');
										}}
										className='listitemicon'>
										{text.icon}
									</ListItemIcon>
									<ListItemText primary={text.name} onClick={() => {
										PageNavigation(text.link);
										localStorage.removeItem('panel');
									}} className='sidebar_itemText' sx={{ opacity: 1 }} />
								</Box>
							</ListItemButton>
						}
					</ListItem>
					</>


				))}
			</List>
		</Box>
	);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [selectedIndex1, setSelectedIndex1] = React.useState(0);


	const handleNotificationClose = () => {
		setOpenNotifiction(false);
	};


	React.useEffect(() => {
		setSelectedIndex1(menuOrder)
		console.log(menuOrder, 'menuOrder')
	}, [menuOrder])

	const handleListItemClick = (event, index) => {
		setSelectedIndex(index);
		document.querySelector('body').classList.remove("sidebarclose");
	};
	const openAcct = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};


//Admin LogOut functionality:
	const handleSignout = async () => {
		let body = {
			user_id: auth._id,
			FCM_token: fcm_token ? fcm_token : ""
		}
		localStorage.clear();
		await axios.post(process.env.REACT_APP_BASE_URL + `Admin/logout`, body)
			.then(async (res) => {
				localStorage.removeItem("token");
				localStorage.removeItem("userData");
				navigate("/")
				//service worker unregister
				// navigator.serviceWorker.getRegistration().then(async(r) => {
				// 	await r.unregister()
				//  });


				// firebase.database().ref(`Users/${auth._id}`).update({
				// fcm: "",
				// });
			})
			.catch((error) => {
				// enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
				console.log(error, "error");
			})

	};

	const handleClickmenu = (e) => {
		if (window.location.pathname === '/profileupdate') {
			setAnchorEl(null);
		}
		navigate("/profileupdate")
	}

	// const handleClicklogout = () => {
	// 	handleSignout();
	// }

	const handleSignoutteacher = () => {
		navigate("/teacherlogin")

	}

	const handleClicklogout = (id) => {
		id == 1 ? handleSignout() : handleSignoutteacher()

	}

	const handleCloseMenu = (e) => {
		setAnchorEl(null);
	};

	React.useEffect(() => {
		if (submenuOrder === 'undefined' || submenuOrder === undefined) {
			setSelectedIndex(submenuOrder);
			setExpanded(false)

		}
		else {
			setSelectedIndex(submenuOrder);
		}

	}, [submenuOrder])

	React.useEffect(() => {
		setSelectedIndex1(menuOrder);
	}, [menuOrder])

	const showSettingsMenu = () => {
		document.querySelector('.settings').classList.toggle("showSettingMenu");

	}
	const showAccountMenu = () => {
		document.querySelector('body').classList.toggle("showAccountMenu");
	}


	const MenuProps = {
		PaperProps: {
			style: {
				// maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
				left: 1625
			},
		},
	};

	const handleChange = (panel) => (event, newExpanded) => {

		if (newExpanded === true) {
			localStorage.setItem('expanded', true)
			localStorage.setItem('panel', JSON.parse(panel))
		} else {
			localStorage.setItem('expanded', false)
		}
		setExpanded(newExpanded ? panel : false);

	};

	function formatDateTime(dateTimeString) {
		var date = new Date(dateTimeString);

		var day = date.getDate().toString().padStart(2, '0');
		var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so we add 1
		var year = date.getFullYear();

		var hours = date.getHours();
		var minutes = date.getMinutes();
		var period = hours >= 12 ? 'PM' : 'AM';

		hours = hours % 12;
		hours = hours ? hours : 12; // Handle midnight case

		minutes = minutes < 10 ? '0' + minutes : minutes;

		var formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes} ${period}`;

		return formattedDateTime;
	}



	React.useState(() => {
		setExpanded(localStorage.getItem('panel'));
	}, [expanded])

	const data = [
		{
			name: ' INVOICE',
			icon: <Receipt className='icons-img' style={{ width: '20px' }} />,
			submenu: [
				{
					name: "Add Invoice",
					link: '/addInvoice',
					order: 14
				},
				{
					name: "List Invoice",
					link: '/invoice',
					order: 15
				},
			],
			link: '/channels'
		}
	]

	const handleMenuOrder = (event, val) => {
		// setExpanded(newExpanded ? panel : false);
	};
	const [openNotifiction, setOpenNotifiction] = React.useState(false);
	const [userDetailes, setUserDetailes] = React.useState("");
	const [openview, setOpenview] = React.useState(false);
	const [alldata, setAlldata] = React.useState("");
	const [nav, setNav] = React.useState('')
	const handleNotificationClick = (event) => {
		setOpenNotifiction(true);
	};

	React.useEffect(() => {
		getNotifications()
		getDetailes()
	}, [])

	const getNotifications = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Notification/getNotification`).then((res) => {
			console.log(res.data.notificationdata, 'notifications');
			setNotification(res.data.notificationdata)
			setNav(res.data.notificationdata.notificationData)
			console.log(nav, "navigatorrr");
			const sortedNotifications = res.data.notificationdata.sort((a, b) => {
				return new Date(b.createdAt) - new Date(a.createdAt);
			});
			const unreadNotifications = sortedNotifications.filter((notif) => !notif.isRead != false);
			setNotificationLength(unreadNotifications);

			//   setNotification(unreadNotifications);
		}).catch((error) => {
			console.log(error, "error");
		})
	}

	const [teachernotification, setTeachernotification] = React.useState()
	const [teachernotificationLength, setTeacherNotificationLength] = React.useState("");
	// console.log(teachernotificationLength, "teachernotificationLength");


	React.useEffect(() => {
		getTeacherNotifications()
		getDetailes()
	}, [])

	const getTeacherNotifications = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Notification/getTeachersNotificationDetailsbasedonId/${auth._id}`).then((res) => {
			setTeachernotification(res.data.notificationDetails)
			const sortedNotifications = res.data.notificationDetails.sort((a, b) => {
				return new Date(b.createdAt) - new Date(a.createdAt);
			});
			const unreadNotifications = sortedNotifications.filter((notif) => !notif.isRead != false);
			setTeacherNotificationLength(unreadNotifications);


			//   setNotification(unreadNotifications);
		}).catch((error) => {
			console.log(error, "error");
		})
	}

	React.useEffect(() => {
		getPlan()
	}, [])

	

	// console.log(notificationLength,"notificationLengthnotificationLengthnotificationLength");
	// console.log(auth._id,"auth._idauth._id");
	// console.log(notification.map((data,i) => data._id),"notification id");


	const getDetailes = async (data) => {

		// const notifyId = data;
		console.log(notify_id, "notifyIdnotifyId");
		await axios.get(
			process.env.REACT_APP_BASE_URL + `Notification/getNotificationDetailsbasedonId/${notify_id}`
		)
			.then((res) => {
				console.log(res, "responce dataaaa");
				let detailes = res.data.notificationDetails.sender_id;
				setUserDetailes(detailes);
				handleViewOpen(detailes);
			})
			.catch((error) => {
				console.log(error, "error");
			});
	};
	const [plan, setPlan] = React.useState()

	const [openplan, setOpenplan] = React.useState(false)
	const handleplans = () => {
		setOpenPayment(true)
	}

	const handleClickplanClose = () => {
		setOpenplan(false)
	}

	
//To delete notifications:
	const handleDelete = async (data) => {
		await axios.put(process.env.REACT_APP_BASE_URL + `Notification/deleteNotification/${notify_id}`)
		getDetailes()
	}


//Notifications Navigate:
	const handleView = async (data) => {
		setUserDetailes(data);
		setNotify_id(data._id)
		const body = {
			'isRead': true
		}
		await axios.put(process.env.REACT_APP_BASE_URL + `Notification/updateNotificationRead/${data._id}`, body)
		.then((res) => {
			console.log(res.data.updateIsRead, "response of update");
			setOpenNotifiction(true);
			handleViewOpen(data)
			getNotifications()
			if (res.data.updateIsRead.notificationData && res.data.updateIsRead.notificationData.TeacherId) {
				if (res.data.updateIsRead.notificationData.TeacherId && res.data.updateIsRead.notificationData.EventId) {
					navigate(`/teachers/details/events/${res.data.updateIsRead.notificationData.TeacherId}`)
				}
				else if (res.data.updateIsRead.notificationData.TeacherId && res.data.updateIsRead.notificationData.PodcastId) {
					navigate(`/teachers/details/podcast/${res.data.updateIsRead.notificationData.TeacherId}`)
				}
				else if (res.data.updateIsRead.notificationData.TeacherId && res.data.updateIsRead.notificationData.blogId) {
					navigate(`/teachers/details/blog/${res.data.updateIsRead.notificationData.TeacherId}`)
				}
				else if (res.data.updateIsRead.notificationData.TeacherId && res.data.updateIsRead.notificationData.courseId) {
					navigate(`/teachers/details/course/${res.data.updateIsRead.notificationData.TeacherId}`)
				}
				else if (res.data.updateIsRead.notificationData.TeacherId && res.data.updateIsRead.notificationData.imageId) {
					navigate(`/teachers/details/image/${res.data.updateIsRead.notificationData.TeacherId}`)
				}
				else if (res.data.updateIsRead.notificationData.TeacherId && res.data.updateIsRead.notificationData.videoId) {
					navigate(`/teachers/details/video/${res.data.updateIsRead.notificationData.TeacherId}`)
				}
				else {
					navigate('/teachers')
				}
			}
			else if (res.data.updateIsRead.notificationData.CommunityId) {
				if (res.data.updateIsRead.notificationData.CommunityId && res.data.updateIsRead.notificationData.courseId) {
					navigate(`/community/community-details/course/${res.data.updateIsRead.notificationData.CommunityId}`)
				}
				else if (res.data.updateIsRead.notificationData.CommunityId && res.data.updateIsRead.notificationData.EventId) {
					navigate(`/community/community-details/event/${res.data.updateIsRead.notificationData.CommunityId}`)
				}
				else if (res.data.updateIsRead.notificationData.CommunityId && res.data.updateIsRead.notificationData.eventId) {
					navigate(`/community/community-details/event/${res.data.updateIsRead.notificationData.CommunityId}`)
				}
				else if (res.data.updateIsRead.notificationData.CommunityId && res.data.updateIsRead.notificationData.podcastId) {
					navigate(`/community/community-details/podcast/${res.data.updateIsRead.notificationData.CommunityId}`)
				}
				else if (res.data.updateIsRead.notificationData.CommunityId && res.data.updateIsRead.notificationData.PodcastId) {
					navigate(`/community/community-details/podcast/${res.data.updateIsRead.notificationData.CommunityId}`)
				}
				else if (res.data.updateIsRead.notificationData.CommunityId && res.data.updateIsRead.notificationData.imageId) {
					navigate(`/community/community-details/gallery/${res.data.updateIsRead.notificationData.CommunityId}`)
				}
				else if (res.data.updateIsRead.notificationData.CommunityId && res.data.updateIsRead.notificationData.videoId) {
					navigate(`/community/community-details/vides/${res.data.updateIsRead.notificationData.CommunityId}`)
				}
				else if (res.data.updateIsRead.notificationData.CommunityId && res.data.updateIsRead.notificationData.Userid) {
					navigate(`/community/community-details/users/${res.data.updateIsRead.notificationData.CommunityId}`)
				}
				// else if(res.data.updateIsRead.notificationData.CommunityId && res.data.updateIsRead.notificationData.DescId)
				// {
				// 	navigate(`/community/community-details/desc/${res.data.updateIsRead.notificationData.CommunityId}`)
				// }
				// else if(res.data.updateIsRead.notificationData.CommunityId && res.data.updateIsRead.notificationData.AttachmentId)
				// {
				// 	navigate(`/community/community-details/attachments/${res.data.updateIsRead.notificationData.CommunityId}`)
				// }
				else {
					navigate('/community')
				}
			}
		}).catch
			((error) => {
				getNotifications()
				console.log(error, "error");
			})

	};

	const handleViewOpen = (detailes) => {
		setAlldata(detailes);
		setOpenNotifiction(false);
	};

	const handleClickClose = () => {
		setOpenNotifiction(false);
		setAlldata("");
		getNotifications();
	};

	

	const handleTeacherReadUpdate = async (data) => {
		console.log(data, "datttt");
		let body = {
			isRead: true,
			notification_id: data._id
		}
		await axios.put(process.env.REACT_APP_BASE_URL + `Notification/updateNotificationRead/${data._id}`, body)
			.then((res) => {
				getTeacherNotifications()
				console.log(res, "resssss");
			})
			.catch((error) => {
				console.log(error, "error");
			})
	}

	const [subscribe, setSubscribe] = React.useState([]);
	const [hoveredIndex, setHoveredIndex] = React.useState(null);

	//Hook's state:
	React.useEffect(() => {
		getPlans();
	}, []);

	const [plandetails, setPlandetails] = React.useState([])


	// const getPlans = async () => {
	// 	try {
	// 		const res = await axios.get(process.env.REACT_APP_BASE_URL + `subscription/GetSubscriptionPublic?usertype=teacher`);
	// 		console.log(res.data, "sssssuuuuu");
	// 		const filteredPlanDetails = res.data.getSubscriptiondata.filter(data => data.name == auth && auth?.planId?.name == "AddOnsSubscription");

	
	// 		setPlandetails(filteredPlanDetails);
	// 		setSubscribe(res.data.getSubscriptiondata);

	// 		// Check if there is at least one matching plan and log its name
	// 		if (filteredPlanDetails.length > 0) {
	// 			console.log(filteredPlanDetails[0].name, "kkkkkk");
	// 		} else {
	// 			console.log("No plan details found for the current plan.");
	// 		}
	// 	} catch (err) {
	// 		console.log(err, "error in getPlans");
	// 	}
	// };
	const getPlans = async () => {
		try {
			const res = await axios.get(process.env.REACT_APP_BASE_URL + 'subscription/GetSubscriptionPublic?usertype=teacher');
			let filteredPlanDetails = [];
	
			if (auth?.planId?.name === "AddOnsSubscription") {
				filteredPlanDetails = res.data.getSubscriptiondata.filter(data => data?.name === auth?.name);
			} else {
				console.log("No specific plan filtering applied.");
				filteredPlanDetails = res.data.getSubscriptiondata; 
			}
			setPlandetails(filteredPlanDetails);
			setSubscribe(res.data.getSubscriptiondata);
			console.log(res.data.getSubscriptiondata)
			console.log(filteredPlanDetails)
	
			if (filteredPlanDetails.length > 0) {
				console.log(filteredPlanDetails[0].name, "kkkkkk");
			} else {
				console.log("No plan details found for the current plan.");
			}
		} catch (err) {
			console.error(err, "error in getPlans");
		}

		// try {
		// 	const res = await axios.get(process.env.REACT_APP_BASE_URL + `Admin/getTeachersByUsername/${auth.username}`);
		// 	let filteredPlanDetails = [];
		// 	console.log(res.data.teachers[0].plan_details)
	
		// 	// if (auth?.planId?.name === "AddOnsSubscription") {
		// 	// 	filteredPlanDetails = res.data.getSubscriptiondata.filter(data => data.name === auth.name);
		// 	// } else {
		// 	// 	console.log("No specific plan filtering applied.");
		// 	// 	filteredPlanDetails = res.data.getSubscriptiondata; 
		// 	// }
		// 	setPlandetails(res.data.teachers[0].plan_details);
		// 	// setSubscribe(res.data.getSubscriptiondata);
		// 	setSubscribe(res.data.teachers[0].plan_details);
		// 	// console.log(res.data.getSubscriptiondata)
	
		// 	if (filteredPlanDetails.length > 0) {
		// 		console.log(filteredPlanDetails[0].name, "kkkkkk");
		// 	} else {
		// 		console.log("No plan details found for the current plan.");
		// 	}
		// } catch (err) {
		// 	console.error(err, "error in getPlans");
		// }
	};

	const getPlan = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Admin/TeachersPlan/${auth._id}`).then((res) => {
			setPlan(res.data.getDetails.plan_details)
			console.log(res.data.getDetails.plan_details, "getplannn");
		}).catch((error) => {
			console.log(error.response, "error");
		})
	}
	


	const [openpayment, setOpenPayment] = React.useState()
	const handleClose = () => {
		setOpenPayment(false)
	}



//Stripe Payment:
	const handlePayment = (item) => {
		let paymentbody = {
			"planId": item.stripePlanId,
			"userId": auth._id,
			"customerId": auth.stripeCustomerId,
			"subscriptionId": item._id
		}
		axios.post(process.env.REACT_APP_BASE_URL + 'subscription/create-sessionpublic', paymentbody)
			.then((res) => {
				if (res.data.session.url) {
					let newurl = res.data.session.url
					window.open(newurl)
				}
			})
			.catch((err) => {
				console.log(err, "error in handlePayment");
			})
	};

	const shouldUseSwiper = subscribe && subscribe.length > 3;

	return (
		<Box sx={{ display: 'flex' }}>
			<AppBar position="fixed" open={open} className='appbar'>
				<Toolbar className='toolbar'>
					<Box className='logo'>
						<img src={require('./../assets/images/finallogo.png')} alt='picprofile' width='100%' height='auto'></img>
					</Box>
					<Box className='icons'>
						<IconButton
							onClick={handleNotificationClick}
							size="small"
							aria-label="show notifications"
							disableRipple={true}
						>
							{auth.role_id === 1 ? <Stack spacing={5} direction="row">
								<Badge badgeContent={notification.filter(ndata => !ndata.isRead).length} className="badge">
									<NotificationsIcon />
								</Badge>
							</Stack> : <Stack spacing={5} direction="row">
								<Badge badgeContent={teachernotificationLength.length} className="badge">
									<NotificationsIcon />
								</Badge>
							</Stack>}
						</IconButton>
						{auth && auth.role_id === 1 && <>
							<Box className='account'>
								<IconButton
									onClick={handleClick}
									size="small"
									sx={{ ml: 2 }}
									aria-controls={open ? 'account-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={open ? 'true' : undefined}
									disableRipple={true}
								>
									<Avatar sx={{ width: 32, height: 32, marginRight: 1 }} alt={auth && auth.username}>{auth && auth.userrole && (auth.username?.split('')[0][0])}</Avatar>
									<Typography component='div' variant='body1' className='fz-13 name'>{auth && auth.username}</Typography>
									{/* <ArrowDropDown sx={{ marginTop: -0.4, fontSize: 18, color: '#222' }} /> */}
								</IconButton>
							</Box>
						</>
						}
					</Box>

					{auth && (auth.role_id === 2 || auth.role_id === 3) &&
						<>
							<Box className='account'>
								<IconButton
									onClick={handleClick}
									size="small"
									sx={{ ml: 2 }}
									aria-controls={open ? 'account-menu' : undefined}
									aria-haspopup="true"
									aria-expanded={open ? 'true' : undefined}
									disableRipple={true}
								>
									<Avatar sx={{ width: 32, height: 32, marginRight: 1 }} alt={auth && auth.firstname} src={auth && process.env.REACT_APP_BASE_URL + auth.image}>{auth && auth?.username && auth?.username.split('')[0][0]} </Avatar>
									<Typography component='div' variant='body1' className='fz-13 name'>{auth && auth.username}</Typography>
									<ArrowDropDown sx={{ marginTop: -0.4, fontSize: 18, color: '#222' }} />
								</IconButton>
							</Box>
						</>
					}

					<Menu
						className='acct-menu select'
						anchorEl={anchorEl}
						id="account-menu"
						open={openAcct}
						// onChange={handleClickmenu}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
						onClose={handleCloseMenu}
					>
						{/* {auth && auth.role_id == 1 ? null : <MenuItem className='fz-14 appbarselect' value={'profile'} onClick={handleClickmenu}>My Profile</MenuItem>} */}
						{auth.role_id === 2 ? <MenuItem className='fz-14 appbarselect' value={'currentplan'} onClick={() => { handleplans() }} >Current Plan</MenuItem> : ''}
						<MenuItem className='fz-14 appbarselect' value={'logout'} onClick={() => { handleClicklogout(auth.role_id) }} >Logout</MenuItem>
					</Menu>


					<IconButton
						aria-label="open drawer"
						edge="end"
						onClick={() => showAccountMenu()}
						// sx={{ ...(open && { display: 'none' }) }}
						className='Res_AccountIcon'
					>
						<Share />

					</IconButton>
					<IconButton
						aria-label="open drawer"
						edge="end"
						onClick={() => showSettingsMenu()}
						// sx={{ ...(open && { display: 'none' }) }}
						className='Res_settingsIcon'
					>
						<SyncAlt />

					</IconButton>
					<Box className='res-menu-icon'>
						{['left'].map((anchor) => (

							<React.Fragment key={anchor}>
								<IconButton
									color="inherit"
									aria-label="open drawer"
									edge="end"
									onClick={toggleDrawer_res(anchor)}
									// sx={{ ...(open && { display: 'none' }) }}
									className=''
								>
									<MenuIcon />
								</IconButton>
								<SwipeableDrawer
									anchor={anchor}
									open={state[anchor]}
									onClose={toggleDrawer(anchor, false)}
									onOpen={toggleDrawer(anchor, true)}
									className='res-drawer'

								>
									{list(anchor)}
								</SwipeableDrawer>
							</React.Fragment>
						))}
					</Box>
				</Toolbar>
				{/* <Box className='resAccountmenu'>
					<Box className='account'>
						<IconButton
							onClick={handleClick}
							size="small"
							sx={{ ml: 2 }}
							aria-controls={open ? 'account-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							disableRipple={true}
						>
							<Avatar sx={{ width: 32, height: 32, marginRight: 1 }} alt={auth && auth.firstname} src={auth && process.env.REACT_APP_BASE_URL + auth.Image}>{"SA"} </Avatar>
							<Typography component='div' variant='body1' className='fz-13 name text_black'>Admin</Typography>
							<ArrowDropDown sx={{ marginTop: -0.4, fontSize: 18, color: '#222' }} />
						</IconButton>
					</Box>
				</Box> */}
			</AppBar>
			<Drawer variant="permanent" open={open} className='Custom_drawer'>
				<Box className={open ? 'menuicon-box' : 'menuIcon-boxClosed'}>
					{/* {open ? <Typography component='h4' variant='body1' className='fz-12'>Main</Typography> : null} */}
					<IconButton onClick={handleDrawerToggle}>
						<MenuOpen className='menuicon' />
					</IconButton>
				</Box>
				<List>
					{sidebar_Items.map((text, index) => (
						text &&
						<><ListItem key={index} disablePadding sx={{ display: 'block' }} >
							{text.submenu ?
								<ListItemButton
									sx={{
										minHeight: 44,
										justifyContent: open ? 'initial' : 'center',
										fontSize: 13,
									}}
									className='p-0'
									selected={menuOrder === text.menuOrder}
								>
									<Accordion expanded={JSON.parse(expanded) === index} onChange={handleChange(index)} className='accordion'>
										<AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className='sidebar-menuItem'>
											<ListItemIcon
												sx={{
													minWidth: 0,
													mr: 3,
													justifyContent: 'center',
												}}
												style={{ marginRight: '8px' }}
												onClick={() => PageNavigation(text.link)}
												className='listitemicon'>
												{text.icon}
											</ListItemIcon>
											<ListItemText primary={text.name} className='item-text fz-12' sx={{ opacity: 1 }} />
										</AccordionSummary>
										{(text.submenu !== undefined) ?
											text.submenu?.map((data, key) => {
												return (
													data &&
													<AccordionDetails style={{ padding: 0 }} className='accordion-details'>
														<Typography key={index}  >
															<ListItemButton
																sx={{
																	minHeight: 48,
																	justifyContent: open1 ? 'initial' : 'center',


																}}
																key={index}
																className='item-btn'
																// onClick={(event) => handleListItemClick(event, key, data.link)}
																selected={selectedIndex === data.submenuOrder}
															>
																<ListItemText primary={data.name} onClick={() => { PageNavigation(data.link) }} className='sidebar_itemText' sx={{ opacity: 1 }} />
															</ListItemButton>
														</Typography>

													</AccordionDetails>
												)
											}) : null}
									</Accordion>

								</ListItemButton>
								:
								<ListItemButton className='' sx={{
									minHeight: 48,
									justifyContent: open1 ? 'initial' : 'center',
									px: 2,
									paddingY: 0,
								}}
									// onClick={(event) => { handleMenuOrder(event, text.menuOrder) }}
									selected={selectedIndex1 === text.menuOrder}>
									<Box className='sidebar-menuItem'>
										<ListItemIcon
											sx={{
												minWidth: 0,
												mr: 3,
												justifyContent: 'center',
											}}
											style={{ marginRight: '8px' }}
											onClick={() => {
												PageNavigation(text.link);
												localStorage.removeItem('panel');
											}}
											className='listitemicon'>
											{text.icon}
										</ListItemIcon>
										<ListItemText primary={text.name} onClick={() => {
											PageNavigation(text.link);
											localStorage.removeItem('panel');
										}} className='sidebar_itemText' sx={{ opacity: 1 }} />
									</Box>
								</ListItemButton>
							}
						</ListItem>
						</>


					))}
				</List>
			</Drawer>
			<DrawerHeader />
			<Dialog
				open={openNotifiction}
				keepMounted
				onClose={handleNotificationClose}
				aria-describedby="alert-dialog-slide-description"
				className="custom_modal notificationModal"
				PaperProps={{
					style: {
						maxWidth: "450px",
						margin: "auto",
						position: "absolute",
						right: 0,
						backgroundColor: "#fff",

					},
				}}
			>
				<DialogTitle>Notifications</DialogTitle>
				<DialogContent dividers>
					{auth.role_id === 1 ? <Grid style={{ marginBottom: 15 }} item xs={12} sm={12} md={6} lg={6}>
						<Box>
							{notification && notification.length > 0 ? (
								notification.map((ndata, i) => (
									<Card key={i} style={{ marginBottom: 8 }} className='notif_card'>
										<CardContent onClick={(e) => handleView(ndata)} className='p-0'>
											<Box className="flex-between flex-center headBox justify-content-right">
												{/* {ndata.isRead !== true && (
													<FiberManualRecordIcon className='text_white' />
												)} */}
												<Typography
													component="div"
													variant="body1"
													className="fz-15 semibold"
													style={{
														fontSize: 12,
													}}
												>
													{formatDateTime(ndata.updatedAt)}
												</Typography>
											</Box>
											<div className='p-20 flex-between' >

												<Typography component="div" variant="body1" className="fz-15 flex1 semibold">
													{ndata.title}
												</Typography>


												<DeleteIcon
													style={{
														cursor: 'pointer',
														fontSize: 18,
													}} className='text_white'
													onClick={(e) => handleDelete(ndata)}
												/>
											</div>
										</CardContent>
									</Card>
								))
							) : (
								<Typography variant="body1">No Notifications</Typography>
							)}
						</Box>
					</Grid> : <Grid style={{ marginBottom: 15 }} item xs={12} sm={12} md={6} lg={6}>
						<Box>
							{teachernotification && teachernotification.length > 0 ? (
								teachernotification.map((ndata, i) => (
									<Card key={i} style={{ marginBottom: 8 }}>
										<CardContent onClick={(e) => handleTeacherReadUpdate(ndata)} >
											{ndata.isRead !== true && (
												<FiberManualRecordIcon style={{ color: "blue", marginRight: 8 }} />
											)}
											<div style={{ display: "flex", alignItems: "center" }}>
												<div style={{ flexGrow: 1 }}>
													<Typography component="div" variant="body1" className="fz-15 semibold">
														{ndata.title}
													</Typography>
													<Typography
														component="div"
														variant="body1"
														className="fz-15"
														style={{
															position: 'relative',
															bottom: -10,
															right: -310,
															color: 'black',
															fontSize: 12,
														}}
													>
														{formatDateTime(ndata.updatedAt)}
													</Typography>

												</div>

											</div>
										</CardContent>
									</Card>
								))
							) : (
								<Typography variant="body1">No Notifications</Typography>
							)}
						</Box>
					</Grid>}
				</DialogContent>

				<DialogActions>
					{/* <Button onClick={handleReadAll} className="read_all_btn" color="blue">
						Read All
					</Button> */}
					<Button onClick={handleNotificationClose} className="cancel_btn">
						Close
					</Button>
				</DialogActions>
			</Dialog>


			<BootstrapDialog
				onClose={handleClickClose}
				aria-labelledby="customized-dialog-title"
				open={openview}
				className="custom_modal"
				maxWidth="md"
			>
				<DialogActions>
					{/* <Button className="cancel_btn" onClick={handleClickClose}>
            Close
          </Button> */}
				</DialogActions>
			</BootstrapDialog>
			<BootstrapDialog
				onClose={handleClickplanClose}
				aria-labelledby="customized-dialog-title"
				open={openplan}
				className='custom_modal'
				maxWidth='md'
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">Current Subscription Details</DialogTitle>
				<DialogContent dividers>
					{plan ? (
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Typography variant='body1'><span className='fz-15 semibold'>Subscription Name:</span> {plan.name}</Typography>
								<Typography variant='body1'><span className='fz-15 semibold'>User Type:</span> {plan.usertype}</Typography>
								<Typography variant='body1'><span className='fz-15 semibold'>Amount:</span> {plan.currency === 'INR' ? '₹ ' : '$ '}{plan.amount}</Typography>
								<Typography variant='body1'><span className='fz-15 semibold'>Interval:</span> {plan.interval}</Typography>
								<Typography variant='body1'><span className='fz-15 semibold'>Description:</span> {plan.description ? plan.description : "No Description"}</Typography>

							</Grid>
							<Grid item xs={6}>
								<Typography variant='body1'><span className='fz-15 semibold'>Events:</span> {plan.noOfEvent ? plan.noOfEvent : "0"}</Typography>
								<Typography variant='body1'><span className='fz-15 semibold'>Blogs:</span> {plan.noOfBlogs ? plan.noOfBlogs : "0"}</Typography>
								<Typography variant='body1'><span className='fz-15 semibold'>Courses:</span> {plan.noOfCourse ? plan.noOfCourse : "0"}</Typography>
								<Typography variant='body1'><span className='fz-15 semibold'>Podcasts:</span> {plan.noOfPodcast ? plan.noOfPodcast : "0"}</Typography>
								<Typography variant='body1'><span className='fz-15 semibold'>Videos:</span> {plan.noOfVideos ? plan.noOfVideos : "0"}</Typography>
								<Typography variant='body1'><span className='fz-15 semibold'>Images:</span> {plan.noOfImages ? plan.noOfImages : "0"}</Typography>
								<Typography variant='body1'><span className='fz-15 semibold'>Community:</span> {plan.noOfCommunity ? plan.noOfCommunity : "0"}</Typography>
							</Grid>

							{/* Add-Ons Section */}
							{plan.addOns && plan.addOns.length > 0 && (
								<Grid item xs={12}>
									<Typography variant='body1' className='fz-15 semibold'>Add-Ons:</Typography>
									{plan.addOns.map((addOn, index) => (
										<Grid container key={index}>
											<Grid item xs={6}>
												<Typography variant='body1'><span className='fz-15 semibold'>Section Name:</span> {addOn.sectionName}</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography variant='body1'><span className='fz-15 semibold'>Amount:</span> {addOn.amount_type === 'INR' ? '₹ ' : '$ '}{addOn.amount}</Typography>
												<Typography variant='body1'><span className='fz-15 semibold'>Count:</span> {addOn.count}</Typography>
											</Grid>
										</Grid>
									))}
								</Grid>
							)}
						</Grid>
					) : (
						<Typography variant='body1'>No data available</Typography>
					)}
				</DialogContent>


				<DialogActions>
					<Button className='cancel_btn' onClick={handleClickplanClose}>Close</Button>
				</DialogActions>
			</BootstrapDialog>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={openpayment}
				className='theme_modal planModal'
				maxWidth='md'
			>
				<DialogTitle sx={{ m: 0, p: 2, borderBottom: '1px solid #ddd' }} className='fw-bold' id="customized-dialog-title">
					Plan Details
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: 'black',
						zIndex: 1
					}}
					className='closebtn'
				>
					<CloseIcon className='fz-16 fz-bold' />
				</IconButton>

				<DialogContent >
					<Box className='w100'>
						{shouldUseSwiper === false ? (
							<Swiper
								spaceBetween={10}
								autoplay={{
									delay: 5000,
									disableOnInteraction: false,
								}}
								loop={true}
								pagination={{ clickable: true }}
								modules={[Pagination]}
								className="mySwiper"
								centeredSlides={true}
								slidesPerView={3}
								breakpoints={{
									320: {
										slidesPerView: 1,
									},
									768: {
										slidesPerView: 2,
									},
									900: {
										slidesPerView: 3,
									},
								}}
							>
								
								 {subscribe && subscribe.map((item, i) => (
									<SwiperSlide key={i} className='d-flex mb-10 '>
										<Box className="plan_card">
											
											{item?.name === plan?.name && <Box className="ribbon"><Typography component='span'>cuurent plan</Typography></Box>}

											{item.popular === true && <Box className="ribbon"><Typography component='span'>Popular</Typography></Box>}

											
											<Typography component='h5' variant='h5' className='fw-bold fz-20 mb-5 text_black'>{item.name}</Typography>
											<Typography component='h5' variant='h5' className='fw-bold fz-18 text_primary'>₹{item.amount}/- {item.interval}</Typography>
											<Box className="text-center">
												<Button className='theme_btn my-30' onClick={() => handlePayment(item)}>Upgarde Plan</Button>
											</Box>
											<Typography component='div' variant='body1' className='fw-400 fz-14 text_black '>
												<ul className='planlist'>
													{item.noOfBlogs == null ? '' : <li className='d-flex mb-10'><i class="fa-solid fa-check mr-10 pt-5 text_black"></i><div className='text-left text_black  fz-14 '>You can add upto {item.noOfBlogs == null ? 0 : item.noOfBlogs} blogs  </div></li>}
													{item.noOfCourse == null ? '' : <li className='d-flex mb-10'><i class="fa-solid fa-check mr-10 pt-5 text_black"></i><div className='text-left text_black  fz-14 '>You can add upto {item.noOfCourse == null ? 0 : item.noOfCourse} courses</div></li>}
													{item.noOfEvent == null ? '' : <li className='d-flex mb-10'><i class="fa-solid fa-check mr-10 pt-5 text_black"></i><div className='text-left text_black  fz-14 '>You can add upto {item.noOfEvent == null ? 0 : item.noOfEvent} events</div></li>}
													{item.noOfImages == null ? '' : <li className='d-flex mb-10'><i class="fa-solid fa-check mr-10 pt-5 text_black"></i><div className='text-left text_black  fz-14 '>You can add upto {item.noOfImages == null ? 0 : item.noOfImages} images</div></li>}
													{item.noOfPodcast == null ? '' : <li className='d-flex mb-10'><i class="fa-solid fa-check mr-10 pt-5 text_black"></i><div className='text-left text_black  fz-14 '>You can add upto {item.noOfPodcast == null ? 0 : item.noOfPodcast} podcasts</div></li>}
													{item.noOfVideos == null ? '' : <li className='d-flex mb-10'><i class="fa-solid fa-check mr-10 pt-5 text_black"></i><div className='text-left text_black  fz-14 '>You can add upto {item.noOfVideos == null ? 0 : item.noOfVideos} videos</div></li>}
												</ul>
											</Typography>
										</Box>
									</SwiperSlide>
								))}
							</Swiper>
						) : (
							<Grid container columnSpacing={2} className='py-20 withoutSlider'>
								{subscribe && subscribe.slice(0, 3).map((item, i) => (
									<Grid item xs={12} md={4} lg={4}>
										<Box className='plan_card w100'>
										    {item?.name === plan?.name && <Box className="ribbon"><Typography component='span'>cuurent plan</Typography></Box>}
											{item?.popular && <Box className="ribbon"><Typography component='span'>Popular</Typography></Box>}
											<Typography component='h5' variant='h5' className='fw-bold fz-20 mb-5 text_black'>{item?.name}</Typography>
											<Typography component='h5' variant='h5' className='fw-bold fz-18 text_primary'>₹{item?.amount}/- {item?.interval}</Typography>
											
											<Typography component='div' variant='body1' className='fw-400 fz-14 text_black '>
												<ul className='planlist'>
													{item.noOfBlogs == null ? '' : <li className='d-flex mb-10'><i class="fa-solid fa-check mr-10 pt-5 text_black"></i><div className='text-left text_black  fz-14 '>You can add upto {item.noOfBlogs == null ? 0 : item.noOfBlogs} blogs  </div></li>}
													{item.noOfCourse == null ? '' : <li className='d-flex mb-10'><i class="fa-solid fa-check mr-10 pt-5 text_black"></i><div className='text-left text_black  fz-14 '>You can add upto {item.noOfCourse == null ? 0 : item.noOfCourse} courses</div></li>}
													{item.noOfEvent == null ? '' : <li className='d-flex mb-10'><i class="fa-solid fa-check mr-10 pt-5 text_black"></i><div className='text-left text_black  fz-14 '>You can add upto {item.noOfEvent == null ? 0 : item.noOfEvent} events</div></li>}
													{item.noOfImages == null ? '' : <li className='d-flex mb-10'><i class="fa-solid fa-check mr-10 pt-5 text_black"></i><div className='text-left text_black  fz-14 '>You can add upto {item.noOfImages == null ? 0 : item.noOfImages} images</div></li>}
													{item.noOfPodcast == null ? '' : <li className='d-flex mb-10'><i class="fa-solid fa-check mr-10 pt-5 text_black"></i><div className='text-left text_black  fz-14 '>You can add upto {item.noOfPodcast == null ? 0 : item.noOfPodcast} podcasts</div></li>}
													{item.noOfVideos == null ? '' : <li className='d-flex mb-10'><i class="fa-solid fa-check mr-10 pt-5 text_black"></i><div className='text-left text_black  fz-14 '>You can add upto {item.noOfVideos == null ? 0 : item.noOfVideos} videos</div></li>}
												</ul>
												
											</Typography>
											<Box className="text-center">
												{item?.name === plan?.name ? <Button className='theme_btn my-30'>Current Plan</Button>

													: <Button className='theme_btn my-30' onClick={() => handlePayment(item)}>Upgarde Plan</Button>}
											</Box>
											

										</Box>

									</Grid>
								))}
							</Grid>
						)}

					</Box>
				</DialogContent> 

				

				

			</BootstrapDialog>
		</Box>
	);
}


