import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, Avatar, Input, TextField, FormControlLabel, RadioGroup, Radio, Typography, IconButton, TextareaAutosize } from '@mui/material'
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'
import Avatarimage from "../../../assets/images/Avatar.png"
import { locale } from 'moment';
import Switch from '@mui/material/Switch';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { AddAPhoto, Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import PropTypes from 'prop-types';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { usePlacesWidget } from "react-google-autocomplete";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: '15px',
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 15,
                        color: (theme) => theme.palette.grey[500],
                        border: '1px solid #E5EBF0',
                        borderRadius: '8px',
                        height: 38,
                        width: 38
                    }}
                >
                    <CloseIcon className='fz-18' />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#f3714d',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#106ba3',
    },
});


export default function AddLegend() {
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    let location = useLocation()
    var data = location.state ? location.state.result : null
    const [truebutton, setTruebutton] = React.useState(false)
    const [user, setUser] = React.useState({ firstname: '', username: '', email: '', gender: 'Male', phone: '', address: '', Imageprew: "", Imageraw: "", specialization: "", description: "" });
    const [error, setError] = React.useState({ firstname: "", link: "", image: "", specialization: "" })
    const [isToggled, setToggled] = React.useState(false)
    const [statesingle, setStatesingle] = React.useState([])
    const [statesingle2, setStatesingle2] = React.useState([])
    const [cityName, setCityName] = React.useState('')
    const [lat, setLat] = React.useState('')
    const [lng, setLng] = React.useState('')
    const [link, setLink] = React.useState('')

    let username, uservalue;
    const handleChange = (e) => {
        username = e.target.name;
        uservalue = e.target.value;
        // const trimmedValue = uservalue.trim(); // Trim leading and trailing spaces
        //     console.log(trimmedValue)
            
            
        setUser({ ...user, [username]: uservalue })
        setError({ ...error, [username]: '' })
        setError('')
        // Check if the value is only special characters


        if(/^[^a-zA-Z0-9]+$/.test(uservalue)){
          
            setError({ firstname: "Name should not contain only special characters" })
            return false
        }
        if(/.*[0-9].*/.test(uservalue)){
            setError({ firstname: "Name should not be only numbers" })
            return false
        }




        
        if (/^\d+$/.test(uservalue)) {
            setError({ firstname: "Name should not be only numbers" })
            return false
            
          }
        if (/^[^a-zA-Z0-9]/.test(uservalue) && /[a-zA-Z]/.test(uservalue)) {
            setError({ firstname: "Name should not contain both alphabets and special characters"})
            return false
        }
        
        if (/^[a-zA-Z]+$/.test(uservalue) ) {
            setError({ firstname: ""})
            return true
        }
    };


    // const SingleImage = (e, index) => {
    //     setError('')
    //     var fileObj = [];
    //     var fileArray = [];
    //     var filepath = [];

    //     fileObj.push(e.target.files)
    //     for (let i = 0; i <= fileObj[0].length; i++) {
    //         if (i < fileObj[0].length) {
    //             fileArray.push(URL.createObjectURL(fileObj[0][i]))
    //             filepath.push(fileObj[0][i])
    //         }
    //     }
    //     var data = statesingle.filter(x => x != null)
    //     var data1 = statesingle2.filter(x => x != null)
    //     setStatesingle(data)
    //     setStatesingle2(data1)
    //     setStatesingle(prevValue => prevValue.concat(fileArray))
    //     setStatesingle2(prevValue => prevValue.concat(filepath))
    // }


    const SingleImage = (e, index) => {
        setError('');
        var fileObj = [];
        var fileArray = [];
        var filepath = [];
        fileObj.push(e.target.files);
    
        for (let i = 0; i < fileObj[0].length; i++) {
            let file = fileObj[0][i];
            // Check if file size is less than 1MB (1000000 bytes)
            if (file.size > 1000000) {
                setError({ ...error, image: "Image should be less than 1 MB" });
                continue; // Skip adding this file to state
            }
    
            fileArray.push(URL.createObjectURL(file));
            filepath.push(file);
        }
    
        var data = statesingle.filter(x => x != null);
        var data1 = statesingle2.filter(x => x != null);
    
        setStatesingle(data);
        setStatesingle2(data1);
        setStatesingle(prevValue => prevValue.concat(fileArray));
        setStatesingle2(prevValue => prevValue.concat(filepath));
    };
    
    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }

    const { ref, autocompleteRef } = usePlacesWidget({
        // need to change this API key 
        apiKey: 'AIzaSyB-U-riOWcHLeZOeh197bv_RGfF4mF6Jj8',
        onPlaceSelected: (place) => {
            setCityName(place.formatted_address)
            setLat(place.geometry.location.lat());
            setLng(place.geometry.location.lng());
            setError(prevError => ({ ...prevError, location: '' }))
        },
        options: {
            types: "(regions)" | 'establishment'
        },
    });

    const urlRegex = /^(ftp|http[s]?):\/\/(?:www\.)?([^\s.]+\.[^\s]{2,}|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(:\d+)?(\/\S*)?$/;
    const validation = () => {
        if (!user.firstname  && !link) {
            setError({ firstname: "This field is required.", location: "This field is required.", specialization: "This field is required", link: "This field is required" })
            return false
        }
        else if (!user.firstname) {
            setError({ firstname: "First name can not be empty" })
            return false
        }
             
        // else if (statesingle2?.length == 0) {
        //     setError({ image: "Image cannot be empty" })
        //     return false
        // }
        // else if (!user.specialization) {
        //     setError({ specialization: "Specialization can not be empty" })
        //     return false
        // }
        else if (!link) {
            setError({ link: "link can not be empty" })
            return false
        }
        else if (!urlRegex.test(String(link))) {
            setError({ link: "Please enter a valid URL, example:https://www.abc.com/" })
            return false
        }

        return true
    }

    const addLegendDetails = () => {
        const validator = validation()
        if (validator == true) {
            setTruebutton(true)
            const formData = new FormData();
            { statesingle2 ? statesingle2.forEach((item) => { formData.append("image", item) }) : formData.append("image", '') }
            formData.append("name", user.firstname);
            formData.append("sepcialization", user.specialization);
            formData.append("link", link);
            // formData.append("location", cityName); 
            // formData.append("role_id", 2);
            for (const value of formData.values()) {
                console.log(value);
            }
            axios.post(process.env.REACT_APP_BASE_URL + `YogaLegend/yogaLegend`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false)
                    console.log(formData, "formData");
                    console.log(res, "legend")
                    setUser({ firstname: '', specialization: "" });
                    setLink('')
                    setStatesingle('')
                    setCityName('')
                    enqueueSnackbar("Yoga Legend added successfully", { variant: 'success' })
                    navigate('/yogalegend')
                })
                .catch((err) => {
                    console.error("Error occurred:", err);
                    setTruebutton(false)
                    if (err.response) {
                        enqueueSnackbar("Server responded with an error", { variant: 'error' });
                    } else if (err.request) {
                        // console.error("Request made but no response received:", err.request);
                        enqueueSnackbar("No response received from the server", { variant: 'error' });
                    } else {
                        // console.error("Error setting up the request:", err.message);
                        enqueueSnackbar("Error setting up the request", { variant: 'error' });
                    }
                });

        }
    }

    return (
        <Box className='Addcompany'>
            <MiniDrawer  menuOrder={2} submenuOrder={2} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle mb-20'>Add Yoga Legend</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>

                                    <Grid item xs={12} sm={12} md={12} lg={12} >
                                        <Box className="flex-center flex-between">
                                            <Typography component='div' className='label'>Primary Image  <Typography component='span' className='star'></Typography></Typography>
                                            <Box className="">
                                                {statesingle.length < 1 &&
                                                    <Button className="upload_btn" variant="contained" component="label">
                                                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                        <input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
                                                    </Button>}
                                            </Box>
                                        </Box>
                                        {/* {error.image && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.image}</p>} */}

                                        {statesingle.length > 0 &&
                                            <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                {statesingle.length > 0 ? statesingle.map((x, i) => (
                                                    <Grid item xs={6} sm={4} md={4} lg={4} >
                                                        <Box className='uploaded_img' >
                                                            <Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
                                                            <img src={x} alt='' height={155} width={195} />
                                                        </Box>
                                                    </Grid>
                                                )) : ""}
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 280px * 280px for better quality picture.</Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Name: <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='firstname'
                                                className='textfield'
                                                placeholder='Name'
                                                value={user.firstname}
                                                onChange={handleChange}
                                              
                                            />
                                        </FormControl>
                                        {error.firstname && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.firstname}</p>}

                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Tagline/Specialization </Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                name='specialization'
                                                className='textfield'
                                                placeholder='Specialization'
                                                value={user.specialization}
                                                onChange={handleChange}
                                              
                                            />
                                        </FormControl>
                                        {/* {error.specialization && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.specialization}</p>} */}

                                    </Grid>

                                    {/* <Box className="mb-10">
                                        <Typography component='div' className='label'>Location<Typography component='span' className='star'>*</Typography></Typography>
                                        <input style={{ outline: "none", fontFamily: "Nunito,sans-serif", fontSize: "14px", width: '100%' }} ref={ref} placeholder="location" />
                                    </Box> */}

                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Website Link <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                placeholder='Link'
                                                size="small"
                                                name='link'
                                                className='textfield'
                                                style={{ width: '100%' }}
                                                value={link}
                                                onChange={(e) => {
                                                    setLink(e.target.value); setError('')
                                                }}
                                            />
                                        </FormControl>

                                        {error.link && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.link}</p>}

                                    </Grid>

                                </Grid>
                                {truebutton===false ? 
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button variant="contained" className='addbtn ' onClick={addLegendDetails}>Save</Button>
                                </Box>
                                :truebutton===true &&
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                    <Button variant="contained" className='addbtn ' >Please Wait</Button>
                                </Box>
}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}