import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography, Avatar } from '@mui/material';
import axios from 'axios';
import { Add, Instagram, Remove } from '@mui/icons-material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import prof1 from '../../../../../assets/images/profile.png';
import moment from 'moment';
import userlogo from '../../../../../assets/images/upcoming.png'
import { useSnackbar } from 'notistack';


export default function Comment_section(props) {
    console.log(props, "+++++");

    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    };
    const {enqueueSnackbar} = useSnackbar()
    const auth = JSON.parse(localStorage.getItem('userData'))
    const [content, setContent] = useState('');
    const [comments, setComments] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [replyModal, setReplyModal] = useState(false)
    const [replydata, setReplydata] = React.useState('')
    const [blogID, setBlogID] = useState(props.blogId);
    const [isVisible, setIsVisible] = useState(false);
    const [isReply, setReply] = useState(false);
    const [isVisible1,setIsVisible1] = useState(false);
    const [error,setError] = React.useState({replymsg:''})

    useEffect(() => {
        if (props.data._id) {
            getReplies(props.data._id);
        }
    }, []);

   


    const getReplies = (id) => {
        axios.get(process.env.REACT_APP_BASE_URL + `fanPostComments/getfanspostcommentsbyid/${id}`)
            .then((res) => {
                setComments(res.data.comments);
            })
            .catch((error) => {
                console.error("Error fetching comments:", error);
            });
    };


    const showReplies = () => {
        setIsVisible(true);
    };

    const showAnotherReplies = () => {
        setIsVisible1(true)
    }

    const hideReplies = () => {
        setIsVisible(false);
    };


     const hideAnotherReplies = () => {
        setIsVisible1(false);
    };

    const handleReply = (data) => {
        setReplydata(data);
        setReply(!isReply);
    }


  const Validation = () => {
    if(!content){
        setError({replymsg:"Please enter comment"})
        return false
    }
    return true
  }
    


    const commentPost = () => {
        let valid = Validation()
        if(valid){
        const body = {
            "fanPost_id": replydata.fanPost_id,
            "parentId": replydata._id,
            "user_id": auth && auth._id,
            "content": content,
            
        };
        axios.post(process.env.REACT_APP_BASE_URL + `fanPostComments/AddfanTimeLineComments`, body)
            .then((res) => {
                setContent('');
                getReplies(replydata.fanPost_id);
                props.getTimeLine()
            })
            .catch((error) => {
                console.error("Error posting comment:", error);
            });
        }
    };

    const handleDeletecomment = (id) => {
        let body = {
            "commentId":id,
            
        }
        axios.put(process.env.REACT_APP_BASE_URL + `fanPostComments/fancommentreplydelete`, body)
            .then((res) => {
                getReplies(props.data._id)
                enqueueSnackbar("comment deleted successfully", { variant: 'success' })
                props.getTimeLine()
            })
            .catch((error) => {
                console.log(error);
            })
    }

    console.log(comments,"+++++++++++++");

    return (
        

        <Box className="comments_section my-30">
            <Grid container columnSpacing={2} rowSpacing={2}>
                {comments && comments.length > 0 ? 
                (comments && comments.map((data, i) => (
                    
                    <Grid item xs={12} sm={12} md={12} key={data._id} >
                        <Box className='receiver '>
                            <Box className="comment_each ">
                                <Box className="flex-center ">
                                {data.replies?.length > 0 && <Box className="collapseBtns">
                                        <Button onClick={showReplies} className='text_primary'><Add className='fz-16' /></Button>
                                        <Typography>{data.replies?.length}</Typography>
                                        <Button onClick={hideReplies} className='text_primary'><Remove className='fz-16' /></Button>
                                    </Box>}
                                    <Box className="">
                                        {data.user?.image?.length > 0 ? (
                                            <img
                                                src={process.env.REACT_APP_BASE_URL + `${data.user.image}`}
                                                height={50}
                                                width={50}
                                                style={{ borderRadius: '50%' }}
                                                className='mr-10'
                                            />
                                        ) : (
                                            < AccountCircleIcon className="mr-10" fontSize="large" />
                                        )}
                                    </Box>
                                    <Box>
                                        <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>{data.user && data.user?.username}</Typography>
                                        <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 semibold '>{moment(data.createdAt).fromNow()}</Typography>
                                    </Box>
                                </Box>
                                <Button className='' onClick={() => handleReply(data)}>
                                    <Typography component='span' variant='span' className='text_blue fz-12 semibold '><i class="fa-solid fa-reply"></i> Reply</Typography>
                                </Button>
                            </Box>
                            <Typography component='span' variant='body1' className='text_grey letter_sp fz-14 fw-400 mt-20'>{data.content}</Typography>
                            {replydata._id === data._id && isReply &&
                                <Box className="comment_box">
                                    {data.prof1?.length > 0 ? (
                                        <img
                                            src={process.env.REACT_APP_BASE_URL + `${data.prof1}`}
                                            height={50}
                                            width={50}
                                            style={{ borderRadius: '50%' }}
                                            className='mr-10'
                                        />
                                    ) : (
                                        < AccountCircleIcon className="mr-10" fontSize="large" />
                                    )}
                                    <TextField fullWidth id="outlined-basic" placeholder='Add a comment' multiline rows={1} variant="outlined" className='textfield text_white' value={content}
                                        onChange={(e) => {setContent(e.target.value);setError({replymsg:''})}} />
                                    <Button className='fz-16 theme-btn mr-10' onClick={(e) => commentPost(e, data._id)} >Reply</Button>
                                    <Button className='fz-16 cancel_btn' onClick={() => setReply(false)} >Cancel</Button>
                                </Box>
                               
                            }
                                    {error.replymsg ? <p style={{ color: "red", marginLeft: '90px' }}>{error.replymsg}</p> : null}

                        </Box>
                         {isVisible == true && data.replies.map((reply) => {
                            return (
                                <><Box className='sender' key={reply._id}>
                                    <Box className="comment_each ">
                                        <Box className="flex-center ">
                                            <Box className="collapseBtns">
                                                <Button onClick={showAnotherReplies} className='text_primary'><Add className='fz-16' /></Button>
                                                <Typography>{reply.replies?.length}</Typography>
                                                <Button onClick={hideAnotherReplies} className='text_primary'><Remove className='fz-16' /></Button>
                                            </Box>
                                            <Box className="">
                                                {data.prof1?.length > 0 ? (
                                                    <img
                                                        src={process.env.REACT_APP_BASE_URL + `${data.prof1}`}
                                                        height={50}
                                                        width={50}
                                                        style={{ borderRadius: '50%' }}
                                                        className='mr-10' />
                                                ) : (
                                                    <AccountCircleIcon className="mr-10" fontSize="large" />
                                                )}
                                            </Box>
                                            <Box className='flex-center flex-wrap'>
                                                <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>Admin</Typography>
                                                <Typography component='span' variant='span' className='text_white fz-12 semibold mr-10 badge'>You</Typography>
                                                <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 semibold '>{moment(reply.createdAt).fromNow()}</Typography>
                                            </Box>
                                        </Box>
                                        <Box className='flex-center '>

                                            <Button className=' mr-10'>
                                                <Typography component='span' variant='span' className='text_primary fz-12 semibold ' onClick={() => handleDeletecomment(reply._id)}><i class="fa-solid fa-trash"></i> Delete</Typography>
                                            </Button>
                                            <Button className=''>
                                                <Typography component='span' variant='span' className='text_blue fz-12 semibold '><i class="fa-solid fa-reply"></i> Reply</Typography>
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Typography component='span' variant='body1' className='text_grey letter_sp fz-14 fw-400 '>{reply.content}</Typography>
                                </Box>
                                {isVisible1 == true && reply && reply.replies.map((info,i)=>(
                                    <Box className="doubleReply_box">
                                        <Box className='sender '>
                                            <Box className="flex-center">
                                                <Box className="w100">
                                                    <Box className="comment_each ">
                                                        <Box className="flex-center ">
                                                            <Box className="">
                                                                <img src={userlogo} />
                                                            </Box>
                                                            <Box>
                                                                <Typography component='span' variant='span' className='text_black fz-16 semibold mr-10'>{info.user?.username}</Typography>
                                                                <Typography component='span' variant='body1' className='text_grey letter_sp fz-12 semibold '>{moment(info.createdAt).fromNow()}</Typography>
                                                            </Box>
                                                        </Box>
                                                        {/* <Button className=' mr-10'>
                                                            <Typography component='span' variant='span' className='text_primary fz-12 semibold '><i class="fa-solid fa-trash"></i> Delete</Typography>
                                                        </Button> */}

                                                    </Box>
                                                    <Typography component='span' variant='body1' className='text_grey letter_sp fz-14 fw-400 '><Typography component='span' variant='body1' className='text_blue semibold fz-14'>@{reply && reply.user?.username && "Admin"}</Typography> {info.content}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                                
                                </>
                            )
                        })}

                        
                       
                    </Grid>
                ))) : (
                        <p style={{color:'gray',textAlign:'center',marginLeft:'600px'}}>No Comments found</p>
                )}
            </Grid>
           
        </Box>

    )
}
