import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import MiniDrawer from '../../../components/drawer';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Checkbox, Typography, RadioGroup, Radio, FormControlLabel, Input, CardContent, Dialog, Slide } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import EmailEditor from "react-email-editor";
import { usePlacesWidget } from "react-google-autocomplete";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Sample from './sample.json'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function BpRadio(props) {
	return (
		<Radio
			sx={{
				'&:hover': {
					bgcolor: 'transparent',
				},
			}}
			disableRipple
			color="default"
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			{...props}
		/>
	);
}
const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: '50%',
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === 'dark'
			? '0 0 0 1px rgb(16 22 26 / 40%)'
			: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
	backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
	backgroundImage:
		theme.palette.mode === 'dark'
			? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
			: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
	},
}));

const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#f3714d',
	backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 16,
		height: 16,
		backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#106ba3',
	},
});

const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 40,
	height: 20,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 0,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(20px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 18.5,
		height: 18.5,
	},
	'& .MuiSwitch-track': {
		borderRadius: 18.5 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#db0a07' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
	color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
	display: 'flex',
	height: 22,
	alignItems: 'center',
	...(ownerState.active && {
		color: '#784af4',
	}),
	'& .QontoStepIcon-completedIcon': {
		color: '#784af4',
		zIndex: 1,
		fontSize: 18,
	},
	'& .QontoStepIcon-circle': {
		width: 8,
		height: 8,
		borderRadius: '50%',
		backgroundColor: 'currentColor',
	},
}));

function QontoStepIcon(props) {
	const { active, completed, className } = props;
	return (
		<QontoStepIconRoot ownerState={{ active }} className={className}>
			{completed ? (
				<Check className="QontoStepIcon-completedIcon" />
			) : (
				<div className="QontoStepIcon-circle" />
			)}
		</QontoStepIconRoot>
	);
}

QontoStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 * @default false
	 */
	active: PropTypes.bool,
	className: PropTypes.string,
	/**
	 * Mark the step as completed. Is passed to child components.
	 * @default false
	 */
	completed: PropTypes.bool,
};


export default function Service_Steppyform() {
	const navigate = useNavigate()
	const headerlocal = {
		'Content-Type': 'application/json',
		"Access-Control-Allow-Origin": "*",
		Authorization: localStorage.getItem("token")
	}
	const headerformdata = {
		'Content-Type': 'multipart/form-data',
		Authorization: localStorage.getItem("token")
	}
	const [activeStep, setActiveStep] = React.useState(0);
	const { enqueueSnackbar } = useSnackbar()
	const [bookreviews, setBookreviews] = React.useState({ booktitle: '', description: "", publishby: "", publisher: "", author: "", pagecount: "", photographedby: "", isbn: "", reviewed_by: "" })
	const [truebutton, setTruebutton] = React.useState(false)
	const [error, setError] = React.useState({ booktitle: "", video: "", location: "", publishby: "", publisher: "" })
	const [categoryId, setCategoryId] = React.useState('')
	const [subcategoryId, setSubCategoryId] = React.useState('')
	const [catError, setCatError] = React.useState('')
	const [subcatError, setSubCatError] = React.useState('')
	const [imageError, setImageError] = React.useState('')
	const [imageAddError, setImageAddError] = React.useState('')
	const [state1, setState1] = React.useState([])
	const [state2, setState2] = React.useState([])
	const [statesingle, setStatesingle] = React.useState([])
	const [statesingle2, setStatesingle2] = React.useState([])
	const [convertedContent, setConvertedContent] = React.useState(null);
	const [description, setDescription] = React.useState('')
	const [richeditor, setRichtor] = React.useState("")
	const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
	const dataofeditor = `${richeditor}`
	const [selectedVideo, setSelectedVideo] = useState([]);
	const [videoPreviewUrl, setVideoPreviewUrl] = useState([]);
	const [category, setCategory] = React.useState([])
	const [subcat, setSubcat] = React.useState([])
	// const [date, setDate] = React.useState(dayjs());
	// const [values, setValues] = React.useState(dayjs());
	const [review_posted_online, setReview_posted_online] = React.useState(dayjs())
	const [isPrimary, setPrimary] = React.useState(false)
	const [isPublic, setPublic] = React.useState(false)
	const [selectGallert, setSelectGallery] = useState('Image')
	// const [isToggled, setToggled] = React.useState(false)
	const auth = JSON.parse(localStorage.getItem('userData'))
	const [selectedDate, setSelectedDate] = React.useState(dayjs());


	const emailEditorRef = useRef({ editor: {} });
	const unlayerSampleJSON = {
		counters: {
			u_column: 1,
			u_row: 1,
			u_content_text: 1,
		},
		body: {
			id: "MJ_d5bY5sW",
			rows: [
				{
					id: "-qw54ukLG6",
					cells: [1],
					columns: [
						{
							id: "JmI5YdJgB-",
							contents: [
								{
									id: "ltAvFqp4nr",
									type: "text",
									values: {
										containerPadding: "10px",
										anchor: "",
										textAlign: "left",
										lineHeight: "140%",
										linkStyle: {
											inherit: true,
											linkColor: "#0000ee",
											linkHoverColor: "#0000ee",
											linkUnderline: true,
											linkHoverUnderline: true,
										},
										hideDesktop: false,
										displayCondition: null,
										_meta: {
											htmlID: "u_content_text_1",
											htmlClassNames: "u_content_text",
										},
										selectable: true,
										draggable: true,
										duplicatable: true,
										deletable: true,
										hideable: true,
										text: " ",
										// ------------>
									},
								},
							],
							values: {
								border: {},
								padding: "0px",
								backgroundColor: "",
								_meta: {
									htmlID: "u_column_1",
									htmlClassNames: "u_column",
								},
							},
						},
					],
					values: {
						displayCondition: null,
						columns: false,
						backgroundColor: "",
						columnsBackgroundColor: "",
						backgroundImage: {
							url: "",
							fullWidth: true,
							repeat: "no-repeat",
							size: "custom",
							position: "center",
						},
						padding: "0px",
						anchor: "",
						hideDesktop: false,
						_meta: {
							htmlID: "u_row_1",
							htmlClassNames: "u_row",
						},
						selectable: true,
						draggable: true,
						duplicatable: true,
						deletable: true,
						hideable: true,
					},
				},
			],
			values: {
				popupPosition: "center",
				popupWidth: "600px",
				popupHeight: "auto",
				borderRadius: "10px",
				contentAlign: "center",
				contentVerticalAlign: "center",
				contentWidth: "950px",
				fontFamily: {
					label: "Arial",
					value: "arial,helvetica,sans-serif",
				},
				textColor: "#000000",
				popupBackgroundColor: "#FFFFFF",
				popupBackgroundImage: {
					url: "",
					fullWidth: true,
					repeat: "no-repeat",
					size: "cover",
					position: "center",
				},
				popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
				popupCloseButton_position: "top-right",
				popupCloseButton_backgroundColor: "#DDDDDD",
				popupCloseButton_iconColor: "#000000",
				popupCloseButton_borderRadius: "0px",
				popupCloseButton_margin: "0px",
				popupCloseButton_action: {
					name: "close_popup",
					attrs: {
						onClick:
							"document.querySelector('.u-popup-container').style.display = 'none';",
					},
				},
				backgroundColor: "#e7e7e7",
				backgroundImage: {
					url: "",
					fullWidth: true,
					repeat: "no-repeat",
					size: "custom",
					position: "center",
				},
				preheaderText: "",
				linkStyle: {
					body: true,
					linkColor: "#0000ee",
					linkHoverColor: "#0000ee",
					linkUnderline: true,
					linkHoverUnderline: true,
				},
				_meta: {
					htmlID: "u_body",
					htmlClassNames: "u_body",
				},
			},
		},
		schemaVersion: 12,
	};
	const emailJSON = unlayerSampleJSON;
	const [designEditor, setDesignEditor] = useState(Sample)
	const onLoad = () => {
		emailEditorRef.current.editor.loadDesign(designEditor);
	};

	const handleEditor = (e) => {
		const unlayer = emailEditorRef.current?.editor;
		unlayer?.exportHtml((data) => {
			const { design, html } = data;
			const sanitizedHtml = DOMPurify.sanitize(html);
			const editor = { "body": design.body }
			setDesignEditor(editor)
			enqueueSnackbar('Saved changes', { variant: 'info' });
		})
	}

	const handleDateChange = (date) => {
		setSelectedDate(date);
	};

	const handleReviewDate = (date) => {
		setReview_posted_online(date);
	}

	React.useEffect(() => {
		if (dataofeditor) {
			try {
				const contentState = stateFromHTML(dataofeditor);
				setEditorState(EditorState.createWithContent(contentState));
			} catch (error) {
				console.error("Error during HTML to ContentState conversion:", error);
			}
		}
	}, [dataofeditor]);

	// React.useEffect(() => {
	// 	convertContentToHTML();
	// }, [editorState]);

	const handleEditGallery = (e) => {
		setSelectGallery(selectGallert === 'Image' ? 'Video' : 'Image')
	}

	React.useEffect(() => {
		getCategory()
	}, [])

	React.useEffect(() => {
		getSubCategory()
	}, [categoryId])


	const getCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `Category/GetCategory`, { headers: headerlocal }).then((res) => {
			setCategory(res.data.category)
		}).catch((error) => {
			console.log(error.response, "error");
		})
	}
	const getSubCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `SubCategory/GetSubcategorybasedonCategoryId?category_id=${categoryId}`, { headers: headerlocal })
			.then((res) => { setSubcat(res.data.subcategory) }).catch((error) => {
				console.log(error.response, "error");
			})
	}

	const handleRemoveRestart = () => {
		setSubCategoryId('')
	}

	// const handleEditorChange = (newEditorState) => {
	// 	setEditorState(newEditorState);
	// }

	// const convertContentToHTML = () => {
	// 	const currentContent = editorState.getCurrentContent();
	// 	const currentContentAsHTML = stateToHTML(currentContent);
	// 	setConvertedContent(currentContentAsHTML)
	// }


	let name, value;
	const handlechange = (e) => {
		name = e.target.name;
		value = e.target.value;
		setBookreviews({ ...bookreviews, [name]: value })
	}

	const multipleImgChange = (e, index) => {
		setImageAddError(''); // Clear previous error message
		const fileObjects = e.target.files; // Get the selected files from input
		const fileArray = [];
		const filepath = [];

		for (let i = 0; i < fileObjects.length; i++) {
			const file = fileObjects[i];

			// Check file size (less than 1MB)
			if (file.size <= 1024 * 1024) {
				fileArray.push(URL.createObjectURL(file)); // Add file URL to display preview
				filepath.push(file); // Add file object to store for submission
			} else {
				setImageAddError('Image should be less than 1 MB.'); // Set error message for oversized file
			}
		}

		// Update state with valid files only
		setState1(prevState => prevState.concat(fileArray)); // Add URLs to display in UI
		setState2(prevState => prevState.concat(filepath)); // Store file objects for submission
	};

	const indexDelete = (e, index) => {
		var filteredpreview = state1.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = state2.filter((val, i) => {
			return i !== index;
		});
		setState1(filteredpreview);
		setState2(filteredraw);
	}

	const SingleImage = (e, index) => {
		setImageError('');
		const fileObj = e.target.files;

		const fileArray = [];
		const filepath = [];

		for (let i = 0; i < fileObj.length; i++) {
			let file = fileObj[i];
			// Check if file size is less than 1MB (1000000 bytes)
			if (file.size > 1000000) {
				setImageError("Image should be less than 1 MB");
				continue; // Skip adding this file to state
			}

			fileArray.push(URL.createObjectURL(file));
			filepath.push(file);
		}

		// Updating states based on your logic
		const data = statesingle.filter(x => x != null);
		const data1 = statesingle2.filter(x => x != null);

		setStatesingle(prevValue => [...prevValue, ...fileArray]);
		setStatesingle2(prevValue => [...prevValue, ...filepath]);
	};

	const indexDelete1 = (e, index) => {
		var filteredpreview = statesingle.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = statesingle2.filter((val, i) => {
			return i !== index;
		});
		setStatesingle(filteredpreview);
		setStatesingle2(filteredraw);
	}
	const handleNext = () => {
		var valid = validate()
		if (valid === true) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};


	const validate = () => {
		if (!bookreviews.booktitle && statesingle2?.length == 0 && !bookreviews.publisher) {
			setError({ booktitle: "This field is required.", publisher: "This field is required." })
			setCatError("This field is required.")
			setImageError("This field is required.")
			return false
		}
		if (!bookreviews.booktitle) {
			setError({ booktitle: "This field is required." })
			return false
		}
		if (!bookreviews.publisher) {
			setError({ publisher: "This field is required." })
			return false
		}
		if (!categoryId) {
			setCatError("This field is required.")
		}
		if (statesingle2?.length == 0) {
			setImageError("This field is required.")
			return false
		}

		return true
	}

	const validatenew = () => {
		// if (state2?.length == 0 && selectedVideo?.length == 0) {
		// 	setError({ video: "This field is required." })
		// 	setImageAddError("This field is required.")
		// 	return false
		// }
		// if (state2?.length == 0) {
		// 	setImageAddError("This field is required.")
		// 	return false
		// }
		// if (selectedVideo?.length == 0) {
		// 	setError({ video: "This field is required." })
		// 	return false
		// }
		return true
	}

	// const handleToggle = () => {
	// 	setToggled(!isToggled)
	// }
	const handleTogglePrimary = () => {
		setPrimary(!isPrimary)
	}
	const handleTogglePublic = () => {
		setPublic(!isPublic)
	}

	const handleAddService = () => {
		var multipleImage = state2.filter(x => x != null);
		var valid = validate()
		// var validnew = validatenew()

		if (valid == true) {

			setTruebutton(true)
			const formData = new FormData();
			formData.append("createdby[]", auth && auth._id)
			formData.append("Book_title", bookreviews.booktitle)
			formData.append("Date", selectedDate)
			formData.append("category_id", categoryId)
			formData.append("subcategory_id", subcategoryId)
			// formData.append("Book_publish", isToggled)
			formData.append("public", isPublic)
			formData.append("primary", isPrimary)
			formData.append("Book_published_by", bookreviews.publisher)
			formData.append("author", bookreviews.author)
			formData.append("photographed_by", bookreviews.photographedby)
			formData.append("page_count", bookreviews.pagecount)
			formData.append("Isbn", bookreviews.isbn)
			formData.append("review_posted_online", review_posted_online)
			formData.append("reviewed_by", bookreviews.reviewed_by)
			formData.append("Book_shortdescription", description)
			// formData.append("Book_Description", sanitizedHtml)
			// formData.append("Book_DescriptionJSON", design && JSON.stringify(design.body))
			formData.append("gallery", selectGallert === 'Image' ? 'Image' : 'Video')
			{ statesingle2 ? statesingle2.forEach((item) => { formData.append("newimage", item) }) : formData.append("newimage", '') }
			{
				selectGallert === 'Image' ? <>
					{multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '')}
					{selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", '') }) : formData.append("Video", '')}
				</>
					:
					<>
						{multipleImage ? multipleImage.forEach((item) => { formData.append("additionalimages", '') }) : formData.append("additionalimages", '')}
						{selectedVideo ? selectedVideo.forEach((item) => { formData.append("Video", item) }) : formData.append("Video", '')}</>
			}
			console.log(...formData, "book review formdata");
			axios.post(process.env.REACT_APP_BASE_URL + `BookReview/AddBookReview`, formData, { headers: headerformdata }).then((res) => {
				setTruebutton(false)
				navigate('/bookreviews');
				setBookreviews({ booktitle: '', description: "", publisher: "", author: "", photographedby: "", pagecount: "", isbn: "", reviewed_by: "" })
				setCategoryId('');
				setSubCategoryId('');
				setState1([])
				setState2([])
				enqueueSnackbar("Book Review added successfully", { variant: 'success' })
				console.log(res, "Bookreviews added");
			}).catch((err) => {
				setTruebutton(false)
				enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' })
				console.log(err, "error");
			})

		}
	}


	// const handleVideoChange = (e, index) => {
	// 	setError(prevError => ({ ...prevError, video: '' }))
	// 	var fileObj = [];
	// 	var fileArray = [];
	// 	var filepath = [];
	// 	fileObj.push(e.target.files)
	// 	for (let i = 0; i <= fileObj[0].length; i++) {
	// 		if (i < fileObj[0].length) {
	// 			fileArray.push(URL.createObjectURL(fileObj[0][i]))
	// 			filepath.push(fileObj[0][i])
	// 		}
	// 	}
	// 	setVideoPreviewUrl(fileArray)
	// 	setSelectedVideo(filepath)
	// }

	const handleVideoChange = (e, index) => {
		setImageError('')
		setError({ video: '' });
		var fileObj = [];
		var fileArray = [];
		var filepath = [];
		fileObj.push(e.target.files);
		for (let i = 0; i < fileObj[0].length; i++) {
			if (fileObj[0][i].size > 25 * 1024 * 1024) { // Corrected comparison
				setError({ video: 'Video should be less than 25 MB' });
				return;
			}
			fileArray.push(URL.createObjectURL(fileObj[0][i]));
			filepath.push(fileObj[0][i]);
		}

		setVideoPreviewUrl(fileArray);
		setSelectedVideo(filepath);
	}

	const clearVideo = () => {
		setSelectedVideo([]);
		setVideoPreviewUrl([]);
	};

	const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };


	return (
		<Box className=''>
			<MiniDrawer menuOrder={14} submenuOrder={25}/>
			<Box component="section" className="contentWraper">
				<Box className='whiteContainer p-20'>
					<Typography component='h6' className='formTitle mb-20'>Add Book Review</Typography>
					<Box className='steppy_content'>
						<Box className="maxw_700">
							<Box className="mb-20">
								<Typography component='div' className='label'>Book Title <Typography component='span' className='star'>*</Typography></Typography>
								<FormControl variant="standard" fullWidth>
									<TextField
										id="filled-size-small"
										variant="outlined"
										placeholder='Title'
										size="small"
										name='booktitle'
										className='textfield'
										style={{ width: '100%' }}
										value={bookreviews.booktitle}
										onChange={(e) => {
											handlechange(e); setError(prevError => ({ ...prevError, booktitle: '' }))
										}}
									/>
									{error.booktitle && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.booktitle}</p>}
								</FormControl>
							</Box>

							<Box className="mb-20">
								<Typography component='div' className='label'>Select Category <Typography component='span' className='star'>*</Typography></Typography>
								<FormControl fullWidth >
									<Select
										className="select"
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={categoryId ? categoryId : 'sel'}
										onChange={(e) => { setCategoryId(e.target.value); setCatError("") }}>
										<MenuItem disabled value={'sel'} >Select category</MenuItem>
										{category && category?.length > 0 ? category.map((data) => (
											<MenuItem value={data._id}>{data.category_title}</MenuItem>)) : <MenuItem disabled>{"No Record's found"}</MenuItem>}
									</Select>
									{catError && <p style={{ color: 'red' }} className="fz-13 m-0" >{catError}</p>}
								</FormControl>
							</Box>
							<Box className="mb-20">
								<Typography component='div' className='label'>Select Sub Category</Typography>
								<Box  >
									<FormControl fullWidth style={{ flex: 1 }}>
										<Select
											className="select"
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											disabled={categoryId !== '' ? false : true}
											value={subcategoryId ? subcategoryId : 'sel18'}
											onChange={(e) => {
												setSubCategoryId(e.target.value);
												setSubCatError('');
												setCatError('');
												setSubCatError('');
											}}
										>
											<MenuItem disabled value={'sel18'}>Select sub category</MenuItem>
											{subcat && subcat?.length > 0 ? (
												subcat.map((data5) => (
													<MenuItem value={data5._id}>{data5.subcategory_title}</MenuItem>
												))) : (<MenuItem disabled>{"No Record's found"}</MenuItem>)}
										</Select>
										{subcatError && (<p style={{ color: 'red' }} className="fz-13 m-0">{subcatError}</p>)}
									</FormControl>
									{subcategoryId !== '' && (
										<RestartAltIcon style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => handleRemoveRestart()} />
									)}
								</Box>
							</Box>

			
							<Box className="mb-20">
								<Typography component='div' className='label'>Published Date</Typography>
								<FormControl variant="standard" fullWidth className="datepicker">
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											className='textfield w100'
											value={selectedDate}
											onChange={handleDateChange}
											renderInput={(params) => <TextField {...params} />}
											minDate={dayjs().subtract(100, 'year')} 
											maxDate={dayjs().add(10, 'year')} 
											format="DD/MM/YYYY"
										/>
									</LocalizationProvider>
								</FormControl>
							</Box>

							{/* <Grid item xs={12} sm={12} md={12} lg={12}>
									<Box className='mb-10 d-flex align-items-start justify-content-between' >
										<Box className=' text-right' style={{ display: 'flex', justifyContent: 'space-between' }}>
											<Typography component='div' variant='body1' className='fz-14 '>Book Publish: </Typography>
											<FormControlLabel className='m-0' style={{ display: 'flex', alignItems: 'center' }} control={<IOSSwitch onClick={handleToggle} sx={{ m: 1 }} />} />
										</Box>
									</Box>
								</Grid> */}

							<Box className='mb-20'>
								<FormControlLabel className="checkbox_list"
									control={
										<Checkbox
											checked={isPublic}
											onChange={handleTogglePublic}
											name='checkbox-value'
											color='primary'
										/>
									}
									label={<Typography component='div' className='label mb-0'>Do you want to make this review as Public? </Typography>}
								/>
								<FormControlLabel className="checkbox_list"
									control={
										<Checkbox
											checked={isPrimary}
											onChange={handleTogglePrimary}
											name='checkbox-value'
											color='primary'
										/>
									}
									label={<Typography component='div' className='label mb-0'>Do you want to make this review as Primary? </Typography>}
								/>
							</Box>

							<Box className="mb-10">
								<Typography component='div' className='label'>Publisher<Typography component='span' className='star'>*</Typography></Typography>
								<FormControl variant="standard" fullWidth>
									<TextField
										id="filled-size-small"
										variant="outlined"
										placeholder='Publisher'
										size="small"
										name='publisher'
										className='textfield'
										style={{ width: '100%' }}
										value={bookreviews.publisher}
										onChange={(e) => {
											handlechange(e); setError(prevError => ({ ...prevError, publisher: '' }))
										}}
									/>
									{error.publisher && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publisher}</p>}
								</FormControl>
							</Box>

							<Box className="mb-10">
								<Typography component='div' className='label'>Author</Typography>
								<FormControl variant="standard" fullWidth>
									<TextField
										id="filled-size-small"
										variant="outlined"
										placeholder='Author'
										size="small"
										name='author'
										className='textfield'
										style={{ width: '100%' }}
										value={bookreviews.author}
										onChange={(e) => {
											handlechange(e);
										}}
									/>
									{/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
								</FormControl>
							</Box>
							<Box className="mb-10">
								<Typography component='div' className='label'>Reviewed By</Typography>
								<FormControl variant="standard" fullWidth>
									<TextField
										id="filled-size-small"
										variant="outlined"
										placeholder='Reviewed By'
										size="small"
										name='reviewed_by'
										className='textfield'
										style={{ width: '100%' }}
										value={bookreviews.reviewed_by}
										onChange={(e) => {
											handlechange(e);
										}}
									/>
									{/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
								</FormControl>
							</Box>

							<Box className="mb-20">
								<Typography component='div' className='label'>Review Posted Online<Typography component='span' ></Typography></Typography>
								<FormControl variant="standard" fullWidth className="datepicker">
									<LocalizationProvider dateAdapter={AdapterDayjs} >
										<DatePicker
											className='textfield w100'
											value={review_posted_online}
											onChange={handleReviewDate}
											renderInput={(params) => <TextField {...params} />}
											minDate={dayjs().subtract(100, 'year')} 
											maxDate={dayjs().add(10, 'year')} 
											format="DD/MM/YYYY"
										/>
									</LocalizationProvider>
								</FormControl>
							</Box>

							<Box className="mb-10">
								<Typography component='div' className='label'>Photographed By</Typography>
								<FormControl variant="standard" fullWidth>
									<TextField
										id="filled-size-small"
										variant="outlined"
										placeholder='Photographed By'
										size="small"
										name='photographedby'
										className='textfield'
										style={{ width: '100%' }}
										value={bookreviews.photographedby}
										onChange={(e) => {
											handlechange(e);
										}}
									/>
									{/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
								</FormControl>
							</Box>

							<Box className="mb-10">
								<Typography component='div' className='label'>Page Count</Typography>
								<FormControl variant="standard" fullWidth>
									<TextField
										id="filled-size-small"
										variant="outlined"
										placeholder='Page Count'
										size="small"
										name='pagecount'
										onInput={(e) => onlyNumbers(e)}
										className='textfield'
										style={{ width: '100%' }}
										value={bookreviews.pagecount}
										onChange={(e) => {
											handlechange(e);
										}}
									/>
									{/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
								</FormControl>
							</Box>

							<Box className="mb-10">
								<Typography component='div' className='label'>ISBN</Typography>
								<FormControl variant="standard" fullWidth>
									<TextField
										id="filled-size-small"
										variant="outlined"
										placeholder='ISBN'
										size="small"
										name='isbn'
										className='textfield'
										style={{ width: '100%' }}
										value={bookreviews.isbn}
										onChange={(e) => {
											handlechange(e);
										}}
									/>
									{/* {error.publishedby && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.publishedby}</p>} */}
								</FormControl>
							</Box>

							<Grid item xs={12} sm={12} md={12}  >
								<Box className='flex-center flex-between mt-20'>
									<Typography component='div' className='label'>Cover Image <Typography component='span' className='star'> * </Typography></Typography>
									<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
										{statesingle.length < 1 &&
											<Button className="upload_btn" variant="contained" component="label">
												<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
												<input accept={"image/png, image/jpg,image/jpeg"} onChange={SingleImage} hidden type="file" />
											</Button>
										}
									</Box>
								</Box>
							</Grid>

							{imageError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageError}</p>}

							<Grid item xs={12} sm={12} md={12}  >
								{statesingle.length > 0 &&
									<Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
										{statesingle.length > 0 ? statesingle.map((x, i) => (
											<Grid item xs={6} sm={4} md={4} lg={4} >
												<Box className='uploaded_img' >
													<Button className='close_icon' onClick={(e) => indexDelete1(e, i)}><Close /></Button>
													<img src={x} alt='' height={155} width={195} />
												</Box>
											</Grid>
										)) : ""}
									</Grid>
								}
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12}>
								<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 500px * 600px for better quality picture.</Typography>
							</Grid>

							<Grid item xs={12} sm={12} md={12}  >
								<Box className="mb-10">
									<Typography component='div' className='label'>Short Description</Typography>

									<FormControl variant="standard" fullWidth>
										<textarea
											placeholder='Short Description '
											className='textarea'
											defaultValue={description}
											onChange={(e) => setDescription(e.target.value)}
											rows={4}
											cols={40} 
											style={{resize:'none'}}/>
									</FormControl>
								</Box>
							</Grid>

						</Box>
					</Box >
					<>
						{/* <Box className='heading-btns mt-20 w100' style={{ textAlign: 'right' }}> */}
						<Box className='heading-btns mt-20 w100' style={{ textAlign: 'center', marginLeft: '530px', marginRight: 'auto' }}>
							{/* <Button onClick={handleBack} variant="contained" className='theme-btn  mr-10'>Back</Button> */}
							<Button onClick={handleAddService} variant="contained" className='theme-btn'>Submit</Button>
						</Box>
					</>
				</Box >
			</Box >
		</Box >
	)
}