import React, { useState } from "react"
import { BusinessOutlined, Lock, Person } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, OutlinedInput, IconButton } from '@mui/material'
import axios from 'axios'
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack'
import contactusLogo from './../../assets/images/contactusLogo.png';
import firebase from 'firebase/compat/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useCookies } from 'react-cookie';
import 'firebase/compat/database';
import { messaging, setupNotifications } from "../../firebase";

export default function Adimlogimn() {

    const [fcmkey, setFcmKey] = React.useState('')
    console.log(fcmkey, "fcm");
    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
    const { enqueueSnackbar } = useSnackbar()
    let navigate = useNavigate();
    const [userdata, setUserData] = React.useState({ username: "", password: "" })
    const [error, setError] = useState({ username: "", password: "" })

    const [values, setValues] = React.useState({
        showPassword: false,
        showRePassword: false
    });

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };
    const handleClickShowRePassword = () => {
        setValues({
            ...values,
            showRePassword: !values.showRePassword,
        });
    };

    // React.useEffect(() =>  {
    //     getToken(messaging, { vapidKey: "BCch_dBcI75By1vAAUqNoKNe5elsltpcncqVeMSTPvoZTQtgT149-zC1Pf9l52One6Bh9cvZR1rZoqOeoVR9rKo" })
    //     .then((currentToken) => {
    //         if (currentToken) {
    //           setFcmKey(currentToken)
    //         } else {
    //           console.log('No FCM token available.');
    //         }
    //       }).catch((error) => {
    //         console.error(error, 'Error getting FCM token:');
    //       });
    //   }, [])
    React.useEffect(() => {
        const fetchData = async () => {
          try {
            const currentToken = await getToken(messaging, { vapidKey: "BCch_dBcI75By1vAAUqNoKNe5elsltpcncqVeMSTPvoZTQtgT149-zC1Pf9l52One6Bh9cvZR1rZoqOeoVR9rKo" });
            if (currentToken) {
              setFcmKey(currentToken);
            } else {
              console.log('No FCM token available.');
            }
          } catch (error) {
            console.error('Error getting FCM token:', error);
          }
        };
      
        fetchData(); // Call the async function
      
        // Note: Dependency array is empty, meaning this effect runs only once after mount
      
      }, []);

    let uname, uvalue
    const handleChange = (e) => {
        setUserData({ ...userdata, [e.target.name]: e.target.value })
        setError({ ...error, [e.target.name]: "" })
    }
    const validator = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        if (!userdata.username && !userdata.password) {
            setError({ username: "Email cannot be empty", password: "Password cannot be empty" })
            return false
        }
        else if (!userdata.username) {
            setError({ username: "Email cannot be empty" });
            return false
        }
        if (!userdata.username.match(emailRegex)) {
            setError({ username: "Email is invalid" });
            return false
        }
        else if (!userdata.password) {
            setError({ password: "Password cannot be empty" });
            return false
        }
        return true
    }


//Login functionality:
    const handleLogin = (e) => {
        e.preventDefault();
        var validate = validator();
        if (validate) {
            var body = {
                email: userdata.username,
                password: userdata.password,
                FCM_token: fcmkey != '' ? fcmkey : undefined
            }
            axios.post(process.env.REACT_APP_BASE_URL + "Admin/AdminLogin", body)
                .then((res) => {
                    localStorage.setItem("userData", JSON.stringify(res.data.admin))
                    localStorage.setItem("roledata", JSON.stringify(res.data.mergedUser))
                    localStorage.setItem("token", res.data.token)
                    localStorage.setItem("fcmkey", fcmkey)
                    console.log(res.data.mergedUser)
                    console.log(res.data.admin)

                    setCookie('value', res.data.token);
                    // firebase.database().ref(`Users/${res.data.admin._id}`).update({
                    //     fcm: fcmkey,
                    //     isOnline: true
                    // })
                    enqueueSnackbar("Admin Login Successfully", { variant: 'success' });
                    navigate("/teachers")
                })
                // .catch((err) => {
                //     if (err.response) {
                //         console.log(err.response.data);
                //         enqueueSnackbar(err.response.data.error, { variant: 'error' });
                //     } else if (err.request) {
                //         console.log(err.request);
                //         setError({ password: "Something went wrong" });
                //         enqueueSnackbar("Something went wrong", { variant: 'error' });
                //     } else {
                //         console.log('Error', err.message);
                //         setError({ password: "Something went wrong" });
                //         enqueueSnackbar("Something went wrong", { variant: 'error' });
                //     }
                // });
                .catch((err) => {
                    console.log(err,"error123");
                    setError({ username: "", password: "" });
                    if (err.response) {
                        if (err.response.data.error.includes("email")) {
                            setError({ ...error, username: err.response.data.error });
                        } else if (err.response.data.error.includes("Password")) {
                            setError({ ...error, password: err.response.data.error });
                        } else if(err.response.data.error == "data and hash arguments required"){
                            setError({...error,password:"Kindly Check Your Register email and set Password"})
                            enqueueSnackbar("Kindly Check Your Register email and set Password", { variant: 'error' });

                        }
                        
                        else {
                            setError({ ...error, password: err.response.data.error });
                        }
                        // enqueueSnackbar(err.response.data.error, { variant: 'error' });
                    } else {
                        setError({ ...error, password: "Something went wrong" });
                        enqueueSnackbar("Something went wrong", { variant: 'error' });
                    }
                })
        }
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleLogin(e)
        }
    }

    return (
        <Box className='login'>
            <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='login_img'>
                        <Box className='logintext_box'>
                            <h1 className='heading semibold'>Yoga</h1>
                            <Typography component='p' variant='body1' className='subheading'>Yoga takes you into present moment. The only place where life exists. </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Box className='Login_content'>
                        <Box className="header_main_left">
                            <img src={contactusLogo} height={100} />
                        </Box>
                        <Typography component='h4' variant='h4'>Admin Login</Typography>
                        <Box className='form'>

                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Email</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    className='textfield'
                                    placeholder='Enter your Email'
                                    name='username'
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Person className='start_icon' /></InputAdornment>,
                                    }}
                                />
                              {error.username && <p style={{color:'red'}} className='error-field'>{error.username}</p> }
                            </FormControl>
                            <FormControl variant="standard">
                                <Typography component='div' className='label'>Password</Typography>
                                <TextField
                                    id="filled-size-small"
                                    variant="outlined"
                                    size="small"
                                    placeholder='Enter your Password'
                                    type={values.showRePassword ? 'text' : 'password'}
                                    className='textfield'
                                    name='password'
                                    onKeyDown={_handleKeyDown}
                                    onChange={handleChange}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><Lock className='start_icon' /></InputAdornment>,
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowRePassword}
                                                    edge="end">
                                                    {values.showRePassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>

                                    }}
                                />
                             {error.password && <p style={{color:'red'}} className='error-field'>{error.password}</p>}
                            </FormControl>
                        </Box>
                        <Button className='loginbtn mt-0' onClick={handleLogin}>LOGIN</Button>
                        <Box className="logintype">
                            <Box className='a-link'>
                                {/* <Typography component='a' href='/'>Login as Teacher </Typography> */}
                            </Box>
                            <Box className='a-link'>
                                {/* <Typography component='a' href='/superdminforgotpassword'>Forgot your password?</Typography> */}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}