import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { AssignmentOutlined, Business, FeaturedPlayListOutlined, LockPersonOutlined, VpnKeyRounded } from '@mui/icons-material';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
import PanoramaIcon from '@mui/icons-material/Panorama';
import { ListItemButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';


export default function Sidebar({ subtype }) {
    const auth = JSON.parse(localStorage.getItem("userData"))
    const params = useParams()
  
    let navigate = useNavigate()
    const data = [
        {
            icons: <AssignmentOutlined className='fz-18' />,
            text: 'ADD DETAILS',
            link: `/community/community-details/desc/${params.id}`          
        },
        auth && auth.role_id == 1 || auth.role_id == 2  ? {
            icons: <AssignmentTurnedInIcon className='fz-18' />,
            text: 'ADD COURSE',
            link: `/community/community-details/course/${params.id}`   
        } : "",
        auth && auth.role_id == 1 || auth.role_id == 2  ? {
            icons: <i class="fa-solid fa-calendar-days fz-18"></i>,
            text: 'ADD EVENT',
            link: `/community/community-details/event/${params.id}`   
        } : "",
        auth && auth.role_id == 1 || auth.role_id == 2  ? {
            icons: <HeadsetMicIcon className='fz-18' />,
            text: 'ADD PODCAST',
            link: `/community/community-details/podcast/${params.id}`   
        } : "",
        auth && auth.role_id == 1 || auth.role_id == 2  ? {
            icons: <VpnKeyRounded className='fz-18' />,
            text: 'ADD USERS',
            link: `/community/community-details/users/${params.id}`   
        } : "",
        auth && auth.role_id == 1 || auth.role_id == 2  ? {
            icons: <UploadFileIcon className='fz-18' />,
            text: 'ADD ATTACHMENTS',
            link: `/community/community-details/attachments/${params.id}`   
        } : "",
        auth && auth.role_id == 1 || auth.role_id == 2  ? {
            icons: <SmartDisplayIcon className='fz-18' />,
            text: 'ADD VIDEOS',
            link: `/community/community-details/vides/${params.id}`   
        } : "",
        auth && auth.role_id == 1 || auth.role_id == 2  ? {

            icons: <PanoramaIcon className='fz-18' />,
            text: 'ADD IMAGES',
            link: `/community/community-details/gallery/${params.id}`   
        } : "",
        auth && auth.role_id == 1 || auth.role_id == 2  ? {
            icons: <i class="fa-solid fa-timeline fz-18"></i>,
            text: 'COMMUNITY TIMELINE',
            link: `/community/community-details/communitytimeline/${params.id}`   
        } : "",
        auth && auth.role_id == 1 || auth.role_id == 2  ? {
            icons: <i class="fa-solid fa-circle-info fz-18"></i>,
            text: 'COMMUNITY RULE',
            link: `/community/community-details/communityrule/${params.id}`   
        } : "",
    ]

    const [dense, setDense] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };
    const PageNavigation = (url) => {
        navigate(url);

    }

    React.useState(() => {
        setSelectedIndex(subtype);
    }, [subtype])
    return (
        <>
            <Box className='sidebar'>
                <Typography variant="h5" component="h5" >
                    COMMUNITY SETTINGS
                </Typography>
                {/* <Typography variant="h6" component="h6" >
                    DATA
                </Typography> */}
                <List dense={dense} className='list'>
                    {data.map((item, index) => (
                        item &&
                        <ListItemButton key={index} selected={selectedIndex === index}
                            onClick={(event) => handleListItemClick(event, index)}>
                            <ListItemIcon style={{ minWidth: 35, fontSize: 16 }} className='itemIcon'>
                                {item.icons}
                            </ListItemIcon>
                            <ListItemText className='sidebarText'
                                primary={item.text} onClick={() => PageNavigation(item.link)}
                            />
                        </ListItemButton>
                    ))}
                </List>
            </Box>
            <Box className='Ressidebar'>
                <Typography variant="h5" component="h5" >
                    COMMUNITY SETTINGS
                </Typography>
                <Typography variant="h6" component="h6" >
                    DATA
                </Typography>
                <List className='list'>
                    {data.map((item, index) => (
                        <ListItem key={index} disablePadding sx={{ display: 'block' }} selected={selectedIndex === index}
                        >
                            <ListItemButton
                                onClick={(event) => handleListItemClick(event, index)} >
                                <ListItemIcon style={{ minWidth: 35, fontSize: 16 }} className='itemIcon'>
                                    {item.icons}
                                </ListItemIcon>
                                <ListItemText className='sidebarText'
                                    primary={item.text} onClick={() => PageNavigation(item.link)}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>
    )
}
