// // import './App.css';
// import './assets/css/Custom.css';
// import './assets/css/common.css';
// import './assets/css/responsive.css';
// import './assets/icons/css/all.css';
// import Routers from './routes';
// import React, { useEffect, useState } from "react";
// import { SnackbarProvider } from 'notistack';
// import { useSnackbar } from 'notistack'
// import Closebutton from "./components/closebutton"
// function App() {
// 	return (
// 		<>
// 			<SnackbarProvider maxSnack={3} anchorOrigin={{
// 				vertical: 'top',
// 				horizontal: 'right',
// 			}} action={closeid => <Closebutton value={closeid} />}>
// 				<Routers />
// 			</SnackbarProvider>
// 		</>
// 	);
// }

// export default App;


// import './App.css';
import './assets/css/Custom.css';
import './assets/css/common.css';
import './assets/css/responsive.css';
import './assets/icons/css/all.css';
import Routers from './routes';
import { SnackbarProvider } from 'notistack';
import Closebutton from "./components/closebutton"
import { register } from './serviceworker'
import { messaging, setupNotifications } from './firebase';
import { onMessage } from '@firebase/messaging';
import firebase from 'firebase/compat/app';
import React, { useEffect } from 'react'
import { Notifications } from 'react-push-notification';
import { useSnackbar } from 'notistack'
import addNotification from 'react-push-notification';
// const { enqueueSnackbar } = useSnackbar()
function App() {



    useEffect(() => {
        // setupNotifications()
        onMessage(messaging, (payload) => {
            console.log(payload, 'Foreground Message in app.js');
            // const notificationTitle = payload.notification.title;
            // const notificationOptions = {
            //     body: payload.notification.body,
            //     icon: "/Noti.jpg",
            // };
            const notificationTitle = payload.notification.title;
            const notificationOptions = {
                body: payload.notification.body,
                icon: payload.notification.image
            };

            //new notification
            addNotification({
                title: payload.notification.title,
                message: payload.notification.body,
                theme: 'white',
                duration: 4000, // Apply a theme color
                backgroundTop: 'black', // Background color of the top container
                backgroundBottom: 'white', // Background color of the bottom container
                colorTop: 'white', // Font color of the top container
                colorBottom: 'black', // Font color of the bottom container
                closeButton: 'Close', // Text for the close button
            });
            // enqueueSnackbar(payload.notification.title, { variant: 'success' });
            // self.registration.showNotification(notificationTitle, notificationOptions);
            // self.registration.showNotification(notificationTitle, notificationOptions);


            //old working
            if ('Notification' in window) {

                if (Notification.permission === 'granted') {
                    // new Notification(notificationTitle, notificationOptions);
                } else if (Notification.permission !== 'denied') {
                    Notification.requestPermission().then(permission => {
                        if (permission === 'granted') {
                            // new Notification(notificationTitle, notificationOptions);
                        }
                    });
                }
            }

        }, [])


    });



    // onMessage(messaging, (payload) => {
    //     console.log(payload,'Foreground Message in app.js');
    //     const notificationTitle = payload.notification.title;
    //     const notificationOptions = {
    //         body: payload.notification.body,
    //         icon: "/Noti.jpg",
    //     };
    //     if ('Notification' in window) {
        
    //         if (Notification.permission === 'granted') {
    //             new Notification(notificationTitle, notificationOptions);
    //         } else if (Notification.permission !== 'denied') {
    //             Notification.requestPermission().then(permission => {
    //                 if (permission === 'granted') {
    //                     new Notification(notificationTitle, notificationOptions);
    //                 }
    //             });
    //         }
    //     }
    // });

    // const firebaseConfig = {
    //     apiKey: "AIzaSyDiQK1X2_VjCmayO3eTDHHzyZwDB6gQkzY",
    //     authDomain: "yoga-5006f.firebaseapp.com",
    //     databaseURL: "https://yoga-5006f-default-rtdb.firebaseio.com",
    //     projectId: "yoga-5006f",
    //     storageBucket: "yoga-5006f.appspot.com",
    //     messagingSenderId: "359278173131",
    //     appId: "1:359278173131:web:c27cebec6e144645f6f44e",
    //     measurementId: "G-Q4RKEGTZNG"
    //   };

    //   firebase.initializeApp(firebaseConfig);
      
    return (
        <>
            <SnackbarProvider maxSnack={3} anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }} action={closeid => <Closebutton value={closeid} />}>
                <Notifications />
                <Routers />
            </SnackbarProvider>
        </>
    );
}

export default App;